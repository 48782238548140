import { useRouteError } from 'react-router-dom';
import { FourZeroFourPage } from '~/pages/FourZeroFourPage';
import { UnknownErrorPage } from '~/pages/UnknownErrorPage';
import './ErrorPage.scss';

export function ErrorHandlerPage() {
  const error = useRouteError();
  console.error('Error handler page', error);

  switch ((error as any).status) {
    case 404:
      return <FourZeroFourPage />;
    default:
      return <UnknownErrorPage error={error} />;
  }
}
