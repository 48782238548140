import { Badge, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import React, { useEffect, useMemo, useState } from 'react';
import { ClausesApi } from '@property-folders/common/client-api/clausesApi';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { NewClauseModal } from '@property-folders/components/dragged-components/clauses/NewClauseModal';
import { EditClauseModal } from '@property-folders/components/dragged-components/clauses/EditClauseModal';
import { SearchBar } from '@property-folders/components/dragged-components/SearchBar';
import './ClauseManagementPage.scss';
import { SingleSelectForm } from '@property-folders/components/dragged-components/SelectForm';
import { Clause, isHtmlClause } from '@property-folders/common/types/Clause';
import {
  InfiniteScrollListColumn,
  LazyInfiniteTableList
} from '@property-folders/components/dragged-components/LazyInfiniteTableList';
import { friendlyDateFormatter } from '@property-folders/common/util/formatting';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { DeleteClauseModal } from '@property-folders/components/dragged-components/clauses/DeleteClauseModal';
import { useFeatureFlags } from '@property-folders/components/hooks/useFeatureFlags';
import {
  ShowGuidanceNotesButton
} from '@property-folders/components/dragged-components/guidance/ShowGuidanceNotesButton';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { Predicate } from '@property-folders/common/predicate';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '@property-folders/components/display/errors/modals';

export function ClauseManagementPage() {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const isGlobalAdmin = sessionInfo?.isGlobalAdmin ?? false;
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const managementEntities = useMemo(() => sessionInfo?.entities.filter(entity => entity.roles.includes('Manager')) ?? [], [sessionInfo]);
  const [entityUuid, setEntityUuid] = useState<string | undefined>(isGlobalAdmin ? 'global' : (managementEntities.length > 0 && managementEntities[0].entityUuid || undefined));
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [editingClause, setEditingClause] = useState<Clause & { migrate?: boolean} | null>(null);
  const [filterForm, setFilterForm] = useState<string | 'AllForms'>('AllForms');
  const [items, setItems] = useState<Clause[]>([]);
  const featureFlags = useFeatureFlags();
  const [subscriptionForms, setSubscriptionForms] = useState<Record<string, string>>({});

  const columnDefinition: InfiniteScrollListColumn<Clause>[] = useMemo(() => [
    {
      label: 'Name',
      rowMajor: 'title' as keyof Clause,
      rowMinor: (rowData: Clause) => {
        return rowData.allForms
          ? 'All Forms'
          : ((rowData.formCodes ?? []).concat(rowData.formCodesRequired ?? []))
            .map(code => FormTypes[code]?.label ?? subscriptionForms[code] ?? '')
            .join(' | ') || 'Unknown form set';
      }
    },
    featureFlags.newPropertyFolders ? {
      label: '',
      rowMajor: (rowData: Clause) => isHtmlClause(rowData)
        ? <Badge
          pill={true}
          bg='dark'
          title='This clause was written for subscription forms only. It needs to be migrated to work with property folder documents as well.'
        >Migration Required</Badge>
        : <></>,
      headerCellStyle: { width: '10rem' }
    } : undefined,
    {
      label: 'Modified',
      rowMajor: (rowData: Clause) => rowData.updateStamp
        ? friendlyDateFormatter(new Date(rowData.updateStamp))
        : 'Unknown Date',
      headerCellStyle: { width: '10rem' }
    }
  ].filter(Predicate.isNotNullish), [featureFlags.newPropertyFolders, subscriptionForms]);

  useEffect(() => {
    if (!entityUuid) {
      setSubscriptionForms({});
      return;
    }

    AjaxPhp.listSubscriptionForms(entityUuid).then(r => {
      if (r && Array.isArray(r.results)) {
        const newSubscriptionForms: Record<string, string> = {};
        for (const result of r.results) {
          newSubscriptionForms[result.code] = `${result.name} (${result.stateName})`;
        }

        setSubscriptionForms(newSubscriptionForms);
        if (!newSubscriptionForms[filterForm]) {
          setFilterForm('AllForms');
        }
      }
    });

  }, [entityUuid]);

  if (!entityUuid) {
    return <div className='h1'>Not authorised to edit clauses for any Entity</div>;
  }

  const editClause = (clause: Clause) => {
    setEditingClause({ ...clause });
    setShowEditModal(true);
  };

  const deleteClause = (clause: Clause) => {
    setEditingClause({ ...clause });
    setShowDeleteModal(true);
  };

  const migrateClause = (clause: Clause) => {
    setEditingClause({ ...clause, migrate: true });
    setShowEditModal(true);
  };

  const {
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    fetchNextPage,
    data,
    refetch
  } = ClausesApi.useInfiniteEntityClause({
    entityUuid: entityUuid,
    query: {
      pageNumber: 1,
      pageSize: 100,
      searchTerm
    }
  });

  const allItems = data?.pages
    .flatMap(c => c?.results ?? [])
    .flatMap(c => ({
      ...c,
      id: String(c.clauseId)
    })) ?? [];

  useEffect(() => {
    let ff = filterForm;
    const allForms = allItems.map(i => i.formCodes.concat(i.formCodesRequired)).flat();
    if (filterForm !== 'AllForms' && !allForms.includes(filterForm)) {
      setFilterForm('AllForms');
      ff = 'AllForms';
    }

    const formCodeFilter: Clause[] = ff === 'AllForms'
      ? allItems
      : allItems.filter(f => f.formCodes.includes(ff) || f.formCodesRequired.includes(ff));

    setItems(formCodeFilter);
  }, [data?.pages, filterForm, entityUuid]);

  return <Container
    fluid={true}
    className='h-100 position-relative g-0 d-flex flex-column flex-start clause-management-page'
  >
    <Row className='px-3 pt-3 control-line mb-3 mb-lg-0'>
      <Col lg={3} xs={6} className='pb-3'>
        <h1 className='mb-0'>Clauses <ShowGuidanceNotesButton noteId='clauseManagement' fontSize='24px' /></h1>
      </Col>
      <Col lg={3} xs={6} align='end' className='pb-3 d-flex justify-content-end'>
        <div>
          <Button
            variant='primary'
            size='lg'
            onClick={() => setShowAddModal(true)}
            title='Add clause'
          >
            <Icon name='add' variant='outlined' icoClass='me-2 fs-4 mb-1'></Icon>
            Add Clause
          </Button>
        </div>
      </Col>
      <Col>
        <div className='d-block search-group d-sm-flex'>
          <SearchBar
            placeholder='Search for a clause'
            onSearch={term => setSearchTerm(term)}
            className={'flex-basis-0 flex-grow-1'}
          />
          <SingleSelectForm
            value={filterForm}
            withClauses={true}
            bootstrapSelect={true}
            includeAllFormsOption={true}
            propertyForms={false}
            subscriptionForms={true}
            entityUuid={entityUuid}
            filter={allItems.map(i => i.formCodes.concat(i.formCodesRequired)).flat()}
            allFormsLabel='All Clauses'
            onChange={f => {
              setFilterForm(f);
            }}
            className={'flex-basis-0 flex-grow-1'}
          />
          {(isGlobalAdmin || ((managementEntities.length ?? 1) > 1)) && (
            <Form.Select
              size='lg'
              onChange={e => {
                setEntityUuid(e.target?.value ? e.target.value : entityUuid);
              }}
              value={entityUuid}
              className={'flex-basis-0 flex-grow-1'}
            >
              {isGlobalAdmin && <option value='global' key='global'>Global Clauses</option>}
              {managementEntities.map(e => <option
                value={e.entityUuid}
                key={e.entityUuid}
              >{e.profileName || e.name || e.nameRla}</option>)}
            </Form.Select>)}
        </div>
      </Col>
    </Row>

    <div className='flex-grow-1' style={{ overflow: 'hidden', position: 'relative' }}>
      <LazyInfiniteTableList
        storageKey='clause-management'
        hasNextPage={hasNextPage}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        items={items.map(i => ({ id: String(i.clauseId), ...i }))}
        columns={columnDefinition}
        rowActions={[
          { label: 'Edit', action: (rowData) => editClause(rowData) },
          { label: 'Delete', action: (rowData => deleteClause(rowData)) },
          {
            label: 'Migrate',
            action: (rowData => migrateClause(rowData)),
            if: (rowData => isHtmlClause(rowData))
          }
        ]}
        rowClick={editClause}
        containerClass={''}
      />
    </div>
    <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} onClose={() => setShowAddModal(false)} show={!!showAddModal}/>}>
      <NewClauseModal
        entityUuid={entityUuid}
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        onAdd={async c => {
          await ClausesApi.addClauseForEntity(c, entityUuid);
          await refetch();
          setShowAddModal(false);
        }}
        propertyFoldersEnabled={featureFlags.newPropertyFolders ?? false}
      />
    </ErrorBoundary>

    {editingClause && <ErrorBoundary fallbackRender={fallback=>
      <FallbackModal
        {...fallback}
        onClose={() => {
          setShowEditModal(false);
          setEditingClause(null);
        }}
        show={!!showEditModal}
      />
    }>
      <EditClauseModal
        entityUuid={entityUuid}
        show={showEditModal}
        clause={editingClause}
        onHide={() => {
          setShowEditModal(false);
          setEditingClause(null);
        }}
        onEdit={async c => {
          await ClausesApi.updateClauseForEntity(c);
          await refetch();
          setShowEditModal(false);
          setEditingClause(null);
        }}
        propertyFoldersEnabled={featureFlags.newPropertyFolders ?? false}
      />
    </ErrorBoundary>}
    <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} onClose={() => setShowDeleteModal(false)} show={!!showDeleteModal}/>}>
      <DeleteClauseModal
        show={showDeleteModal}
        clause={editingClause}
        onHide={() => setShowDeleteModal(false)}
        onDelete={async c => {
          await ClausesApi.deleteClauseForEntity(c);
          await refetch();
          setShowDeleteModal(false);
        }}
      />
    </ErrorBoundary>
  </Container>;
}
