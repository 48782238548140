import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';

import { useContext, useState } from 'react';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import {
  EntitySettingsEntity,
  EntitySettingsRule,
  EntitySettingsRuleTriggerType
} from '@property-folders/contract/yjs-schema/entity-settings';
import { ruleTriggerTypeOptions } from '@property-folders/contract/yjs-schema/entity-settings/options';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { v4 } from 'uuid';
import { LazyInfiniteTableList } from '@property-folders/components/dragged-components/LazyInfiniteTableList';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';

export function RulesSettingsEdit() {
  const { entityUuid } = useContext(EntitySettingsContext);
  const { value: rules } = useLightweightTransaction<EntitySettingsRule[]>({ parentPath: 'rules' });
  const { updateDraft } = useYdocBinder<EntitySettingsEntity>({ path: '' });
  const [editRuleId, setEditRuleId] = useState('');

  const createRule = () => {
    const id = v4();
    updateDraft?.(draft => {
      if (!draft.rules) {
        draft.rules = [];
      }
      draft.rules.push({
        id,
        name: 'New Rule',
        enabled: false
      });
    });
    setEditRuleId(id);
  };

  return <div>
    <WizardStepPage
      name='rules'
      label='Rules'
      icon='email'
      headerContent={<Button onClick={createRule}>Add Rule</Button>}
    >
      <LazyInfiniteTableList
        hover={true}
        storageKey={'EntityRules'}
        hasNextPage={false}
        isFetching={false}
        isFetchingNextPage={false}
        fetchNextPage={() => {}}
        items={rules || []}
        columns={[{
          label: 'Name',
          rowMajor: row => row.name
          // rowMinor: row => row.name
        },{
          label: 'Status',
          rowMajor: row => row.enabled ? 'Enabled' : 'Disabled'
          // rowMinor: row => row.enabled ? 'Enabled' : 'Disabled'
        }]}
        rowHeight='50px'
        rowClick={row => setEditRuleId(row.id)}
        rowActions={[{
          label: 'Edit',
          action: row => setEditRuleId(row.id)
        }]}
        containerClass=''
      />
    </WizardStepPage>
    {editRuleId && <Modal show={Boolean(editRuleId)} onHide={() => setEditRuleId('')} size='lg'>
      <Modal.Header>
        <Modal.Title>Edit Rule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditRule id={editRuleId} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => setEditRuleId('')}>Close</Button>
      </Modal.Footer>
    </Modal>}
  </div>;
}

function EditRule({ id }: { id: string }) {
  const { value: rule, fullPath: rulePath } = useLightweightTransaction<EntitySettingsRule>({ parentPath: `rules.[${id}]` });
  return <div className='w-100 h-100'>
    <div>
      <WrField.Control
        name={'name'}
        parentPath={rulePath}
        myPath={'name'}
        label={'Rule name'}
      />
    </div>
    <div className='mt-3'>
      <WrField.CheckRadio
        label='Enable this rule?'
        radioType='checkbox'
        options={{ 'true': 'Yes' }}
        valueType='boolean'
        inline={true}
        name='enabled'
        parentPath={rulePath}
        myPath={'enabled'}
      />
    </div>
    <div className='mt-3 flex-wrap'>
      <WrField.Select
        label='Trigger'
        name='trigger'
        parentPath={rulePath}
        myPath={'trigger.type'}
        options={ruleTriggerTypeOptions}
      />
      {rule?.trigger?.type === EntitySettingsRuleTriggerType.FormStatusChanged && <>
        <WrField.Select
          label='Form'
          name='trigger'
          parentPath={rulePath}
          myPath={'trigger.type'}
          options={ruleTriggerTypeOptions}
        />
      </>}
    </div>
    <div className='mt-3'>
      <Container>
        <Row>
          <Col xs={1}></Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
    <pre>{JSON.stringify(rule)}</pre>
  </div>;
}

