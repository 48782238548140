import { FormContextSigningOverride } from '@property-folders/components/form-gen-util/yjsStore';
import { SigningConfiguration } from '@property-folders/components/dragged-components/signing/SigningConfiguration';
import { prepareForSigning } from '@property-folders/components/subscription-forms/prepareForSigning';
import { FormTypes, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import {
  FolderType,
  FormCode,
  FormSigningState,
  PropertyRootKey,
  TransactionMetaData
} from '@property-folders/contract/yjs-schema/property';
import { generateInitiator } from '@property-folders/common/util/form';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AuthApi } from '@property-folders/common/client-api/auth';
import * as Y from 'yjs';
import { IndexeddbPersistence } from 'y-indexeddb';
import { Awareness } from 'y-protocols/awareness';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { useStore } from 'react-redux';
import { UserPreferencesRootKey } from '@property-folders/contract/yjs-schema/user-preferences';
import { useEntities } from '@property-folders/components/hooks/useEntity';
import { useEntityLogoForCurrentPropertyFolder } from '@property-folders/components/hooks/useEntityLogo';
import { ShortId } from '@property-folders/common/util/url';
import {
  ExpandSigningPlacementStrategy,
  FillContiguousSpaceSigningPlacementStrategy
} from '@property-folders/common/yjs-schema/property';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';

interface RouterData {
  transId: string,
  ydoc: Y.Doc,
  localProvider: IndexeddbPersistence,
  ydocStats: Y.Doc,
  localProviderStats: IndexeddbPersistence,
  awareness: Awareness,
}

export function ConfigureSubscriptionFolderSigning() {
  const { transId, ydoc } = useReactRouterData<RouterData>();
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { documentIdRaw } = useParams();
  const { bindState: metaBindState } = useImmerYjs<TransactionMetaData>(ydoc, PropertyRootKey.Meta);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const [formCode, setFormCode] = useState('');
  const formId = ShortId.toUuid(documentIdRaw);
  const formInstance = formCode
    ? PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, meta || {})
    : PropertyFormYjsDal.searchFormInstanceByIdFromState(formId, meta || {});
  const memberEntities = useEntities();
  const localEntity = memberEntities && meta?.entity?.id ? memberEntities?.[meta.entity.id] : null;
  const navigate = useNavigate();
  const { instance: fileSync } = useContext(FileSyncContext);
  const { instance: yManagerInstance } = useContext(YManagerContext);
  const store = useStore();
  const getCurrentUserPrefs = useCallback(() => yManagerInstance?.getUserPrefs()?.doc.getMap(UserPreferencesRootKey.Main).toJSON(), [yManagerInstance]);
  const entityLogoUri = useEntityLogoForCurrentPropertyFolder();

  useEffect(() => {
    if (!formInstance?.formCode) return;

    setFormCode(formInstance.formCode);
  }, [formInstance?.formCode]);

  const prepareForSigningHandler = async () => {
    let placementSettings: any = { placement: {}, initialPlacement: {} };

    if (formInstance?.subscription?.documentId) {
      const documentMeta = await AjaxPhp.getDocumentMeta({ documentId: formInstance.subscription.documentId });
      if (documentMeta.signingPortal.settings) {
        placementSettings = documentMeta.signingPortal.settings;
      }
    }

    const placementStrat: ExpandSigningPlacementStrategy | FillContiguousSpaceSigningPlacementStrategy = {
      type: placementSettings.placement?.type || 'fill-contiguous-space',
      expandDown: placementSettings.placement?.expandDown || 10,
      marginRight: placementSettings.placement?.marginRight || 43,
      marginLeft: placementSettings.placement?.marginLeft || 0,
      expandUp: placementSettings.placement?.expandUp || 0,
      signedText: placementSettings.placement?.signedText
        ? {
          size: placementSettings.placement?.signedText?.size || 10,
          width: placementSettings.placement?.signedText?.width || 40,
          offsetX: placementSettings.placement?.signedText?.offsetX || 0,
          offsetY: placementSettings.placement?.signedText?.offsetY || 0
        }
        : undefined,
      expandLeft: placementSettings.placement?.expandLeft || 0,
      expandRight: placementSettings.placement?.expandRight || 0,
      timestampText: placementSettings.placement?.timestampText
        ? {
          x: placementSettings.placement?.timestampText?.x || 0,
          y: placementSettings.placement?.timestampText?.y || 0
        }
        : undefined
    };

    const initialPlacementStrat: ExpandSigningPlacementStrategy | FillContiguousSpaceSigningPlacementStrategy = {
      type: placementSettings.initialPlacement?.type || 'expand',
      expandDown: placementSettings.initialPlacement?.expandDown || 0,
      expandLeft: placementSettings.initialPlacement?.expandLeft || 0,
      expandRight: placementSettings.initialPlacement?.expandRight || 0,
      expandUp: placementSettings.initialPlacement?.expandUp || 0,
      marginRight: placementSettings.placement?.marginRight || 43,
      marginLeft: placementSettings.placement?.marginLeft || 0
    };

    return prepareForSigning({
      // No sublineages on subscription forms
      dal: new PropertyFormYjsDal(ydoc, PropertyRootKey.Data, PropertyRootKey.Meta),
      formId,
      formCode,
      sessionInfo,
      initiator: generateInitiator(meta, sessionInfo, localEntity),
      store,
      fileSync,
      getUserPrefsData: getCurrentUserPrefs,
      memberEntities,
      overrideFormDefinition: {
        ...FormTypes[formInstance?.formCode ?? FormCode.UploadedDocument],
        subscription: {
          signing: {
            useGroups: false,
            placementStrategies: {
              default: placementStrat,
              default_initials: initialPlacementStrat
            }
          }
        },
        debug: placementSettings?.debug
      }
    }).then(async () => {
      await AjaxPhp.updateSubscriptionFolderStatus({
        documentId: formInstance?.subscription?.documentId as number,
        status: FormSigningState.OutForSigning
      });

      navigate(LinkBuilder.documentPath(
        { id: transId, nicetext: '' },
        { id: formId, nicetext: '' },
        { isSubscriptionForm: true, folderType: FolderType.Document }
      ));
    });
  };

  if (!formCode) {
    return <></>;
  }

  return <FormContextSigningOverride>
    <SigningConfiguration
      propertyId={transId}
      formCode={formCode}
      formId={formId}
      ydoc={ydoc}
      yMetaRootKey={PropertyRootKey.Meta} // Subscription forms do not yet support sublineages
      yRootKey={PropertyRootKey.Data}
      entityLogoLoadedUri={entityLogoUri}
      prepareForSigning={prepareForSigningHandler}
      showColours={false}
    />
  </FormContextSigningOverride>;
}
