import { LazyInfiniteTableList } from '@property-folders/components/dragged-components/LazyInfiniteTableList';
import { useLightweightTransaction, useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import {
  DisclosureOfBenefitsTemplate,
  EntitySettingsEntity
} from '@property-folders/contract/yjs-schema/entity-settings';
import React, { useMemo, useState } from 'react';
import { HumanTimestampText } from '@property-folders/components/dragged-components/HumanTimestamp';
import { Button, Container, Modal } from 'react-bootstrap';
import { v4 } from 'uuid';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import {
  CollectionEditor
} from '@property-folders/components/dragged-components/form/CollectionEditor';
import { BenefitLineItem } from '@property-folders/components/dragged-components/form/FeeLineItem';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { SearchBar } from '@property-folders/components/dragged-components/SearchBar';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { FormatAct } from '@property-folders/components/display/GuidanceNotes';
import { LegalJurisdiction } from '@property-folders/common/util/pdfgen/constants';
import { byManyAspects, byMapperFn } from '@property-folders/common/util/sortComparison';

export function DisclosureOfBenefitsEdit() {
  const [filter, setFilter] = useState('');
  const { value: templates } = useLightweightTransaction<DisclosureOfBenefitsTemplate[]>({
    myPath: 'dobTemplates'
  });
  // @ts-ignore
  const { value: defaultTemplateId, handleUpdate: setDefaultTemplateId, handleRemove: clearDefaultTemplateId } = useTransactionField<string>({
    myPath: 'dobDefaultTemplateId'
  });
  const { updateDraft } = useYdocBinder<EntitySettingsEntity>({ path: '' });
  const [editTemplateId, setEditTemplateId] = useState('');

  const items = useMemo(() => {
    const filterValue = filter.toLowerCase();
    return (templates || [])
      .filter(item => !filterValue || item.name.toLowerCase().indexOf(filterValue) >= 0)
      .sort(byManyAspects([
        byMapperFn(x => defaultTemplateId && x.id === defaultTemplateId ? 1 : 0, 'desc'),
        (a, b) => a.name.localeCompare(b.name)
      ]));
  }, [defaultTemplateId, templates, filter]);

  const createTemplate = () => {
    if (!updateDraft) return;

    const id = v4();
    updateDraft(draft => {
      if (!Array.isArray(draft.dobTemplates)) {
        draft.dobTemplates = [];
      }
      const next = draft.dobTemplates.length + 1;
      draft.dobTemplates.push({
        id,
        name: `Template ${next}`,
        modified: Date.now(),
        items: []
      });
    });
    setEditTemplateId(id);
  };

  return <Container fluid={true} className='h-100 position-relative d-flex flex-column flex-start g-0'>
    <div className='d-flex flex-wrap flex-row px-3 pt-3 mb-3 mb-lg-1 gap-2'>
      <div className='flex-grow-1 me-sm-5'>
        <h1 className='mb-0'>Templates</h1>
      </div>
      <div className='flex-grow-1 d-flex flex-row justify-content-end'>
        <Button
          variant='primary'
          size='lg'
          title='Add template'
          onClick={createTemplate}
          style={{ paddingTop: 'calc(0.5rem - 0.5px)', paddingBottom: 'calc(0.5rem - 1px)' }}
        >
          <Icon name='add' variant='outlined' icoClass='fs-4 mb-1 me-2'></Icon>
          Add Template
        </Button>
      </div>
      <div style={{ flexGrow: 999 }}>
        <SearchBar
          placeholder='Search for a template'
          onSearch={term => setFilter(term)}
          className='w-100'
          constrainWidth={false}
        />
      </div>
    </div>

    <LazyInfiniteTableList
      storageKey='DisclosureOfBenefitsTemplates'
      hasNextPage={false}
      items={items}
      columns={[{
        label: 'Name',
        rowMajor: row => row.name,
        rowMinor: row => row.id === defaultTemplateId ? 'Default Template' : undefined
      }, {
        label: 'Items',
        rowMajor: row => row.items.length.toString()
      }, {
        label: 'Last Modified',
        rowMajor: row => (<HumanTimestampText timestampMs={row.modified}/>)
      }]}
      rowActions={[{
        label: 'Make Default',
        action: row => setDefaultTemplateId(row.id, true),
        if: row => row.id !== defaultTemplateId
      }, {
        label: 'Clear Default',
        action: () => clearDefaultTemplateId(),
        if: row => row.id === defaultTemplateId
      }]}
      rowClick={row => setEditTemplateId(row.id)}
      containerClass='w-100'
    />

    {editTemplateId && <Modal show={Boolean(editTemplateId)} onHide={() => setEditTemplateId('')} size='lg'>
      <Modal.Header>
        <Modal.Title>Edit Disclosure of Benefit Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditTemplate id={editTemplateId} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => setEditTemplateId('')}>Close</Button>
      </Modal.Footer>
    </Modal>}
  </Container>;
}

function EditTemplate({ id }: {id: string}) {
  // note: there's some discussion going on about what we will allow agents to enter in the amount column
  // to me legislation fairly clearly shows that the amount should be a dollar value,
  // as correct as possible (e.g. sometimes value needs to be estimated)
  // but this is not how agents seem to behave
  const { value, fullPath } = useLightweightTransaction<DisclosureOfBenefitsTemplate>({ parentPath: `dobTemplates.[${id}]` });
  const showAmountWarning = useMemo(() => {
    return value?.items?.length
      ? value.items.some(item => item.amount && !canonicalisers.aud(item.amount).valid)
      : false;
  }, [value]);

  return <div className='w-100 h-100'>
    <div>
      <WrField.Control
        name={'name'}
        parentPath={fullPath}
        myPath={'name'}
        label={'Template name'}
      />
    </div>
    <div className='mt-3'>
      <CollectionEditor
        parentPath={fullPath}
        myPath={'items'}
        autoAddFirst={false}
        autoAddNew={true}
        childItemRenderer={BenefitLineItem}
        hasHeaderRow={true}
        childProps={{
          columns: [
            { path: 'nature', header: 'Nature of the Benefit*', label: 'Nature' },
            { path: 'source', header: 'Source of the Benefit', label: 'Source' },
            { path: 'amount', header: 'Benefit amount**', label: 'Amount',
              warn: amountWarn,
              warnText: <>
                <p>The amount (or estimated amount or value) in dollars, pursuant to section 24C (4) of the {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}.</p>
                <p>If additional information is required, it should be entered in the Nature field</p>
              </> },
            { path: 'recipient', header: 'Recipient and capacity***', label: 'Recipient' }
          ]
        }}
      />
    </div>
    <p>
      * A description of the benefit. Important context information should be written here.
    </p>
    <p className={showAmountWarning ? 'text-danger' : ''}>
      ** The amount (or estimated amount or value) in dollars, pursuant to section 24C (4) of the {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}.
    </p>
    {showAmountWarning && <p className='text-danger'>If you wish to write additional information about a benefit, please do so in the Nature field.</p>}
    <p>
      *** Refers to the capacity in which the person receives the benefit. e.g. as an agent, a financier, mortgage broker, lawyer.
    </p>
  </div>;
}

function amountWarn(value: any): boolean {
  switch (typeof value) {
    case 'string':
      if (value === '') return false;
      return !canonicalisers.aud(value).valid;
    case 'number':
      return !canonicalisers.aud(value).valid;
    default:
      return false;
  }
}
