import { Link } from 'react-router-dom';

export function UnknownErrorPage(props: {error: any}) {
  const deployEnv = import.meta.env.VITE_DEPLOY_ENVIRONMENT || 'LOCAL';
  const isDev = ['DEV', 'LOCAL'].includes(deployEnv);

  return <div className='page-error'>
    <div>
      <h1>Something went wrong.</h1>
      <div className='d-flex mt-4'>
        <h1 className='text-secondary'>500</h1>
        <h4 className='text-secondary ms-4' style={{ maxWidth: 270 }}>Sorry, we have encountered an error.</h4>
      </div>
      <div className='text-center'>
        <Link to='/index.php'>
          <button className='home btn text-white mt-4'>Take me home</button>
        </Link>
      </div>
    </div>
    {isDev && <div className='mt-4'><code>
      <pre>
        {props.error.message}<br />
        <br />
        {props.error.stack}
      </pre>
    </code></div>}
  </div>;
}
