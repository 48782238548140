import { EntityLargeScreenPreviewClickHandler, EntitySmallScreenPreviewButton } from '~/pages/settings/PreviewHandlers';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { useSize } from '@property-folders/components/hooks/useSize';
import React, { useContext, useRef } from 'react';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { AjaxPhpSettingsImageUpload } from '~/pages/settings/AjaxPhpSettingsImageUpload';
import { Col, Row } from 'react-bootstrap';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';

export enum ReaformsBrandingSettingsPreviewKey {
  Forms = 'forms'
}

export function ReaformsBrandingSettingsEdit({ onPreviewKey }: { onPreviewKey: (key: string) => void }) {
  return <div>
    <EntityLargeScreenPreviewClickHandler onPreviewKey={onPreviewKey} previewKey={ReaformsBrandingSettingsPreviewKey.Forms}>
      <WizardStepPage
        name='subscriptionForms'
        label='Subscription Forms'
        icon='email'
        headerContent={<EntitySmallScreenPreviewButton onPreviewKey={onPreviewKey} previewKey={ReaformsBrandingSettingsPreviewKey.Forms}/>}
      >
        <Row>
          <Col size={12}>
            <h4>Logo</h4>
          </Col>
        </Row>
        <Row>
          <Col size={6}>
            <AjaxPhpSettingsImageUpload />
          </Col>
          <Col size={6}></Col>
        </Row>
      </WizardStepPage>
    </EntityLargeScreenPreviewClickHandler>
  </div>;
}

export function ReaformsBrandingSettingsPreview({ previewKey }: { previewKey: string }) {
  switch (previewKey) {
    case ReaformsBrandingSettingsPreviewKey.Forms:
    default:
      return <FormsPreview />;
  }
}

function FormsPreview() {
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const { value: entity } = useLightweightTransaction<EntitySettingsEntity>({ myPath: '' });
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useSize(ref, 0);
  const fontSize = 0.0175 * width;

  const line1 = [entity?.name, entity?.tradeName].filter((s) => !!s).join(' T/A ');
  const line2 = [entity?.address1, entity?.address2, entity?.suburb, entity?.postcode].filter((s) => !!s).join(' ');
  const line3 = [entity?.phone ? `Tel: ${entity.phone}` : undefined, entity?.rla ? `Agent No: ${entity.rla}` : undefined].filter((s) => !!s).join(', ');
  const line4 = entity?.email ? `Email: ${entity.email}` : '';
  const linesPresent = Boolean(line1 || line2 || line3 || line4);

  return <div className='w-100 p-3'>
    <div ref={ref} className='w-100 position-relative'>
      <img src='/legacy/images/brand_form_preview.png?v=1' style={{ width: '100%', objectFit: 'contain' }}  />
      {entityPhpInfo?.urlLogo && <img className='position-absolute' style={{
        top: '2%',
        left: '2%',
        width: '40%',
        maxWidth: '40%',
        maxHeight: '20%',
        objectFit: 'contain',
        objectPosition: 'left top'
      }} src={entityPhpInfo.urlLogo}/>}
      <div className='position-absolute d-flex flex-column' style={{
        top: '24.75%',
        left: '2.2%',
        fontWeight: 'bold',
        lineHeight: `${fontSize}px`,
        fontSize: `${fontSize}px`,
        width: '40%',
        maxWidth: '40%',
        maxHeight: '20%',
        textWrap: 'nowrap'
      }}>
        {linesPresent ? <>
          <span>{line1}</span>
          <span>{line2}</span>
          <span>{line3}</span>
          <span>{line4}</span>
        </> : <span>Sample Entity T/A Sample TN</span>}
      </div>
    </div>
  </div>;
}
