import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { Spinner } from 'react-bootstrap';
import { createPropertyFromEnvelope, getEnvelopeClaimMetadata, updateEnvelopeClaimMetadata } from '@property-folders/signing/src/util/api';
import { buildYDoc } from '@property-folders/components/form-gen-util/buildYDoc';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { WrappedFetch } from '@property-folders/common/client-api/wrappedFetch';
import { FormCode, FormOrderState, FormOrderType } from '@property-folders/contract';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';

export type EnvelopeMetaDocument = {
  formCode: FormCode,
  formId: string,
  documentId: number,
  url: string
};

export type FormsEnvelopeMeta = {
  propertyFolderId: string,
  documents: EnvelopeMetaDocument[]
};

export const PropertyFromEnvelope = () => {
  const navigate = useNavigate();
  const { envelopeId } = useParams();
  const [ queryParams] = useSearchParams();
  const { instance: fileSync } = useContext(FileSyncContext);
  const params = Object.fromEntries([...queryParams]);
  const forms = params.forms?.split(',') || [];
  const { instance: yManager } = useContext(YManagerContext);
  const [message, setMessage] = useState('Creating Form 1...');
  const [error, setError] = useState('');

  useEffect(()=> {
    (async () => {
      if (!envelopeId) return;

      try {
        const envelopeMeta = await getEnvelopeClaimMetadata(envelopeId);
        if (!envelopeMeta.success) {
          setError('Not authorised to view this property');
          return;
        }

        //If envelope has already been claimed, just update EPF with the metadata, then redirect to the form/PF
        if (envelopeMeta?.metadata?.formMeta) {
          const jsonMeta = JSON.parse(envelopeMeta?.metadata?.formMeta);
          await epfCallback(jsonMeta);
          return redirectBasedOnDocuments(jsonMeta.propertyFolderId, jsonMeta.documents);
        }

        //Otherwise we need to create a new PF/Docs
        const createPropertyFolderResult = await createPropertyFromEnvelope(envelopeId);
        if (!createPropertyFolderResult.success) throw ('Failed to create Property Folder');

        const propertyFolderId = createPropertyFolderResult.propertyFolderId;
        const { ydoc, whenSynced } = buildYDoc(propertyFolderId, false, yManager);
        await whenSynced;

        //Create Docs
        const createdForms = await Promise.allSettled(forms?.map(async(formCode) => {
          return { ...await handleNewForm(ydoc, formCode, fileSync, {
            order: {
              type: FormOrderType.Filler,
              state: FormOrderState.ThirdPartyPreparing,
              source: {
                sourcePropertyId: params.agentPropertyFolderId,
                sourceFormId: params.agentFormId,
                sourceFormCode: formCode,
                sourceEnvelopeId: envelopeId,
                sourceEntityName: createPropertyFolderResult.agencyName
              }
            } }), formCode
          };
        }));

        const successfulForms = createdForms?.filter(f => f.status === 'fulfilled' && f.value?.formCode);

        //Build metadata
        const documents = successfulForms.map(f => {
          const form = f.status === 'fulfilled' ? f.value : undefined;
          return form ? {
            formCode: form.formCode,
            documentId: form.documentId,
            formId: form.formId,
            url: LinkBuilder.reaformsFromRoot(LinkBuilder.documentPath({ id: propertyFolderId }, { id: form.formId }, !!(FormTypes[form.formCode].subscription)), false)
          } : undefined;
        }).filter(Boolean) as EnvelopeMetaDocument[];

        const metadata = {
          formMeta: {
            propertyFolderId,
            documents
          }
        };

        //Update the metadata on the envelope
        //Call EPF callback endpoint
        setMessage('Syncing Attachments...');
        await Promise.all([updateEnvelopeClaimMetadata(envelopeId, { formMeta: JSON.stringify(metadata.formMeta) }), epfCallback(metadata.formMeta)]);

        //Redirect
        redirectBasedOnDocuments(propertyFolderId, documents);
      } catch (e) {
        console.error(e);
      }

    })();
  }, []);

  const epfCallback = async(metadata: FormsEnvelopeMeta) => {
    try {
      await WrappedFetch.bare(params.callbackUrl, {
        method: 'POST',
        headers: new Headers({ 'content-type': 'application/json' }),
        body: JSON.stringify(metadata)
      });
    } catch (err) {
      console.error('Failed to call EPF webhook', err);
    }
  };

  const redirectBasedOnDocuments = (propertyFolderId: string, documents: EnvelopeMetaDocument[] | undefined) => {
    if (documents?.length === 1) {
      const doc = documents[0];
      navigate(LinkBuilder.documentPath({ id: propertyFolderId }, { id: doc.formId }, !!(FormTypes[doc.formCode]?.subscription)));
    } else {
      navigate(LinkBuilder.propertyPath({ id: propertyFolderId }));
    }
  };

  return <div className={'d-flex flex-row justify-content-center mt-5'}>
    <h2 className={'me-4'}>{error || message}</h2>
    {!error && <Spinner animation={'border'} style={{ width: '2.5rem', height: '2.5rem' }}/>}
  </div>;
};
