import React, { useEffect, useRef } from 'react';
import JSONEditor from 'jsoneditor';
import { JSONEditorOptions } from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import { Card } from 'react-bootstrap';

interface JSONEditorComponentProps extends JSONEditorOptions{
  jsonData: any;
}

const JSONEditorComponent: React.FC<JSONEditorComponentProps> = ({ jsonData, ...editorOptions }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  let jsonEditor: Editor | null = null;

  const onChange = () => {
    if (!jsonEditor) {
      return;
    }

    try {
      const text = jsonEditor.get();
      if (editorOptions.onChangeJSON) {
        editorOptions.onChangeJSON(text);
      }
    } catch (e) {
      // throws if invalid json
      return;
    }
  };

  useEffect(() => {
    if (containerRef.current && !jsonEditor) {
      const options: JSONEditorOptions = { ...editorOptions, onChange };

      jsonEditor = new JSONEditor(containerRef.current, options);
      jsonEditor.update(jsonData);
    }

    return () => {
      if (jsonEditor) {
        jsonEditor.destroy();
      }
    };
  }, [jsonData]);

  return (
    <Card>
      <div ref={containerRef} style={{ width: '100%', height: '400px' }} />
    </Card>
  );
};

export const JsonEditor = JSONEditorComponent;
