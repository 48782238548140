import { useDispatch, useSelector } from 'react-redux';
import { hideNotes, REDUCER_NAME as REDUX_NOTES_NAME } from '@property-folders/common/redux-reducers/guidanceNotes';
import { notesTable } from '@property-folders/components/display/GuidanceNotes';
import { Offcanvas } from 'react-bootstrap';
import React from 'react';

export function NotesDisplay() {
  const dispatch = useDispatch();
  const { show, noteId } = useSelector(state => state?.[REDUX_NOTES_NAME]) || {};
  const { title, body } = notesTable[noteId] || {};
  return <Offcanvas show={show} onHide={() => dispatch(hideNotes())} placement='end' className='guidance-notes' backdropClassName='guidance-notes-backdrop'>
    <Offcanvas.Header closeButton>
      <Offcanvas.Title><span className='h4'>{title}</span></Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className='lead guidance-spacing'>
      {body}
    </Offcanvas.Body>
  </Offcanvas>;
}
