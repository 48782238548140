import { FormContextSetup, getDefaultReportMissing } from '@property-folders/components/form-gen-util/yjsStore';
import { aavFieldGroups } from '@property-folders/contract/yjs-schema/model/form/agency-agreement-variation-overlay';
import '../FormInput.scss';
import {
  ResidentialSalesAgreementForm
} from '../ResidentialSalesAgreement/ResidentialSalesAgreementForm';

import { VariationWrapper } from '@property-folders/components/dragged-components/VariationWrapper';
import { merge } from 'lodash';
import { agencyAgreementVariationOverlay, salesAgencyAgreement } from '@property-folders/contract/yjs-schema/model/form';
import { ExtraFormCode } from '@property-folders/contract';
import { propertyMeta, propertyFolder } from '@property-folders/contract/yjs-schema/model/field';

import { FormContextType } from '@property-folders/common/types/FormContextType';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';

const FORM_CODE = ExtraFormCode.AAV_SalesAgencyAgreementVariation;
const FORM_RULES = merge(structuredClone(salesAgencyAgreement),structuredClone(agencyAgreementVariationOverlay));
const TRANSACTION_RULES = propertyFolder;
const META_RULES = propertyMeta;
const FILE_CONTEXT_VALUE: Omit<FormContextType, 'formId'> = {
  formName: FORM_CODE,
  printTitle: FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  wizardTitle: FormTypes[FORM_CODE].wizardTitle ?? FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  formRules: FORM_RULES,
  transactionRules: TRANSACTION_RULES,
  metaRules: META_RULES,
  fieldGroups: aavFieldGroups,
  reportMissing: getDefaultReportMissing(FORM_CODE, FORM_RULES)
};

export const AgencyAgreementVariation = (props: TransactionFormProps) => {
  return <FormContextSetup formId={props.formId} base={FILE_CONTEXT_VALUE}>
    <VariationWrapper {...props} InnerForm={ResidentialSalesAgreementForm} requireFullHistory={true} />
  </FormContextSetup>;
};

