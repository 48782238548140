import { useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';

interface LoaderData {
  additionalQueryParams?: Record<string, string>;
  /**
   * Use moustache pattern to define URL parameters.
   * @example
   * '/users/{userId}'
   */
  redirectPath: string;
}

export const paramRegex = /\{([\w]+)\}/g;

export function NavigateWithParams() {
  const navigate = useNavigate();
  const loaderData: LoaderData | null = useLoaderData() as LoaderData;
  const { additionalQueryParams, redirectPath } = loaderData ?? {};

  // Replace all URL parameters in `to` with their corresponding search query parameter values.
  useEffect(() => {
    const params = additionalQueryParams ?? {};
    const path = redirectPath.replace(paramRegex, (_match, group1) => {
      // `console.error()` is just for dev logging.
      return params[group1 as keyof typeof params] ?? console.error(`[redirect]: '${group1}' does not exist in params.`);
    });
    navigate(path);
  }, [loaderData]);

  return <></>;
}
