import { useState } from 'react';
import { JsonEditor } from '~/components/JSONEditor';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { deepEditObjectInPlace, ObjectDiff } from '@property-folders/common/util/object';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { ChangesetTable } from '~/components/ChangesetTable';

type ChangeSetItem = { key: string; from: any; to: any };
type ChangeSet = ChangeSetItem[];

export function YDocEditorPage() {
  const { updateDraft: updateData, rootBinder: dataBinder } = useYdocBinder({ path: '' });
  const { updateDraft: updateMeta, rootBinder: metaBinder } = useYdocBinder({ path: '', bindToMetaKey: true });
  const [metaChangeSet, setMetaChangeSet] = useState<ChangeSet>([]);
  const [dataChangeSet, setDataChangeSet] = useState<ChangeSet>([]);
  const dataJson = dataBinder?.get();
  const metaJson = metaBinder?.get();

  const updateStateClick = (
    changeSet: ChangeSet,
    setChangeSet: (changeSet: any[]) => void,
    update: ((userFunc: any) => void) | undefined
  ) => {
    update?.((state: any) => {
      for (const change of changeSet) {
        deepEditObjectInPlace(state, change.key, change.to);
      }
    });

    setChangeSet([]);
  };

  const onChangeMeta = (newJson: any) => {
    if (!metaJson) {
      return;
    }

    const changeSet = ObjectDiff.getChangeset(metaJson, newJson).filter(s => s.type !== 'UNCHANGED');
    setMetaChangeSet(changeSet);
  };

  const onChangeData = (newJson: any) => {
    if (!dataJson) {
      return;
    }

    const changeSet = ObjectDiff.getChangeset(dataJson, newJson).filter(s => s.type !== 'UNCHANGED');
    setDataChangeSet(changeSet);
  };

  return <Container className='px-4' style={{ overflow: 'auto' }}>
    <Row>
      <Col>
        <h3>Meta</h3>
        <JsonEditor
          jsonData={metaJson ?? {}}
          onChangeJSON={onChangeMeta}
          mode={window.ultraRawDogMode ? 'code' : 'tree'}
        />
      </Col>
    </Row>

    <Row className='mt-1'>
      <Col>
        <div className='d-flex'>
          <h3>Meta Changeset</h3>
          <Button
            className='ms-auto'
            onClick={() => updateStateClick(metaChangeSet, setMetaChangeSet, updateMeta)}
          >
            Update
          </Button>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <ChangesetTable changeSet={metaChangeSet} />
      </Col>
    </Row>

    <Row className='mt-2'>
      <Col>
        <h3>Data</h3>
        <JsonEditor
          jsonData={dataJson ?? {}}
          onChangeJSON={onChangeData}
          mode={window.ultraRawDogMode ? 'code' : 'tree'}
        />
      </Col>
    </Row>

    <Row>
      <Col>
        <div className='d-flex'>
          <h3>Data Changeset</h3>
          <Button
            className='ms-auto'
            onClick={() => updateStateClick(dataChangeSet, setDataChangeSet, updateData)}
          >
            Update
          </Button>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <ChangesetTable changeSet={dataChangeSet} />
      </Col>
    </Row>
  </Container>;
}
