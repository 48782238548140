import { useMemo, useState } from 'react';
import { LazyInfiniteTableList } from '@property-folders/components/dragged-components/LazyInfiniteTableList';
import { HumanTimestampText, SupportedRelativeTimeFormatUnit } from '@property-folders/components/dragged-components/HumanTimestamp';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { SearchBar } from '@property-folders/components/dragged-components/SearchBar';
import { AgentAvatar } from '@property-folders/components/display/AgentAvatar';
import { FallbackModal } from '@property-folders/components/display/errors/modals';
import { RenameFolderModal } from '@property-folders/components/modals/RenameFolderModal';
import { DeleteFolderModal } from '@property-folders/components/modals/DeleteFolderModal';
import { CreateFolderModal } from '@property-folders/components/modals/CreateFolderModal';
import { useAgentTimezone } from '@property-folders/components/hooks/useAgentTimezone';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FoldersApi } from '@property-folders/common/client-api/foldersApi';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import type { BaseItem, InfiniteScrollListColumn, InfiniteScrollRowAction } from '@property-folders/components/dragged-components/LazyInfiniteTableList';
import type { ListFoldersForAgent } from '@property-folders/contract/rest/folders';

type ListFoldersRowType = ListFoldersForAgent['results'][number] & BaseItem;

export function FoldersListPage() {
  const navigate = useNavigate();
  const timeZone = useAgentTimezone();
  const [filter, setFilter] = useState('');
  const [workingFolder, setWorkingFolder] = useState<ListFoldersRowType | null>(null);
  const [doShowRenameFolderModal, setDoShowRenameFolderModal] = useState<boolean>(false);
  const [doShowDeleteFolderModal, setDoShowDeleteFolderModal] = useState<boolean>(false);
  const [doShowCreateFolderModal, setDoShowCreateFolderModal] = useState<boolean>(false);

  const {
    data,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    refetch
  } = FoldersApi.useInfiniteFoldersList({
    pageNumber: 1,
    limit: 100,
    searchTerm: filter
  });

  const navigateToFolder = (row: ListFoldersRowType) => navigate(`/folders/${row.id}/documents`);

  const items = useMemo<ListFoldersRowType[]>(() => data && data.pages.length ? data.pages.flatMap((p) => p.results || []) : [], [data?.pages]);
  const desktopColumns = useMemo<InfiniteScrollListColumn<ListFoldersRowType>[]>(() => [
    {
      label: 'Name',
      rowMajor: (row) => <span>{row.folderName}</span>
    },
    {
      label: 'Owner',
      headerCellStyle: { width: '20%' },
      rowMajor: (row) => (
        <div>
          <AgentAvatar agent={{ agentId: row.ownerId, name: row.ownerName }} fontSize='12px' />
          <span className='ms-2'>{row.ownerName}</span>
        </div>
      )
    },
    {
      label: 'Modified',
      headerCellStyle: { width: '12%' },
      rowMajor: (row) => (
        <HumanTimestampText
          timestamp={row.dateUpdated}
          maxInterval={SupportedRelativeTimeFormatUnit.week}
          timeZone={timeZone}
        />
      )
    },
    {
      label: 'Created',
      headerCellStyle: { width: '12%' },
      rowMajor: (row) => formatTimestamp(row.dateCreated, timeZone, false),
      hoverText: (row) => formatTimestamp(row.dateCreated, timeZone, true)
    }
  ], []);

  const rowActions: InfiniteScrollRowAction<ListFoldersRowType>[] = [
    { label: 'View', action: navigateToFolder },
    {
      label: 'Rename',
      action: (row) => {
        setWorkingFolder(row);
        setDoShowRenameFolderModal(true);
      }
    },
    {
      label: 'Delete',
      action: (row) => {
        setWorkingFolder(row);
        setDoShowDeleteFolderModal(true);
      }
    }
  ];

  const afterTitle = (
    <>
      <Button
        variant='primary'
        size='lg'
        className='d-flex align-items-center'
        title='Create folder'
        onClick={() => setDoShowCreateFolderModal(true)}
      >
        <Icon name='add' variant='outlined' icoClass='me-2 fs-4'></Icon>
        Create
      </Button>
      <SearchBar onSearch={setFilter} />
    </>
  );

  return (
    <div className='d-flex w-100 h-100 special-modal-container-outer'>
      <ContentTitler
        title='Folders'
        afterTitle={afterTitle}
        scroll={false}
        flex
      >
        <div className='flex-grow-1 mt-2 overflow-hidden position-relative'>
          <LazyInfiniteTableList
            storageKey='Folders'
            hasNextPage={hasNextPage}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            items={items}
            columns={desktopColumns}
            rowHeight='85px'
            rowClick={navigateToFolder}
            rowActions={rowActions}
            hover
          />
        </div>

        <ErrorBoundary
          fallbackRender={(fallback) => (
            <FallbackModal
              {...fallback}
              onClose={() => {
                setDoShowRenameFolderModal(false);
                setWorkingFolder(null);
              }}
              show={doShowRenameFolderModal}
            />
          )}
        >
          <RenameFolderModal
            folderId={workingFolder?.id}
            folderName={workingFolder?.folderName}
            show={doShowRenameFolderModal}
            setShow={setDoShowRenameFolderModal}
            refetch={refetch}
          />
        </ErrorBoundary>

        <ErrorBoundary
          fallbackRender={(fallback) => (
            <FallbackModal
              {...fallback}
              onClose={() => {
                setDoShowDeleteFolderModal(false);
                setWorkingFolder(null);
              }}
              show={doShowDeleteFolderModal}
            />
          )}
        >
          <DeleteFolderModal
            folderId={workingFolder?.id}
            folderName={workingFolder?.folderName}
            show={doShowDeleteFolderModal}
            setShow={setDoShowDeleteFolderModal}
            refetch={refetch}
          />
        </ErrorBoundary>

        <ErrorBoundary
          fallbackRender={(fallback) => (
            <FallbackModal
              {...fallback}
              onClose={() => setDoShowCreateFolderModal(false)}
              show={doShowCreateFolderModal}
            />
          )}
        >
          <CreateFolderModal
            show={doShowCreateFolderModal}
            setShow={setDoShowCreateFolderModal}
            refetch={refetch}
          />
        </ErrorBoundary>
      </ContentTitler>
    </div>
  );
}
