type ChangeSetItem = { key: string; from?: any; to?: any };
type ChangeSet = ChangeSetItem[];

export function ChangesetTable({ changeSet = [] }: { changeSet: ChangeSet }) {
  return <>
    <table style={{ width: '100%' }}>
      <thead>
        <tr>
          <th>Key</th>
          <th>From</th>
          <th>To</th>
        </tr>
      </thead>
      <tbody>
        {changeSet.map(c => <tr>
          <td>{c.key}</td>
          <td>{c.from?.toString() ?? ''}</td>
          <td>{c.to?.toString() ?? ''}</td>
        </tr>)}
      </tbody>
    </table>
  </>;
}

