import { Form } from 'react-bootstrap';
const NewPasswordForm = (
  { password,
    setPassword,
    newPassword,
    setNewPassword
  }: {
    password: string,
    setPassword: (password: string) => void,
    newPassword: string,
    setNewPassword: (password: string) => void
  }) => {

  return (
    <>
      <div className='form-group mt-3'>
        <Form.Control
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          type='password'
          className='form-control mt-1'
          placeholder='Enter new password'
          autoComplete='new-password'
          required
        />
      </div>
      <div className='form-group mt-3'>
        <Form.Control
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
          type='password'
          className='form-control mt-1'
          placeholder='Confirm new password'
          autoComplete='new-password'
          required
        />
      </div>
    </>
  );
};

export default NewPasswordForm;