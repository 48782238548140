import { Button, Modal } from 'react-bootstrap';
import React from 'react';

export function Form1OrderConfirmDialog({ show, onHide, onOrder }: { show: boolean, onHide: () => void, onOrder: () => void }) {
  return <Modal show={show} size='lg' onHide={onHide}>
    <Modal.Header closeButton><Modal.Title>There is already a Form 1 document in this property
      folder</Modal.Title></Modal.Header>
    <Modal.Body>
      <p>Ordering a Form 1 from Eckermann Property Forms will cause the existing document to be deleted.</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={onHide}>Cancel</Button>
      <Button variant={'danger'} onClick={() => {
        onHide();
        onOrder();
      }}>Delete & Order</Button>
    </Modal.Footer>
  </Modal>;
}
