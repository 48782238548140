import { FormContextType } from '@property-folders/common/types/FormContextType';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { LineageWrapper } from '@property-folders/components/dragged-components/VariationWrapper';
import { Wizard } from '@property-folders/components/dragged-components/Wizard/Wizard';
import { FormContextSetup } from '@property-folders/components/form-gen-util/yjsStore';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { ExtraFormCode, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { propertyFolder, propertyMeta } from '@property-folders/contract/yjs-schema/model/field';
import { useMemo } from 'react';
import { SaleContractTerminationExternalPdfDefinitionProvider } from '@property-folders/common/util/pdfgen/definitions/helpers/sales-contract-termination-external';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { useForm } from '@property-folders/components/hooks/useForm';
import { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import { useMatches } from 'react-router-dom';

const FORM_CODE = ExtraFormCode.SCTE_ContractOfSaleTerminationExternal;
const FORM_LABEL = FormTypes[FORM_CODE].label;
const FORM_RULES = {};
const TRANSACTION_RULES = propertyFolder;
const FORM_CONTEXT: Omit<FormContextType, 'formId'> = {
  formName: FORM_CODE,
  printTitle: FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  wizardTitle: FormTypes[FORM_CODE].wizardTitle ?? FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  formRules: FORM_RULES,
  transactionRules: TRANSACTION_RULES,
  metaRules: propertyMeta,
  fieldGroups: {},
  reportMissing: ()=>undefined
};

export function ContractExternalTermination(props) {
  const formId = props.formId;

  const updatedBreadcrumb = useMemo(()=>[...(props.breadcrumbs ?? []), { label: FORM_LABEL }], [props.breadcrumbs]);

  return <FormContextSetup formId={formId} base={FORM_CONTEXT}>
    <LineageWrapper requireFullHistory={false}>
      <SetupPdfLoadStateContext>
        <ContractExternalTerminationInner
          {...props}
          breadcrumbs={updatedBreadcrumb}
        />
      </SetupPdfLoadStateContext>
    </LineageWrapper>
  </FormContextSetup>;
}

function ContractExternalTerminationInner({ entityLogoLoadedUri, formId, breadcrumbs }: TransactionFormProps) {
  const { ydoc, docName, transactionRootKey, transactionMetaRootKey, formName: formCode, wizardTitle } = useForm();
  const { bindState: metaBindState, binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { binder: dataBinder } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ });
  const headline = generateHeadlineFromMaterialisedData(transRoot);
  const pdfDefinition = new SaleContractTerminationExternalPdfDefinitionProvider(
    dataBinder, metaBinder, formCode, formId, true
  );
  const currentPathing = useMatches();
  const contractManagementHref = currentPathing[currentPathing.length-2].pathname + '/contracts';
  const updatedBread: CrumbDefn[] = [...breadcrumbs.slice(0,-2), { label: 'Contract Management', href: contractManagementHref }, breadcrumbs[breadcrumbs.length-1]];
  return <Wizard
    formId={formId}
    formName={formCode}
    transactionRootKey={transactionRootKey}
    transactionMetaRootKey={transactionMetaRootKey}
    docName={docName}
    title={wizardTitle}
    printHeadline={headline}
    breadcrumbs={updatedBread}
    pdfDefinition={pdfDefinition}
    ydoc={ydoc}
    signing={true}
    entityLogoLoadedUri={entityLogoLoadedUri}
  ><div>This document has been Terminated</div></Wizard>;
}