import { useCallback, useContext, useMemo } from 'react';
import { Wizard } from '@property-folders/components/dragged-components/Wizard/Wizard';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import { FormContextSetup, getDefaultReportMissing } from '@property-folders/components/form-gen-util/yjsStore';
import {
  saafieldGroups as saaFieldGroups,
  salesAgencyAgreement
} from '@property-folders/contract/yjs-schema/model/form/sales-agency-agreement';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { ExtraFormCode, FormCode, FormInstance, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { useUpdateInstanceModifiedOnLoad } from '@property-folders/components/hooks/useUpdateInstanceModifiedOnLoad';
import { RSAASaleInput } from '@property-folders/components/dragged-components/form/RSAASale';
import { SaleMethodInput } from '@property-folders/components/dragged-components/form/SaleMethodInput';
import { ParticularsInput } from '@property-folders/components/dragged-components/form/ParticularsInput';
import '../FormInput.scss';
import { ManualFeesSection } from '@property-folders/components/dragged-components/form/ManualFeesSection';
import { useForm } from '@property-folders/components/hooks/useForm';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { ProfessionalFeeSection } from '@property-folders/components/dragged-components/form/ProfessionalFeeSection';
import { VendorWizardStepPage } from '@property-folders/components/dragged-components/Wizard/VendorWizardStepPage';
import { PropertyWizardStepPage } from '@property-folders/components/dragged-components/Wizard/PropertyWizardStepPage';
import { AgentWizardStepPage } from '@property-folders/components/dragged-components/Wizard/AgentWizardStepPage';
import { AnnexuresSection } from '@property-folders/components/dragged-components/form/AnnexuresSection';
import { ResidentialSalesAgencyAgreementPdfDefinitionProvider, SalesAgreementVariationPdfDefinitionProvider } from '@property-folders/common/util/pdfgen/definitions/helpers';
import { ClausesWizardStepPage } from '@property-folders/components/dragged-components/Wizard/ClausesWizardStepPage';
import { propertyFolder, propertyMeta } from '@property-folders/contract/yjs-schema/model/field';
import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { requirePropertyDetailsMessage } from '@property-folders/common/util/formatting/constants';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import {
  SigningConfigurationInterceptor
} from '@property-folders/components/dragged-components/signing/SigningConfiguration';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { UpdatesPendingBadge } from '@property-folders/components/display/properties/UpdatesPendingBadge';
import { PropertyBackgroundJobsContext } from '@property-folders/components/context/PropertyBackgroundJobs';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { IPdfDefinitionProvider } from '@property-folders/common/types/PDFDefinition';

import { saaNonUniqueWatchPaths } from '@property-folders/contract/yjs-schema/variations/sales-agency-agreement';
import { useSetAuctionSettlement } from '@property-folders/components/hooks/useSetAuctionSettlement';

const FORM_CODE = FormCode.RSAA_SalesAgencyAgreement;
const FORM_LABEL = FormTypes[FORM_CODE].label;
const FORM_RULES = salesAgencyAgreement;
const TRANSACTION_RULES = propertyFolder;
const FORM_CONTEXT: Omit<FormContextType, 'formId'> = {
  formName: FORM_CODE,
  printTitle: FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  wizardTitle: FormTypes[FORM_CODE].wizardTitle ?? FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  formRules: FORM_RULES,
  transactionRules: TRANSACTION_RULES,
  metaRules: propertyMeta,
  fieldGroups: saaFieldGroups,
  reportMissing: getDefaultReportMissing(FORM_CODE)
};

// These lists are static, don't generate them inplace as this can cause renders in spite of
// memoisation
const varWatch = {
  ...saaNonUniqueWatchPaths,
  clauses: [['clauses']]
};

export const ResidentialSalesAgreementForm = (props: TransactionFormProps) => {
  const formCode = FORM_CODE;
  const formId = props.formId;
  const { ydoc, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  const { binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { binder: dataBinder } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const updatedBreadcrumb = useMemo(()=>[...(props.breadcrumbs ?? []), { label: FORM_LABEL }], [props.breadcrumbs]);
  const pdfDefinition = useMemo<IPdfDefinitionProvider>(() => {
    return props.variationsMode
      ? new SalesAgreementVariationPdfDefinitionProvider(dataBinder,
        metaBinder,
        ExtraFormCode.AAV_SalesAgencyAgreementVariation,
        formId,
        true)
      : new ResidentialSalesAgencyAgreementPdfDefinitionProvider(dataBinder,
        metaBinder,
        formCode,
        formId,
        true);
  }, [dataBinder, metaBinder, formCode, formId, props.variationsMode]);

  return <FormContextSetup formId={formId} base={FORM_CONTEXT}>
    <SigningConfigurationInterceptor
      {...props}
      breadcrumbs={updatedBreadcrumb}
      pdfDefinition={pdfDefinition}
      documentRenderer={() => (<SetupPdfLoadStateContext>
        <ResidentialSalesAgreementFormInner
          {...props}
          variationsMode={props.variationsMode}
          breadcrumbs={updatedBreadcrumb}
          pdfDefinition={pdfDefinition} />
      </SetupPdfLoadStateContext>)}/>
  </FormContextSetup>;
};

export const ResidentialSalesAgreementFormInner = ({ formId, parentPath = '', myPath = '', breadcrumbs, entityLogoLoadedUri, pdfDefinition, variationsMode, labelOverride }: TransactionFormProps & {pdfDefinition: IPdfDefinitionProvider, variationsMode: boolean | undefined}): JSX.Element => {
  const { ydoc, docName, transactionRootKey, transactionMetaRootKey, formName: formCode, formFamilyCode, wizardTitle } = useForm();

  const { bindState: bindRoot } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const { data: transRoot, update: updateDraft } = bindRoot<MaterialisedPropertyData>(state => state);

  const { bindState } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { data: formInstances, update: updateMeta } = bindState<FormInstance[]>(state => state?.formStates?.[formFamilyCode]?.instances || []);
  const instance = useMemo(()=>{
    return formInstances && formInstances?.find(inst => inst.id === formId);
  }, [formInstances, formId]);
  const backgroundJobsContext = useContext(PropertyBackgroundJobsContext);

  // when the form instance is loaded, then update modified date, but only if it's not completed.
  // I suppose if we wanted to go more crazy we could do it if the user actually changes a field
  useUpdateInstanceModifiedOnLoad(instance, formId, formCode, updateMeta);

  const { value } = useTransactionField({ parentPath, myPath });

  const annexureVariationCheck = useCallback(()=>(instance?.annexures?.length??0) > 0,[instance?.annexures?.length]);
  const annexureVariationCallbackList = useMemo(()=>[annexureVariationCheck],[annexureVariationCheck]);
  const headline = generateHeadlineFromMaterialisedData(value);

  useSetAuctionSettlement(transRoot, updateDraft);

  return (
    /*TODO - find a better way to selectivley debounce PDF renders*/
    <Wizard
      formName={formCode}
      formId={formId}
      transactionRootKey={transactionRootKey}
      transactionMetaRootKey={transactionMetaRootKey}
      docName={docName??'UnknownDocCode'}
      title={wizardTitle}
      afterTitle={<UpdatesPendingBadge propertyId={backgroundJobsContext?.propertyId} headline={headline} show={backgroundJobsContext.envelopeChangesAvailable} className='ms-auto me-2 mt-1' />}
      printHeadline={headline}
      breadcrumbs={breadcrumbs}
      pdfDefinition={pdfDefinition}
      ydoc={ydoc}
      signing={true}
      entityLogoLoadedUri={entityLogoLoadedUri}
    >

      <PropertyWizardStepPage
        name="property"
        label="Property"
        icon="home"
        generalFocusClass={composeErrorPathClassName([],'property-section')}
        sectionErrorText={requirePropertyDetailsMessage}
        variationWatchPaths={varWatch.property}
      />

      <VendorWizardStepPage
        name="vendor"
        label="Vendor"
        icon="person"
        variationWatchPaths={varWatch.vendor}
      />

      <AgentWizardStepPage
        name="agent"
        label="Agent"
        icon="real_estate_agent"
        variationWatchPaths={varWatch.agency}
      />

      <WizardStepPage
        name="sale"
        label="Sale"
        pageOnlyLabel={<span>Sale</span>}
        icon='monetization_on'
        variationWatchPaths={varWatch.sale}
      >
        <RSAASaleInput/>
      </WizardStepPage>

      <WizardStepPage
        name="saleMethod"
        label="Method of Sale"
        icon='gavel'
        variationWatchPaths={varWatch.saleMethod}
      >
        <SaleMethodInput/>
      </WizardStepPage>

      <WizardStepPage
        name="particulars"
        label="Property and Sale Particulars"
        icon='rule'
        variationWatchPaths={varWatch.particulars}
      >
        <ParticularsInput/>
      </WizardStepPage>

      <WizardStepPage
        name="fees"
        label="Marketing Fees"
        pageOnlyLabel='Marketing and Other Costs'
        icon='payments'
        variationWatchPaths={varWatch.marketing}
        useSearchInHeader
      >
        <ManualFeesSection/>
      </WizardStepPage>

      <WizardStepPage
        name="fees_professional"
        label="Professional Fee"
        icon='assignment_ind'
        variationWatchPaths={varWatch.proFees}
      >
        <ProfessionalFeeSection/>
      </WizardStepPage>

      <ClausesWizardStepPage
        myPath='clauses'
        name='clauses'
        label='Additional Conditions'
        icon='playlist_add'
        variationWatchPaths={varWatch.clauses}
      />

      <WizardStepPage
        name='annexures'
        label='Annexures'
        icon='attach_file'
        variationDeterminationCallback={annexureVariationCallbackList}
      >
        <AnnexuresSection
          formCode={formCode}
          formId={formId}
          ydoc={ydoc}
          transactionMetaRootKey={transactionMetaRootKey}
        />
      </WizardStepPage>
    </Wizard>
  );
};
