import { Button, Col, Container, Row } from 'react-bootstrap';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import { OnlinePropertiesList } from '@property-folders/components/dragged-components/property/OnlinePropertiesList';
import { OfflinePropertiesList } from '@property-folders/components/dragged-components/property/OfflinePropertiesList';
import { useDebounce } from '@react-hook/debounce';
import { useNavigate } from 'react-router-dom';
import './PropertiesPage.scss';
import { useUserPreferences } from '@property-folders/components/hooks/useUserPreferences';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { OnPropertyArchiveSetHandler } from '@property-folders/components/dragged-components/property/PropertyCard';
import { useFeatureFlags } from '@property-folders/components/hooks/useFeatureFlags';
import { SearchType } from '@property-folders/components/display/SearchType';
import { SearchBar } from '@property-folders/components/dragged-components/SearchBar';
import { useBreakpointValue } from '@property-folders/components/hooks/useBreakpointValue';

export function PropertiesPage() {
  const isOnline = useOnline();
  const [archivalOverrides, setArchivalOverrides] = useState<Map<string, boolean>>(new Map());
  const [showArchived, setShowArchived] = useState(false);
  const [showStarred, setShowStarred] = useState(false);
  const [searchTerm, setSearchTerm] = useDebounce('', 300);
  const navigate = useNavigate();
  const { data: userPrefs } = useUserPreferences();
  const [starredProperties, setStarredProperties] = useState<Set<string>>(new Set());
  useEffect(() => {
    setStarredProperties(new Set(userPrefs?.starredPropertyIds || []));
  }, [userPrefs?.starredPropertyIds]);
  const { instance: yManagerInstance } = useContext(YManagerContext);
  useEffect(() => {
    if (!yManagerInstance) return;

    yManagerInstance.garbageCollect().finally(() => { /**/
    });
  }, [!!yManagerInstance]);
  const featureFlags = useFeatureFlags();
  const searchBarStyles = useBreakpointValue({ lg: { height: 42.25 } }, { flexDirection: 'column' });

  const onPropertyArchiveSet: OnPropertyArchiveSetHandler = useCallback((propertyId: string, newState: boolean | null) => setArchivalOverrides(ps => {
    const mutatedMap = new Map(ps);
    if (newState == null) {
      mutatedMap.delete(propertyId);
      return mutatedMap;
    }
    mutatedMap.set(propertyId, newState);
    return mutatedMap;
  }), []);

  if (!featureFlags?.newPropertyFolders) {
    navigate('/folders');
    return <></>;
  }

  const placeholder = isOnline
    ? 'Search for a property'
    : 'Search for a saved property (offline mode)';

  return (
    <Container
      fluid={true}
      className={'h-100 position-relative g-0 d-flex flex-column flex-start'}
    >
      <Row className={'px-3 pt-3'}>
        <Col lg={3} xs={9} className={'pb-3'}>
          <h1 className={'mb-0'}>Properties</h1>
        </Col>
        <Col lg={3} xs={3} align={'end'} className={'pb-3 d-flex justify-content-end'}>
          <Button variant={'primary'} size={'lg'} className='d-sm-none' onClick={() => {
            navigate('/properties/new');
          }}
          title='Create a new property folder'
          >
            <Icon name={'add'} variant={'outlined'} icoClass='fs-4'></Icon>
          </Button>
          <Button variant={'primary'} size={'lg'} className='d-none d-sm-inline-block' onClick={() => {
            navigate('/properties/new');
          }}
          title='Create a new property folder'
          >
            <Icon name={'add'} variant={'outlined'} icoClass='me-2 fs-4 mb-1'></Icon>
            Add Property
          </Button>
        </Col>
        <Col className='d-flex' style={searchBarStyles}>
          <SearchBar
            onSearch={e => setSearchTerm(e)}
            placeholder={placeholder}
            className='mb-2 w-100'
            constrainWidth={false}
          />
          <SearchType
            setShowArchived={setShowArchived}
            setShowStarred={setShowStarred}
            archived={isOnline}
          />
        </Col>
      </Row>

      {isOnline
        ? <OnlinePropertiesList
          searchTerm={searchTerm}
          showArchived={showArchived}
          showStarred={showStarred}
          starredProperties={starredProperties}
          archivalOverrides={archivalOverrides}
          onPropertyArchiveSet={onPropertyArchiveSet}
        />
        : <OfflinePropertiesList
          searchTerm={searchTerm}
          showStarred={showStarred}
          starredProperties={starredProperties}
        />
      }
    </Container>
  );
}
