import { Button, Col, Container, FloatingLabel, Form, Modal, Row, Spinner } from 'react-bootstrap';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BaseAjaxResponse, LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { Maybe } from '@property-folders/contract';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import clsJn from '@property-folders/common/util/classNameJoin';
import useDetectSticky from '@property-folders/components/hooks/useDetectSticky';
import { isEqual } from 'lodash';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import {
  validateChange,
  ValidationNode
} from '@property-folders/common/yjs-schema/property/validation/process-validation';

export function MyProfileModal({
  onClose,
  onSave
}: {
  onClose: () => void,
  onSave: () => Promise<void>
}) {
  const { data: session } = AuthApi.useGetAgentSessionInfo();
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [init, setInitData] = useState<Maybe<LoadAgentResponse>>(undefined);
  const [latest, setLatestData] = useState<Maybe<LoadAgentResponse>>(undefined);
  const [headerIsStuck, headerRef] = useDetectSticky(undefined, undefined, false);
  const [footerIsStuck, footerRef] = useDetectSticky(undefined, undefined, true);
  const formRef = useRef<HTMLFormElement>(null);
  const unchanged = useMemo(() => {
    return isEqual(init, latest);
  }, [init, latest]);

  const validationResult = useMemo(() => {
    return validateChange(
      latest || { data: {}, entities: [] },
      {
        _type: 'Map',
        data: {
          _type: 'Map',
          AgentName: { _type: 'string', _required: true },
          AgentEmail: { _type: 'string', _subtype: 'email', _required: true },
          AgentPhone: { _type: 'string', _subtype: 'phone' }
        }
      },
      {
        data: {
          AgentName: { _required: true },
          AgentEmail: { _required: true }
        }
      },
      {},
      []
    );
  }, [latest]);
  const valid = validationResult._validationResult.valid;
  if (!valid && !loading) {
    // frontend debugging
    console.error('validationResult', validationResult);
  }

  const setLatestDataField = (key: keyof LoadAgentResponse['data'], value: string | NumericBool | string[]) => {
    setLatestData(cur => {
      if (!cur) return cur;
      const clone = structuredClone(cur);
      // @ts-ignore
      clone.data[key] = value;
      return clone;
    });
  };

  useEffect(() => {
    if (!session?.agentId) return;
    LegacyApi.ajax<LoadAgentResponse>('loadagent', { AgentID: session.agentId.toString() })
      .then(data => {
        setInitData(data);
        setLatestData(data);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [session?.agentId]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!session) return;
    e.preventDefault();
    e.stopPropagation();

    if (!valid) {
      return;
    }

    setSaving(true);
    setErrorMessage('');
    AjaxPhp.saveAgent({
      agentId: session.agentId,
      // @ts-ignore
      formData: new FormData(e.currentTarget)
    })
      .then(response => {
        if (response?.success) {
          return onSave();
        }

        if (response?.message) {
          setErrorMessage(response.message);
          return;
        }

        console.error(response);
        setErrorMessage('There was an error saving changes. Please try again.');
      })
      .catch(console.error)
      .finally(() => setSaving(false));
  };

  function getValidationErrors(...path: string[]) {
    let current = validationResult;
    for (const item of path) {
      current = current?.[item];
    }
    return (current as ValidationNode | undefined)
      ?._validationResult
      ?.errors || [];
  }

  function makeText(
    label: string,
    type: string,
    name: keyof LoadAgentResponse['data']
  ) {
    if (!latest) return <></>;

    const errors = getValidationErrors('data', name);

    return <FloatingLabel label={label}>
      <Form.Control
        type={type}
        name={name}
        placeholder={''}
        defaultValue={latest.data[name]}
        onChange={e => {
          setLatestDataField(
            e.currentTarget.name as keyof LoadAgentResponse['data'],
            e.currentTarget.value);
        }}
        isInvalid={Boolean(errors.length)}
      />
      <ErrorLabel label={label || ''} errors={errors}/>
    </FloatingLabel>;
  }

  function makeCheck(
    name: keyof LoadAgentResponse['data'],
    label: string
  ) {
    if (!latest) return <></>;

    return <Form.Check
      type='checkbox'
      id={name}
      name={name}
      label={label}
      value='1'
      defaultChecked={latest.data[name] == '1'}
      onChange={e => {
        setLatestDataField(
          e.currentTarget.name as keyof LoadAgentResponse['data'],
          e.currentTarget.checked ? 1 : 0);
      }}
    />;
  }

  return <Modal show={true} onHide={saving ? undefined : onClose} size='lg'>
    {loading
      ? <>
        <Modal.Header><Modal.Title>My Profile</Modal.Title></Modal.Header>
        <Modal.Body className='d-flex justify-content-center align-items-center p-5'>
          <Spinner animation='border'/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={onClose}>Cancel</Button>
        </Modal.Footer>
      </> : (init && latest) ? <Form ref={formRef} onSubmit={onSubmit} noValidate>
        <Modal.Header ref={headerRef} className={clsJn('sticky bg-white', headerIsStuck && 'stuck')} data-stick='top'>
          <Modal.Title>My Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {latest.form1 && <Row>
              <Col>
                <h5>General</h5>
              </Col>
            </Row>}
            <Row>
              <Col xs={12} sm={6}>
                {makeText('Name', 'text', 'AgentName')}
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} className='mt-2'>
                {makeText('Email', 'email', 'AgentEmail')}
              </Col>
              <Col xs={12} sm={6} className='mt-2'>
                {makeText('Mobile', 'text', 'AgentPhone')}
              </Col>
            </Row>
            {latest.form1 && <>
              <Row>
                <h5 className='mt-4'>Eckermann Property Forms</h5>
                <p>Eckermann Property Forms has extensive integration with reaforms and Greatforms, making it easy for
                  you to order premium Form 1 preparation for your sales transactions. </p>
                <h6>Methods of Service</h6>
                <p>You can customise the information that our team will enter into the Form 1 under the Methods of
                  Service for cooling off notices when you are selected as the Salesperson.</p>
              </Row>
              <Row>
                <Col xs={12} sm={6} className='mt-2'>
                  {makeText('Fax or email address for notices', 'text', 'Form1ServiceFaxOrEmailUser')}
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} className='mt-2'>
                  {makeText('Address for notices', 'text', 'Form1ServiceAddressUser')}
                </Col>
                <Col xs={12} sm={6} className='mt-2 align-content-center'>
                  {makeCheck('Form1ServiceAddressIsRLAUser', 'This address is the RLA address')}
                </Col>
              </Row>
            </>}
          </Container>
        </Modal.Body>
        <Modal.Footer ref={footerRef} className={clsJn('sticky bg-white', footerIsStuck && 'stuck')} data-stick='bot'>
          {errorMessage && <span className='text-danger'>{errorMessage}</span>}
          <Button disabled={saving} variant='outline-secondary' onClick={onClose}>Cancel</Button>
          <SpinnerButton type='submit' processing={saving} disabled={unchanged || !valid}>Save</SpinnerButton>
        </Modal.Footer>
      </Form> : <div>Failed to load</div>}
  </Modal>;
}

type NumericBool = 0 | 1;

interface LoadAgentResponse extends BaseAjaxResponse {
  data: {
    AgentEmail: string;
    AgentName: string;
    AgentPhone: string;
    Form1ServiceAddressIsRLAUser: NumericBool;
    Form1ServiceAddressUser: string;
    Form1ServiceFaxOrEmailUser: string;
  };
  form1: boolean;
}

// duped code yes, but there is an upcoming PR that will resolve it.
function ErrorLabel({ label: labelRaw, errors, message: messageOverride }: {
  label?: string,
  errors: string[],
  message?: string
}) {
  const first = errors.at(0);
  if (!first) return <></>;

  const label = labelRaw || 'This';
  const message = (() => {
    if (messageOverride) return messageOverride;
    switch (first) {
      case 'required':
        return `${label} is required.`;
      case 'abnacn':
        return `${label} must be a valid ABN / ACN.`;
      case 'email':
        return `${label} must be a valid email address.`;
      case 'phone':
        return `${label} must be a valid phone number.`;
      default:
        return `${first} unhandled error type.`;
    }
  })();

  return <small className='text-danger'>{message}</small>;
}
