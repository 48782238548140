import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormContextSetup, FormContextSigningOverride } from '@property-folders/components/form-gen-util/yjsStore';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import {
  FormCode,
  FormCodeUnion,
  FormInstance,
  FormInstanceSigning,
  FormSigningState,
  MaterialisedPropertyData,
  TransactionMetaData,
  uploadTypeOpts
} from '@property-folders/contract';
import './FormInput.scss';
import { FormTypes, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { FileStorage } from '@property-folders/common/offline/fileStorage';
import { PDFViewer } from '@property-folders/components/dragged-components/PDFViewer/PDFViewer';
import { Allotment, LayoutPriority } from 'allotment';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { Button, Card, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { formatBytes } from '@property-folders/common/react/uncompiled-lib/react-dropzone-uploader/utils';
import { useLightweightTransaction, useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { FormUtil, generateInitiator } from '@property-folders/common/util/form';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useStore } from 'react-redux';
import clsJn from '@property-folders/common/util/classNameJoin';
import {
  SigningFieldsConfiguration
} from '@property-folders/components/dragged-components/signing/fieldConfiguration/SigningFieldsConfiguration';
import { SigningConfiguration } from '@property-folders/components/dragged-components/signing/SigningConfiguration';
import { FormContext } from '@property-folders/components/context/FormContext';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { useEntities } from '@property-folders/components/hooks/useEntity';
import { useSigningNavProps } from '@property-folders/components/hooks/useSigningNavProps';
import { SigningSession } from '@property-folders/components/dragged-components/signing/SigningSession';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { useLiveQuery } from 'dexie-react-hooks';
import { usePdfPreviewUrl } from '@property-folders/components/dragged-components/subscriptionForms/hooks';
import { ExtractedField } from '@property-folders/common/util/pdf/types';
import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { UserPreferencesRootKey } from '@property-folders/contract/yjs-schema/user-preferences';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { prepareForSigningCustomPdf } from '@property-folders/components/dragged-components/Wizard/prepareForSigningHandlers';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';

const FORM_CODE = FormCode.UploadedDocument;
const FORM_LABEL = FormTypes[FORM_CODE].label;
const FORM_CONTEXT: Omit<FormContextType, 'formId'> = {
  formName: FORM_CODE,
  transactionRules: { _type: 'Map' },
  metaRules: { _type: 'Map' }
};

enum AllotmentPaneMode {
  Both = 0,
  Content = 1,
  Preview = 2
}

export const UploadedDocumentPage = (props: TransactionFormProps) => {
  const formId = props.formId;
  const updatedBreadcrumb = useMemo(() => [...(props.breadcrumbs ?? []), {
    label: 'All Documents',
    href: `${props.breadcrumbs[props.breadcrumbs.length - 1]?.href}/documents`
  }, { label: FORM_LABEL }], [props.breadcrumbs]);
  return <FormContextSetup formId={formId} base={FORM_CONTEXT}>
    <UploadedDocumentInner {...props} breadcrumbs={updatedBreadcrumb}/>
  </FormContextSetup>;
};

export const UploadedDocumentInner = ({ breadcrumbs, entityLogoLoadedUri }: TransactionFormProps): JSX.Element => {
  const splitEnabled = useMediaQuery({ minWidth: BP_MINIMA.xl });
  const { ydoc, transactionRootKey, transactionMetaRootKey, docName: propertyId } = useContext(YjsDocContext);
  const { formId, formName: formCode } = useContext(FormContext);
  const { value: form, fullPath: formPath } = useTransactionField<FormInstance>({
    parentPath: FormUtil.getFormPath(formCode as FormCodeUnion, formId),
    bindToMetaKey: true
  });
  const { updateDraft: updateForm } = useYdocBinder<FormInstance>({
    path: formPath,
    bindToMetaKey: true
  });

  const { binder: dataBinder } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const {
    bindState: metaBindState,
    binder: metaBinder
  } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const store = useStore();
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { value: signing } = useLightweightTransaction<FormInstanceSigning>({
    parentPath: FormUtil.getFormPath(FORM_CODE, formId),
    myPath: 'signing',
    bindToMetaKey: true,
    ydocForceKey: transactionMetaRootKey
  });
  const [unfilledFields, setUnfilledFields] = useState<ExtractedField[]>([]);
  const { pdfPreviewUrl: previewUrls, pdfLoadErrorMessage } = usePdfPreviewUrl({
    form,
    formCode,
    formId,
    yDoc: ydoc,
    unsetFieldsHandler: fields => {
      setUnfilledFields(cur => {
        if (cur.length === 0 && fields.length === 0) {
          return cur;
        }
        return fields;
      });
    }
  });
  const fileSize = useLiveQuery(async () => {
    const file = await FileStorage.read(formId);
    return file?.data?.size || file?.size || undefined;
  }, [formId]);
  const { instance: fileSync } = useContext(FileSyncContext);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const memberEntities = useEntities();
  const localEntity = memberEntities && meta?.entity?.id ? memberEntities?.[meta.entity.id] : null;

  const {
    signingSessionWizardPropsForSidebar,
    showConfiguration,
    showSigningSession,
    partyGroups,
    serveToPurchaserProps,
    signingMainProps
  } = useSigningNavProps({ signing, formCode });

  const signingState = signing?.state || FormSigningState.None;
  const customiseScreen = signing?.customiseScreen;

  const handleArchive = () => {
    updateForm?.(draft => {
      if (!draft) return;
      draft.archived = !draft.archived;
      draft.dataModified = Date.now();
    });
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateForm?.(draft => {
      if (!draft.upload) return;
      draft.upload.name = `${e.target.value}.pdf`;
      draft.dataModified = Date.now();
    });
  };

  const startSigningHandler = () => {
    FormUtil.transitionSigningState({
      store,
      formCode: FORM_CODE,
      formId: formId,
      metaBinder,
      dataBinder,
      sessionInfo,
      entitySigningOpts: localEntity.signingOptions
    }, {
      to: FormSigningState.Configuring
    });
  };

  const cancelSigningHandler = () => {
    console.log('transition signing to configuring');
    FormUtil.transitionSigningState({
      store,
      formCode: FORM_CODE,
      formId: formId,
      metaBinder,
      dataBinder,
      sessionInfo
    }, {
      to: FormSigningState.None
    });
  };

  const setCustomisationScreenHandler = (toScreen: 'general' | 'fields') => {
    metaBinder?.update(draft => {
      const signing = FormUtil.getSigning(formCode, formId, draft);
      if (signing?.state !== FormSigningState.Configuring) return;

      switch (toScreen) {
        case 'fields':
          signing.customiseScreen = 'fields';
          FormUtil.clearUnnecessaryCustomFields(signing);
          break;
        case 'general':
          delete signing.customiseScreen;
          break;
      }
    });
  };

  const { instance: yManagerInstance } = useContext(YManagerContext);
  const getCurrentUserPrefs = useCallback(()=>yManagerInstance?.getUserPrefs()?.doc.getMap(UserPreferencesRootKey.Main).toJSON(), [yManagerInstance]);

  const [prepareForSigningError, setPrepareForSigningError] = useState('');
  const prepareForSigningHandler = async () => {
    if (!ydoc) return;
    if (!meta) return;
    if (!memberEntities || !localEntity) return;

    setPrepareForSigningError('');
    try {
      const { error } = await prepareForSigningCustomPdf({
        formId,
        formCode: formCode as FormCodeUnion,
        sessionInfo,
        store,
        fileSync,
        dal: new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey),
        initiator: generateInitiator(meta, sessionInfo, localEntity),
        entitySigningOpts: localEntity.signingOptions,
        getUserPrefsData: getCurrentUserPrefs,
        memberEntities
      });

      if (error) {
        setPrepareForSigningError(error.message);
      }

    } catch (err: unknown) {
      console.error(err);
      setPrepareForSigningError('Unexpected error');
    }
  };

  const [allotmentPaneMode, setAllotmentPaneMode] = useState(splitEnabled ? AllotmentPaneMode.Both : AllotmentPaneMode.Content);
  const onVisibleChange = useCallback((changedIndex: number, changedNowVisible: boolean) => {
    if (!changedNowVisible) {
      return setAllotmentPaneMode(changedIndex === 0 ? AllotmentPaneMode.Preview : AllotmentPaneMode.Content);
    }

    if (splitEnabled) {
      return setAllotmentPaneMode(AllotmentPaneMode.Both);
    }

    setAllotmentPaneMode(changedIndex === 0 ? AllotmentPaneMode.Content : AllotmentPaneMode.Preview);
  }, [splitEnabled]);

  useEffect(() => {
    if (splitEnabled) {
      setAllotmentPaneMode(AllotmentPaneMode.Both);
    } else {
      setAllotmentPaneMode(AllotmentPaneMode.Content);
    }
  }, [splitEnabled]);

  const showContentPane = allotmentPaneMode === AllotmentPaneMode.Both || allotmentPaneMode === AllotmentPaneMode.Content;
  const showPreviewPane = allotmentPaneMode === AllotmentPaneMode.Both || allotmentPaneMode === AllotmentPaneMode.Preview;

  const afterTitle = <>
    {signingState === FormSigningState.None &&
      <Button variant='outline-secondary' onClick={handleArchive}>{form?.archived ? 'Unarchive' : 'Archive'}</Button>}
    {signingState === FormSigningState.None &&
      <Button variant='primary' className='d-none d-md-block' disabled={form?.archived} onClick={startSigningHandler}>Signing</Button>}
    {signingState === FormSigningState.Configuring &&
      <Button variant='outline-secondary' onClick={cancelSigningHandler}>Cancel Signing</Button>}
    {signingState === FormSigningState.Configuring && customiseScreen === 'fields' &&
      <Button
        variant='outline-secondary'
        onClick={() => setCustomisationScreenHandler('general')}
      >Previous</Button>}
    {signingState === FormSigningState.Configuring && customiseScreen === 'fields' &&
        <AsyncButton onClick={() => prepareForSigningHandler()} processingLabel={'Preparing document'}>Submit for Signing</AsyncButton>}
  </>;

  if (!propertyId) {
    return <></>;
  }

  const title = `${form?.upload?.name || ''} ${form?.archived ? '(archived)' : ''}`;

  if (showConfiguration) {
    return <ContentTitler
      breadcrumbs={breadcrumbs}
      title={title}
      afterBreadcrumbs={<>{prepareForSigningError && <span className='text-danger'>{prepareForSigningError}</span>}</>}
      afterTitle={afterTitle}
      flex={true}
    >
      <FormContextSigningOverride>
        {signing?.customiseScreen === 'fields'
          ? <SigningFieldsConfiguration
            propertyId={propertyId}
            formCode={formCode}
            formId={formId}
            entityLogoLoadedUri={entityLogoLoadedUri}
            ydoc={ydoc}
            yRootKey={transactionRootKey}
            yMetaRootKey={transactionMetaRootKey}
            prepareForSigning={prepareForSigningHandler}
            setCustomiseScreen={setCustomisationScreenHandler}
          />
          : <SigningConfiguration
            propertyId={propertyId}
            formCode={formCode}
            formId={formId}
            entityLogoLoadedUri={entityLogoLoadedUri}
            ydoc={ydoc}
            yRootKey={transactionRootKey}
            yMetaRootKey={transactionMetaRootKey}
            prepareForSigning={prepareForSigningHandler}
            setCustomiseScreen={setCustomisationScreenHandler}
          />}
      </FormContextSigningOverride>
    </ContentTitler>;
  }

  return (
    <div className={clsJn('alot-container position-relative h-100 w-100 d-flex')}>
      <Allotment snap onVisibleChange={onVisibleChange}>
        <Allotment.Pane minSize={300} preferredSize={760} priority={LayoutPriority.High} visible={showContentPane}>
          <ContentTitler
            title={title}
            flex={true}
            scroll={true}
            afterTitle={afterTitle}
            breadcrumbs={breadcrumbs}
            afterBreadcrumbs={allotmentPaneMode !== AllotmentPaneMode.Both
              ? <Button
                variant='link'
                title='Preview'
                size='sm'
                className='px-0 py-0 border-0 bg-transparent link-secondary'
                onClick={()=> splitEnabled
                  ? setAllotmentPaneMode(AllotmentPaneMode.Both)
                  : setAllotmentPaneMode(AllotmentPaneMode.Preview)}
              >
                Preview
              </Button>
              : <></>}
          >
            {showSigningSession
              ? <SigningSession
                formCode={formCode}
                formId={formId}
                ydoc={ydoc}
                onVoid={cancelSigningHandler}
                wizardSectionProps={signingSessionWizardPropsForSidebar}
                dataRootKey={PropertyRootKey.Data}
                metaRootKey={PropertyRootKey.Meta}
                partyGroups={partyGroups}
                serveToPurchaserProps={serveToPurchaserProps}
                signingMainProps={signingMainProps}
                showPartyColours={!!unfilledFields.length}
              />
              : <Card className='card wiz-card bg-white mx-0 mx-sm-2 mx-md-4 shadow'>
                <Card.Header className={'bg-white accordion-card-header card-header'}>
                  <div className='title'>
                    <span className='fs-3'>Uploaded Document</span>
                  </div>
                </Card.Header>
                <Container className='p-3'>
                  <Row>
                    <Col>
                      <FloatingLabel label='Name' className='w-100'>
                        <Form.Control value={form?.upload?.name?.replace(/\.[^/.]+$/, '')}
                          onChange={handleNameChange}></Form.Control>
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className={'mt-2'}>
                    <Col md='auto'>Uploaded by {form?.upload?.uploader?.name} on {formatTimestamp(form?.created)}</Col>
                  </Row>
                  <Row>
                    <Col>{fileSize && formatBytes(fileSize)}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <WrField.Select
                        options={uploadTypeOpts}
                        name='upload.type'
                        parentPath={formPath}
                        myPath='upload.type'
                        label='Document Type'
                        canClear={true}
                        bindToMetaKey={true}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card>}
          </ContentTitler>
        </Allotment.Pane>
        <Allotment.Pane visible={showPreviewPane}>
          {<SetupPdfLoadStateContext>
            <PDFViewer
              pdfUrl={previewUrls}
              allowPrint={true}
              filename={form?.upload?.name}
              renderTextLayer={signingState === FormSigningState.None}
              bookmark={''}
              contextDependentLoadingMessage={pdfLoadErrorMessage}
              pageWrapElement={({ children, pageIndex, dimensions }) => <div key={`pw_${pageIndex}`} className='mt-3 position-relative' style={{ width: 'min-content', marginInline: 'auto' }}>
                {children}
              </div>}
              toolbarRight={<>
                {allotmentPaneMode !== AllotmentPaneMode.Content
                  ? <Button
                    variant='secondary'
                    onClick={() => {
                      setAllotmentPaneMode(AllotmentPaneMode.Content);
                    }}
                  >
                    <Icon name='close' />
                  </Button>
                  : undefined}
              </>}
            />
          </SetupPdfLoadStateContext>}
        </Allotment.Pane>
      </Allotment>
    </div>
  );
};

