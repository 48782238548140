import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ErrorHandlerPage } from '~/pages/ErrorHandlerPage';
import { PhpPage } from '~/pages/PhpPage';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import LoginPage from '~/LoginPage';
import { RemoteCompletionThankyouPage } from '~/pages/RemoteCompletionThankyouPage';
import { WindowRedirect } from '@property-folders/components/router/WindowRedirect';
import React from 'react';

export function UnauthenticatedRoutedApp(props: { routes: string[] }) {
  const router = createBrowserRouter([
    ...props.routes.filter(r => r.endsWith('.php')).map(r => ({
      path: r,
      errorElement: <ErrorHandlerPage />,
      element: <PhpPage url={LinkBuilder.reaformsFromRoot(`/${r}`)} needsReload={false} />,
      loader: (match: any) => {
        const params = new URLSearchParams(new URL(match.request.url).search);
        return {
          additionalQueryParams: Object.fromEntries(params)
        };
      }
    })),
    {
      path: 'login',
      element: <LoginPage />,
      errorElement: <ErrorHandlerPage />
    },
    {
      path: '/',
      index: true,
      element: <WindowRedirect to='/home'/>,
      errorElement: <ErrorHandlerPage />
    },
    {
      path: '/remote-completion/thank-you',
      element: <RemoteCompletionThankyouPage />
    }
  ]);

  return <RouterProvider router={router}/>;
}
