import { Button, Form, Modal } from 'react-bootstrap';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FormContext } from '@property-folders/components/context/FormContext';
import { FormCode, FormCodeUnion, FormSigningState, SignedStates, TransactionMetaData } from '@property-folders/contract';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { FormUtil } from '@property-folders/common/util/form';
import { PropertyFormYjsDal, reServeRecipients, restoreArchivedForm, unarchiveRecipients } from '@property-folders/common/yjs-schema/property/form';
import { applyMigrationsV2 } from '@property-folders/common/yjs-schema';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { useEntity } from '@property-folders/components/hooks/useEntity';
import { mapValues, keyBy } from 'lodash';

export function Form1UnarchiveModal({ onClose }: { onClose: () => void }) {
  const { ydoc: yDoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { formId, formName: formCode } = useContext(FormContext);
  const { bindState: metaBindState } = useImmerYjs<TransactionMetaData>(yDoc, transactionMetaRootKey);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const form = useMemo(() => FormUtil.getFormState(formCode, formId, meta), [meta]);
  const currentForm1 = PropertyFormYjsDal.getLatestFormFamilyInstance(FormCode.Form1, meta);
  const recipients = PropertyFormYjsDal.getFormFamilyServeRecipients(FormCode.Form1, meta);
  const localEntity = useEntity(meta?.entity?.id);
  const [reServe, setReserve] = useState<{[key:string]: boolean}>(localEntity?.signingOptions?.autoServeForm1 ? mapValues(keyBy(recipients, r=>r.id), ()=>true) : {});
  const isSigned = SignedStates.has(form?.signing?.state??FormSigningState.None);
  const [show, setShow] = useState(!!currentForm1 || isSigned);

  const title = currentForm1 ? 'Replace active Form 1' : 'Unarchive Form 1';

  useEffect(()=> {
    if (!currentForm1 && !isSigned) {
      handleReplace();
    }
  }, []);

  const handleCancel = () => {
    setShow(false);
    onClose();
  };

  const handleReplace = async () => {
    if (!yDoc) return;

    if (form?.subscription?.documentId) {
      await AjaxPhp.unarchiveDocument(form?.subscription?.documentId);
    }

    applyMigrationsV2<TransactionMetaData>({
      doc: yDoc,
      docKey: PropertyRootKey.Meta,
      typeName: 'Property',
      migrations: [{
        name: `unarchive property form ${formCode} ${formId}`,
        fn: draft => {
          restoreArchivedForm(draft, formCode as FormCodeUnion, formId);
          reServeRecipients(draft, formCode as FormCodeUnion, recipients?.filter(r => !!reServe?.[r.id])?.map(r => r.id)||[]);
          unarchiveRecipients(draft, formCode as FormCodeUnion, formId, recipients?.filter(r => !reServe?.[r.id])?.map(r => r.id)||[]);
        }
      }]
    });

    setShow(false);
    onClose();
  };

  return <Modal show={show} size='lg' onHide={handleCancel}>
    <Modal.Header closeButton>
      <div className='d-flex flex-column'>
        <Modal.Title>{title}</Modal.Title>
      </div>
    </Modal.Header>
    <Modal.Body>
      <div className={'px-3'}>
        The following actions will apply:
        <ul>
          {currentForm1 && <li>The active Form 1 will be archived</li>}
          <li>This Form 1 will become active</li>
        </ul>

        {isSigned && !!recipients?.length &&
          <div>
            Re-serve this document to the following recipients:
            {recipients.map(r => <Form.Check
              id={`re-serve-${r.id}`}
              key={`re-serve-${r.id}`}
              label={r.name}
              className={'ms-3 mb-1'}
              checked={reServe?.[r.id]}
              onChange={()=>setReserve(e=> ({ ...e, [r.id]: !e?.[r.id] }))}
            >
            </Form.Check>)}
          </div>
        }
      </div>

    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={handleCancel}>Cancel</Button>
      <AsyncButton onClick={handleReplace}>{`Unarchive${currentForm1 ? ' & Replace' : ''}`}</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
