import { Alert, Button, Image, Modal } from 'react-bootstrap';
import React, { useEffect, useMemo, useState } from 'react';
import Creatable from 'react-select/creatable';
import { reaformsOrange } from '@property-folders/common/visual';
import { useSelector } from 'react-redux';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { BelongingEntityMeta } from '@property-folders/common/redux-reducers/entityMeta';
import { Maybe } from '@property-folders/contract';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { EditorMode, RichTextEditorComponent } from '@property-folders/components/dragged-components/RichTextEditor';
import { ToolbarPlugin } from '@property-folders/components/dragged-components/lexical/ToolbarPlugin';
import { BaseAjaxResponse, LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import { WrappedFetch } from '@property-folders/common/client-api/wrappedFetch';
import { Lookups } from '@property-folders/common/client-api/lookups';
import { PostEmailFormBody } from '@property-folders/contract/rest/property';
import '@property-folders/components/display/EmailPreview.scss';

export type EmailFormIdType =
  | { type: 'sub', documentId: number, formId: number }
  | { type: 'new', propertyId: string, formId: string, formCode: string };

export function EmailFormModal({
  entityId,
  formName,
  name,
  propertyFormCode,
  propertyFormId,
  propertyId,
  setShow,
  show,
  subscriptionDocumentId,
  subscriptionFormId,
  draft
}: {
  entityId: number;
  formName: string;
  name: string;
  propertyFormCode?: string;
  propertyFormId?: string;
  propertyId?: string;
  setShow: (value: boolean) => void;
  show: boolean;
  subscriptionDocumentId?: number;
  subscriptionFormId?: number;
  draft?: boolean
}) {
  const id = useMemo<EmailFormIdType | undefined>(() => {
    // if it's a [currently being ] signed document, then prefer to use the property folder send path.
    // e.g. subscription form 1 that's created as part of a property folder.
    // it uses the property folder's signing regime and so needs to use that path for filling/sending.
    if (!draft && propertyId && propertyFormId && propertyFormCode) {
      return { type: 'new', propertyId, formCode: propertyFormCode, formId: propertyFormId };
    }

    if (subscriptionDocumentId && subscriptionFormId) {
      return { type: 'sub', documentId: subscriptionDocumentId, formId: subscriptionFormId };
    }

    if (propertyId && propertyFormId && propertyFormCode) {
      return { type: 'new', propertyId, formCode: propertyFormCode, formId: propertyFormId };
    }

    return undefined;
  }, [subscriptionDocumentId, propertyId, propertyFormCode, propertyFormId]);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const [initialOptions, setInitialOptions] = useState<EmailRecipient[]>([]);
  const [to, setTo] = useState<EmailRecipient[]>([]);
  const [cc, setCc] = useState<EmailRecipient[]>([]);
  const [bcc, setBcc] = useState<EmailRecipient[]>([]);
  const [message, setMessage] = useState('');
  const { brand } = useSelector<Maybe<{ entityMeta?: BelongingEntityMeta }>, Maybe<EntitySettingsEntity>>(state=> state?.entityMeta?.[(entityId??-1)?.toString()] as EntitySettingsEntity | undefined) ?? {};

  const [errorMessage, setErrorMessage] = useState('');
  const [sending, setSending] = useState(false);

  const senderName = sessionInfo?.name || '';
  const senderEmail = sessionInfo?.email || '';
  const entity = (sessionInfo?.entities || []).find(e => e.entityId === entityId);

  const entityLogoLoadedUri = '';
  const aAn = formName.match(/^[aeiou]/i) ? 'an' : 'a';

  useEffect(() => {
    const { ac, results } = Lookups.lookupContacts({
      propertyId: id?.type === 'new' ? id.propertyId : undefined,
      subscriptionDocumentId: id?.type === 'sub' ? id.documentId : undefined
    });

    results
      .then(data => {
        if (ac.signal.aborted) return;
        if (!data?.items?.length) return;
        setInitialOptions(data.items.map<EmailRecipient>(i => ({
          address: i.address,
          name: i.name
        })));
      })
      .catch(console.error);

    return () => {
      ac.abort();
    };
  }, [id]);

  const sendHandler = () => {
    if (!to.length) return;
    if (!id) return;

    setErrorMessage('');
    setSending(true);
    switch (id.type) {
      case 'sub':
        LegacyApi.ajax<BaseAjaxResponse>('emaildocument', {
          DocumentID: id.documentId.toString(),
          EmailBody: message,
          Email_To: to.map(x => x.address).join(';'),
          Email_CC: cc.map(x => x.address).join(';'),
          Email_BCC: bcc.map(x => x.address).join(';')
        })
          .then(result => {
            if (result.success) {
              setShow(false);
              return;
            }

            setErrorMessage('Failed to send email. Please try again.');
          })
          .catch(err => {
            console.error(err);
            setErrorMessage('Failed to send email. Please try again.');
          })
          .finally(() => setSending(false));
        return;
      case 'new': {
        const body: PostEmailFormBody = {
          to: to.map(x => x.address),
          cc: cc.length ? cc.map(x => x.address) : undefined,
          bcc: bcc.length ? bcc.map(x => x.address) : undefined,
          message
        };
        WrappedFetch.bare(`/api/rest/properties/${id.propertyId}/forms/${id.formCode}/${id.formId}/send-email`, {
          method: 'POST',
          body: JSON.stringify(body)
        })
          .then(response => {
            if (response.ok) {
              setShow(false);
              return;
            }

            setErrorMessage('Failed to send email. Please try again.');
          })
          .catch(err => {
            console.error(err);
            setErrorMessage('Failed to send email. Please try again.');
          })
          .finally(() => setSending(false));
        return;
      }
    }
  };

  return <Modal
    size='lg'
    show={show}
    onHide={() => setShow(false)}
    backdrop='static'
  >
    <Modal.Header closeButton>
      <Modal.Title>Email Form</Modal.Title>
    </Modal.Header>
    <Modal.Body className='d-flex flex-column gap-2'>
      {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
      <EmailRecipientSelector options={initialOptions} onChange={setTo} label='To' />
      <EmailRecipientSelector options={initialOptions} onChange={setCc} label='CC'/>
      <EmailRecipientSelector options={initialOptions} onChange={setBcc} label='BCC'/>
      <div className='d-flex flex-column'>
        <label><strong>Additional Message</strong></label>
        <RichTextEditorComponent
          namespace='email-form-dialog'
          outputMode={EditorMode.HTML}
          onUpdate={value => {
            setMessage(value);
          }}
          toolbar={subscriptionDocumentId ? () => <ToolbarPlugin templateConfig={{ documentId: subscriptionDocumentId, formId: subscriptionFormId }} /> : undefined}
        />
      </div>
      <div className='mb-4 email-preview-container d-flex justify-content-center'>
        <div className='centerline'>
          <div className='whitebox'>
            <div><Image className='email-preview-logo' src={entityLogoLoadedUri}/></div>
            <div className='brand-box' style={{
              backgroundColor: brand?.content?.backgroundColour || reaformsOrange,
              color: brand?.content?.foregroundColour || 'white'
            }}>
              <p className='mt-2'>{senderName} from {entity?.name || ''} has sent you {aAn} {formName} for {name}.</p>
              <div className='d-flex justify-content-center'>
                <div className='fake-button' style={{
                  backgroundColor: brand?.email?.button?.backgroundColour || 'white',
                  color: brand?.email?.button?.foregroundColour || reaformsOrange
                }}>VIEW
                </div>
              </div>
            </div>
            <div>
              {message && <div className='preview-user-message' dangerouslySetInnerHTML={{ __html: message }} />}

              <p>Should you have any questions in relation to the document, please contact:</p>
              <p>
                <b>{senderName}</b>
                <br/>
                <a href='#'>{senderEmail}</a>
              </p>

            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' disabled={sending} onClick={() => setShow(false)}>Cancel</Button>
      <SpinnerButton processing={sending} disabled={!id || to.length === 0} onClick={sendHandler}>Send Email</SpinnerButton>
    </Modal.Footer>
  </Modal>;
}

export interface EmailRecipient {
  address: string,
  name?: string
}

export function EmailRecipientSelector({
  options: defaultOptions,
  onChange,
  label
}: {
  options: EmailRecipient[],
  onChange: (selected: EmailRecipient[]) => void,
  label: string
}) {
  const [selected, setSelected] = useState<EmailRecipient[]>([]);
  const [createdOptions, setCreatedOptions] = useState<EmailRecipient[]>([]);
  const options = useMemo(() => {
    return defaultOptions.concat(createdOptions);
  }, [defaultOptions, createdOptions]);
  return <div>
    <label><strong>{label}</strong></label>
    <Creatable<EmailRecipient, true>
      styles={{
        input: (css) => ({
          ...css,
          // The default style sheet has inline-grid here, which is giving us a 2px box only.
          // Changing this to flex doesn't seem to alter the functionality as far as I can see.
          display: 'flex'
        })
      }}
      isMulti
      isSearchable
      options={options}
      value={selected}
      onChange={(newSelection, meta) => {
        const distinct = [...new Map(
          newSelection.map(item => [item.address, item])
        ).values()];
        if (meta.action === 'create-option') {
          setCreatedOptions(cur => {
            return [
              ...cur,
              meta.option
            ];
          });
        }
        setSelected(distinct);
        onChange(distinct);
      }}
      getNewOptionData={(address) => {
        return {
          address
        };
      }}
      formatCreateLabel={value => {
        return value;
      }}
      formatOptionLabel={(item, meta) => {
        if (meta.context === 'value') return formatEmailAddress(item.address, item.name);
        return <span>{formatEmailAddress(item.address, item.name)}</span>;
      }}
      getOptionLabel={x => {
        return x.address;
      }}
      getOptionValue={x => {
        return x.address;
      }}
      isValidNewOption={(inputValue, value, options, accessors) => {
        return !!inputValue.length && inputValue.indexOf('@') >= 0;
      }}
    /></div>;
}

function formatEmailAddress(address: string, name?: string) {
  return name
    ? <span>{name} &lt;<b>{address}</b>&gt;</span>
    : <b>{address}</b>;
}
