import React, { useContext, useMemo, useState } from 'react';
import { Wizard } from '@property-folders/components/dragged-components/Wizard/Wizard';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { FormContextSetup,
  getDefaultReportMissing
} from '@property-folders/components/form-gen-util/yjsStore';
import { fieldGroups as contractFieldGroups, saleContractFormRules } from '@property-folders/contract/yjs-schema/model/form/sale-contract-residential';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { FormCode, MaterialisedPropertyData, ProspectivePurchaserGroup, TransactionMetaData } from '@property-folders/contract';
import './FormInput.scss';

import { useForm } from '@property-folders/components/hooks/useForm';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { propertyFolder, propertyMeta } from '@property-folders/contract/yjs-schema/model/field';
import { ResidentialPurchaseOfferPdfDefinitionProvider } from '@property-folders/common/util/pdfgen/definitions/helpers/residential-purchase-offer-pdf';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { PropertyBackgroundJobsContext } from '@property-folders/components/context/PropertyBackgroundJobs';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { IPdfDefinitionProvider } from '@property-folders/common/types/PDFDefinition';
import { useMatches, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { UpdatesPendingBadge } from '@property-folders/components/display/properties/UpdatesPendingBadge';
import { OfferActionModal, OfferActionModalType } from '~/pages/OfferManagementPage';
import { PurchaserApi } from '@property-folders/common/client-api/purchaserApi';
import { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { PurchaserSubmittedDocumentStatus } from '@property-folders/contract/yjs-schema/purchaser-portal';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '@property-folders/components/display/errors/modals';
import { handleCreateContractFromOfferFactory } from '@property-folders/components/form-gen-util/create-contract';

const FORM_CODE = FormCode.OfferToPurchase;
const FORM_LABEL = FormTypes[FORM_CODE].label;
const FORM_RULES = {};
const TRANSACTION_RULES = propertyFolder;
const FORM_CONTEXT: Omit<FormContextType, 'formId'> = {
  formName: FORM_CODE,
  printTitle: FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  wizardTitle: FormTypes[FORM_CODE].wizardTitle ?? FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  formRules: FORM_RULES,
  transactionRules: TRANSACTION_RULES,
  metaRules: propertyMeta,
  fieldGroups: contractFieldGroups,
  reportMissing: getDefaultReportMissing(FORM_CODE, saleContractFormRules)
};

export const LetterOfOfferReview = (props: TransactionFormProps) => {
  // this code is similar to what's on the RSAA, so...
  // future: maybe lift signing stuff out into separate pages within TransactionHomePage
  // and also create a usePdfDefinitionProvider hook to automatically generate the right thing based on YjsDocContext and FormContext
  // and maybe a useInitiateSigningCallback hook to build the right kind of signing initiation flow.
  // anyway, no time to do that now!
  const formCode = FORM_CODE;
  const formId = props.formId;
  const { ydoc, docName: propertyId, transactionMetaRootKey, transactionRootKey } = useContext(YjsDocContext);
  const { bindState: metaBindState, binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { binder: dataBinder } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const updatedBreadcrumb = useMemo(()=>[...(props.breadcrumbs ?? []), { label: FORM_LABEL }], [props.breadcrumbs]);
  const pdfDefinition = useMemo<IPdfDefinitionProvider>(() => {
    return new ResidentialPurchaseOfferPdfDefinitionProvider(dataBinder,
      metaBinder,
      formCode,
      formId,
      true);
  }, [dataBinder, metaBinder, formCode, formId, props.variationsMode]);

  return <FormContextSetup formId={formId} base={FORM_CONTEXT}>
    <SetupPdfLoadStateContext>
      <LetterOfOfferReviewInner
        {...props}
        breadcrumbs={updatedBreadcrumb}
        pdfDefinition={pdfDefinition}/>
    </SetupPdfLoadStateContext>
  </FormContextSetup>;
};

export const LetterOfOfferReviewInner = ({ formId, parentPath = '', myPath = '', breadcrumbs, entityLogoLoadedUri, pdfDefinition }: TransactionFormProps & {pdfDefinition: IPdfDefinitionProvider}): JSX.Element => {
  const { ydoc, docName, transactionRootKey, transactionMetaRootKey, formName: formCode, formFamilyCode, wizardTitle } = useForm();
  const navigate = useNavigate();
  const { bindState, binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { data: meta } = bindState<TransactionMetaData>(state => state);

  const currentOfferId = transactionRootKey;
  const { bindState: bindStateRoot } = useImmerYjs<TransactionMetaData>(ydoc, PropertyRootKey.Meta);
  const { data: prospectivePurchasers } = bindStateRoot<ProspectivePurchaserGroup[]>(meta=>meta?.offerManagement?.prospectivePurchasers);
  const allOffers = prospectivePurchasers?.map(pp=>pp.submittedOffers).flat();
  const thisOffer = allOffers?.find(offer=>offer.id === currentOfferId);

  const { instance: fileSync } = useContext(FileSyncContext);
  const backgroundJobsContext = useContext(PropertyBackgroundJobsContext);

  const [offerActionModal, setOfferActionModal] = useState<{ visible: boolean, type?: OfferActionModalType, offerId?: string }>({ visible: false });

  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ parentPath, myPath });
  const headline = generateHeadlineFromMaterialisedData(transRoot);

  const handleOfferAction = (email: { subject: string, message: string } | undefined) => {
    setOfferActionModal(p =>({ ...p, visible: false }));
    if (!offerActionModal.offerId) return;

    switch (offerActionModal.type) {
      case OfferActionModalType.decline:
        PurchaserApi.declineOffer(docName, offerActionModal.offerId, email);
        break;

      case OfferActionModalType.withdraw:
        PurchaserApi.withdrawOffer(docName, offerActionModal.offerId, email);
        break;
    }
  };

  const handleCreateContractFromOffer = () => handleCreateContractFromOfferFactory({ fileSync, headline, navigate, propertyId: docName, ydoc: ydoc })(transRoot);

  const declined = thisOffer?.status === PurchaserSubmittedDocumentStatus.Declined;
  const withdrawn = thisOffer?.status === PurchaserSubmittedDocumentStatus.Withdrawn;
  const withdrawnBy = thisOffer?.statusChangedBy;
  const stillActive = !(declined || withdrawn) ;

  const currentPathing = useMatches();
  const OfferManagementHref = currentPathing[currentPathing.length-2].pathname + '/offer-management';
  const updatedBread: CrumbDefn[] = [...breadcrumbs.slice(0,-1), { label: 'Offer Management', href: OfferManagementHref }, breadcrumbs[breadcrumbs.length-1]];
  return (
    <>
      <Wizard
        formId={formId}
        formName={formCode}
        transactionRootKey={transactionRootKey}
        transactionMetaRootKey={transactionMetaRootKey}
        docName={docName}
        title={wizardTitle}
        afterTitle={<div className={'d-flex'}>
          <UpdatesPendingBadge propertyId={backgroundJobsContext?.propertyId} headline={headline} show={backgroundJobsContext.envelopeChangesAvailable} className='ms-auto me-2 mt-1' />
          {stillActive && <>
            <Button variant={'outline-secondary'} className={'me-2'} onClick={() => setOfferActionModal({ visible: true, type: OfferActionModalType.withdraw, offerId: transactionRootKey })}>Withdraw</Button>
            <Button variant={'outline-secondary'} onClick={() => setOfferActionModal({ visible: true, type: OfferActionModalType.decline, offerId: transactionRootKey })}>Decline</Button>
          </>}

        </div>}
        printHeadline={headline}
        breadcrumbs={updatedBread}
        pdfDefinition={pdfDefinition}
        ydoc={ydoc}
        signing={true}
        entityLogoLoadedUri={entityLogoLoadedUri}
        signingSessionOtherButton={stillActive ? <Button variant={'outline-secondary'} onClick={handleCreateContractFromOffer}>Create Contract</Button> : undefined}
        signingSessionTitleOverride={declined ? 'Offer Declined' : withdrawn ? `Offer Withdrawn (${withdrawnBy === 'purchaser' ? 'by Purchaser' : 'on behalf of Purchaser'})` : undefined}
      >

      </Wizard>
      <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={!!offerActionModal.visible} onClose={() => setOfferActionModal(p =>({ ...p, visible: false }))}/>}>
        <OfferActionModal
          visible={offerActionModal.visible}
          onClose={() => setOfferActionModal(p =>({ ...p, visible: false }))}
          onSubmit={handleOfferAction}
          actionType={offerActionModal.type}
          headline={headline}
          propertyMeta={meta}
        />
      </ErrorBoundary>
    </>
  );
};
