import { EntityLargeScreenPreviewClickHandler, EntitySmallScreenPreviewButton } from '~/pages/settings/PreviewHandlers';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { reaformsOrange } from '@property-folders/common/visual';

import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { EntityBrandPortalConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { Button, Card, Col, Row } from 'react-bootstrap';
import React from 'react';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { DocumentDescriptions } from '@property-folders/common/data-and-text/descriptions';

export enum PortalSettingsPreviewKey {
  Portal = 'portal'
}

export function PortalSettingsEdit({ onPreviewKey }: { onPreviewKey: (key: string) => void }) {
  return <div>
    <EntityLargeScreenPreviewClickHandler onPreviewKey={onPreviewKey} previewKey={PortalSettingsPreviewKey.Portal}>
      <WizardStepPage
        name='portal'
        label='Portal'
        icon='email'
        headerContent={<EntitySmallScreenPreviewButton onPreviewKey={onPreviewKey} previewKey={PortalSettingsPreviewKey.Portal}/>}
      >
        <Row>
          <Col size={12}>
            <h4>Highlights</h4>
          </Col>
        </Row>
        <Row>
          <Col size={6}>
            <WrField.ColourPicker name='' parentPath='brand.portal' myPath='highlightColour' defaultValue={reaformsOrange} />
          </Col>
          <Col size={6}></Col>
        </Row>
      </WizardStepPage>
    </EntityLargeScreenPreviewClickHandler>
  </div>;
}

export function PortalSettingsPreview({ previewKey }: {previewKey: string}) {
  switch (previewKey) {
    case PortalSettingsPreviewKey.Portal:
    default:
      return <PurchaserPortalPreview />;
  }
}

function PurchaserPortalPreview() {
  const { value: brand } = useLightweightTransaction<EntityBrandPortalConfig>({ parentPath: 'brand.portal' });
  return <div className='p-3 d-flex justify-content-center align-items-center'>
    <Card className='border-0' style={{ maxWidth: '500px' }}>
      <Card.Header style={{ borderTop: `4px solid ${brand?.highlightColour || reaformsOrange}` }}>
        <Card.Title><h4>Welcome to the Purchaser Portal</h4></Card.Title>
      </Card.Header>
      <Card.Body>
        <table className='table w-100'>
          <thead>
            <tr>
              <th>Name</th>
              <th style={{ width: '16px', maxWidth: '16px' }}></th>
            </tr>
          </thead>
          <tbody>
            {Object.values(DocumentDescriptions).map((entry) => <tr className='cursor-pointer'>
              <td className='border-0'>
                <b>{entry.title}</b>
                <div><small className='text-secondary'>{entry.summary}</small></div>
              </td>
              <td className='border-0'>
                <Icon name='more_vert' />
              </td>
            </tr>)}
          </tbody>
        </table>
      </Card.Body>
      <Card.Footer className='d-flex flex-row justify-content-end'>
        <div className='d-flex flex-column align-items-end'>
          <div>
            <Button>Register</Button>
          </div>
          <span>Already registered? <a href='#'>Login</a></span>
        </div>
      </Card.Footer>
    </Card>
  </div>;
}
