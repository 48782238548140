import { useContext, useEffect, useMemo } from 'react';
import { ResidentialSalesAgreementForm } from './forms/ResidentialSalesAgreement/ResidentialSalesAgreementForm';
import { v4 } from 'uuid';
import { SetupNetStateWritingYjsDocContext } from '@property-folders/components/form-gen-util/yjsStore';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { RoomProvider } from '@y-presence/react';
import { AgentSessionInfoResult, AwarenessData, ExtraFormCode, FormCode } from '@property-folders/contract';
import { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import { useNavigate, useParams } from 'react-router-dom';
import { TransactionEditor } from './TransactionEditor';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { TransactionNewForm } from './TransactionNewForm';
import { find } from 'lodash';
import { ShortId } from '@property-folders/common/util/url';
import { NotImplementedPlaceholder } from '~/pages/forms/NotImplementedPlaceholder/NotImplementedPlaceholder';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract/property';
import { FormUtil } from '@property-folders/common/util/form';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { PropertyStatsMain, PropertyStatsRootKey } from '@property-folders/contract/yjs-schema/property-stats';
import { SetupDebouncedAwarenessContext } from '@property-folders/components/context/DebouncedAwarenessContext';
import { SalesContractForm } from './forms/ResidentialSalesContract/ResidentialSalesContractForm';
import { Predicate } from '@property-folders/common/predicate';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { useMediaQuery } from 'react-responsive';
import { AgencyAgreementVariation } from './forms/SalesAgreementVariation/SalesAgreementVariation';
import { SalesContractVariation } from './forms/SalesContractVariation/SalesContractVariation';
import { SetupNetStateContext } from '@property-folders/components/dragged-components/NetStateContext';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { PropertyRootKey, META_APPEND } from '@property-folders/contract/yjs-schema/property';
import { useEntityLogoForCurrentPropertyFolder } from '@property-folders/components/hooks/useEntityLogo';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { FullPageLoading } from '@property-folders/components/dragged-components/FullPageLoading';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { EnvelopeApiHooks } from '@property-folders/components/hooks/api/EnvelopeApiHooks';
import { TransactionFormCommonProps } from '@property-folders/common/types/TransactionFormProps';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { SalesContractTerminationForm } from './forms/SalesContractTermination/SalesContractTerminationForm';
import { RouterData } from '~/App';
import { ContractExternalTermination } from './forms/ExternalTerminations/ContractExternalTermination';
import { TemplateContractForm } from './forms/TemplateContract/TemplateContractForm';
import { VendorToSign } from './VendorToSign';
import { LetterOfOfferReview } from './forms/LetterOfOfferReview';
import { LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { SubscriptionFormCode } from '@property-folders/common/subscription-forms';
import { SubsequentAgencyForm } from './forms/SubsequentAgency/SubsequentAgencyForm';
import * as Y from 'yjs';
import { handleNewFormOrderInternal } from '@property-folders/common/util/handleNewFormOrder';
import { UploadedDocumentPage } from '~/pages/forms/UploadedDocumentPage';
import { UploadedForm1Page } from '~/pages/forms/UploadedForm1Page';
import { YFormUtil } from '@property-folders/common/util/yform';
import { bind } from 'immer-yjs';
import { YDocEditorPage } from '~/pages/YDocEditorPage';
import { ExploreYdoc } from '~/pages/ExploreYdoc';

type SpecialPageFlag = {
  special?: 'templateContract' | 'vendorToSignContract' | 'explore'
};

type HomePageProps = {
  onUpdated?: ()=>void
  onDebounce?: (debouncing: boolean)=>void
  role?: string,

} & SpecialPageFlag;

export const initialPresence: AwarenessData = {
  location: {
    path: ''
  }
};

export async function handleNewFormOrder(ydoc: Y.Doc | undefined, formCode: string) {
  // for now, there is only one form that's orderable, and only one party that can fill the order (EPF).
  // so just grab those details and get on with it.
  const entityId = (await LegacyApi.getFulfillersForDocument(formCode as SubscriptionFormCode)).at(0)?.entityId;
  if (!entityId) return undefined;

  const { id } = await handleNewFormOrderInternal(ydoc, formCode, entityId)||{};
  return id;
}

export function TransactionHomePage(props: HomePageProps) {
  const { onDebounce, role, ...restProps } = props;
  const { transId, ydoc, awareness, ydocStats } = useReactRouterData<RouterData>();
  const { documentIdRaw } = useParams();
  const templateContractMode = props.special === 'templateContract';

  const {
    bindState
  } = useImmerYjs<PropertyStatsMain>(ydocStats, PropertyStatsRootKey.Main);
  const {
    update: updatePropertyStats
  } = bindState<PropertyStatsMain>(s => s);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  const {
    bindState: masterMetaBindState,
    binder: masterMetaBinder
  } = useImmerYjs<TransactionMetaData>(ydoc, PropertyRootKey.Meta); // We're still discovering the currently active root key, so we refer to the root's meta
  const { data: alternateRootKeys } = masterMetaBindState<string[]>((m: TransactionMetaData)=>m.sublineageRoots??[]);
  const { data: formIdListJson } = masterMetaBindState<string>((m: TransactionMetaData)=>(JSON.stringify(listAvailableFormIds(m)??[])));

  const { data: rscFormId } = masterMetaBindState<string|undefined>((m: TransactionMetaData)=>(
    m.formStates?.[FormCode.RSC_ContractOfSale]?.instances?.[0]?.id
  ));

  const formId = templateContractMode ?
    rscFormId
    : documentIdRaw
      ? ShortId.toUuid(documentIdRaw)
      : documentIdRaw;

  const lineageRootKey = useMemo(()=>{
    const result: {data: string, meta: string} ={
      data: PropertyRootKey.Data, // Default values
      meta: PropertyRootKey.Meta
    };
    if (!formId || !(alternateRootKeys && alternateRootKeys.length>0) ) {
      return result;
    }

    const formList = JSON.parse(formIdListJson??'[]');

    if (!formList.includes(formId)) {
      for (const altRootKey of alternateRootKeys) {
        const currentMetaKey = altRootKey+META_APPEND;
        const currentMeta = ydoc.getMap(currentMetaKey).toJSON() as TransactionMetaData;
        const forms = listAvailableFormIds(currentMeta)??[];
        if (forms.includes(formId)) {
          return {
            data: altRootKey,
            meta: currentMetaKey
          };
        }
      }
    }
    return result;
  }, [formId, formIdListJson]);

  useEffect(() => {
    if (!(ydocStats && updatePropertyStats && sessionInfo)) {
      return;
    }

    updatePropertyStats(s => {
      if (!s.id) {
        s.id = transId;
      }
      const now = Date.now();
      const idxMatch = s.userAccessTimes.findIndex(i => i.id && i.id === sessionInfo.agentId);
      if (idxMatch < 0) {
        s.userAccessTimes.push({
          id: sessionInfo.agentId,
          ts: now
        });
      } else {
        s.userAccessTimes[idxMatch].ts = now;
      }
    });
  }, [!!ydocStats, !!updatePropertyStats, !!sessionInfo]);

  useEffect(() => {
    if (!window.fnDev.Y) return; // fnDev mode disabled
    if (!(ydoc && transId)) {
      delete window.fnDev.currentYFormContext;
      return;
    }
    const { formFamilyCode } = YFormUtil.getFormLocationFromId(formId, ydoc)??{};
    const genCurrentFormBinder = (mode: 'data' | 'meta' | 'rdata' | 'rmeta') => {
      let rootKey: string | null = null;
      switch (mode) {
        case 'data': {
          rootKey = lineageRootKey.data;
          break;
        }
        case 'meta': {
          rootKey = lineageRootKey.meta;
          break;
        }
        case 'rdata': {
          rootKey = PropertyRootKey.Data;
          break;
        }
        case 'rmeta': {
          rootKey = PropertyRootKey.Meta;
          break;
        }
        default:
          break;
      }
      if (rootKey == null) {
        console.log('param must be one of data, meta, rdata or rmeta');
        return;
      }
      return bind(ydoc.getMap(rootKey));
    };
    window.fnDev.currentYFormContext = {
      ydoc, transId, lineageRootKey, formId, formFamilyCode, genCurrentFormBinder
    };
  }, [!!ydoc, transId]);

  return ydoc && awareness
    ? <SetupNetStateContext ydoc={ydoc} transactionRootKey={lineageRootKey.data}>
      <SetupNetStateWritingYjsDocContext
        ydoc={ydoc}
        awareness={awareness}
        docName={transId}
        transactionRootKey={lineageRootKey.data}
        transactionMetaRootKey={lineageRootKey.meta}
      >
        <RoomProvider<AwarenessData> awareness={awareness} initialPresence={initialPresence}>
          <SetupDebouncedAwarenessContext delay={0}>
            <TransactionGeneral role={role} special={props.special} formId={formId} sessionInfo={sessionInfo} />
          </SetupDebouncedAwarenessContext>
        </RoomProvider>
      </SetupNetStateWritingYjsDocContext>
    </SetupNetStateContext>
    : <div>loading property...</div>;
}

const listAvailableFormInstances = (meta: TransactionMetaData | undefined) => {
  if (!meta?.formStates) return [];
  if (typeof meta.formStates !== 'object') return [];
  return Object.values(meta.formStates)
    .flatMap(formState=> formState.instances || [])
    .filter(Predicate.isTruthy);
};

const listAvailableFormIds = (meta: TransactionMetaData | undefined) => {
  return listAvailableFormInstances(meta).map(fi => fi.id);
};

const TransactionGeneral = ({
  breadcrumbs,
  role,
  special,
  formId: formIdParam,
  sessionInfo
}: {
  breadcrumbs?: CrumbDefn[],
  role?:string,
  formId?: string;
  sessionInfo?: AgentSessionInfoResult
} & SpecialPageFlag
) => {
  const templateContractMode = special === 'templateContract';
  const VendorToSignMode = special === 'vendorToSignContract';
  const explorerMode = special === 'explore';

  const navigate = useNavigate();
  const { propertyIdRaw, documentIdRaw } = useParams();
  const { instance: fileSync } = useContext(FileSyncContext);
  const transId = ShortId.toUuid(propertyIdRaw);
  const formId = formIdParam ?? (
    documentIdRaw
      ? ShortId.toUuid(documentIdRaw)
      : documentIdRaw
  );

  const { ydoc, transactionRootKey, transactionMetaRootKey: metaKey } = useContext(YjsDocContext);
  const { bindState: bindMetaState } = useImmerYjs<TransactionMetaData>(ydoc, metaKey);
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ 'myPath': '' });
  const narrowMode = useMediaQuery({ maxWidth: BP_MINIMA.sm });
  const headlineVal = generateHeadlineFromMaterialisedData(transRoot, narrowMode);
  const { data: transMeta } = bindMetaState<TransactionMetaData>(meta => meta || {});
  const { formStates, entity } = transMeta || {};
  const allDocs = useMemo(()=>listAvailableFormInstances(transMeta), [transMeta]);
  const node = find(allDocs || [], d=>d?.id=== formId);
  const entityLogoUri = useEntityLogoForCurrentPropertyFolder();
  const hasUpdatesPending = EnvelopeApiHooks.useExternalUpdateWatcher(transId);

  useEffect(()=>{

    if (formStates==null || !formId || node) { // formStates==null Means the ydoc hasn't loaded yet if a form is expected
      return;
    }
    const to = `/properties/${transId}`;
    console.log('form id but no node navigate to', to);
    navigate(to, { replace: true });

  },[node, formId, !!formStates, metaKey]);

  useEffect(()=>{
    if (formStates==null || !ydoc) { // Means the ydoc hasn't loaded yet, hasn't synchronised, or there are no forms in which case we don't care
      return;
    }
    applyMigrationsV2_1({
      typeName: 'Property',
      doc: ydoc,
      docKey: metaKey,
      migrations: [{
        name: 'Checking all forms have a clause ID',
        fn: ((metaDraftRoot: TransactionMetaData) => {
          // This is a repair function in case it isn't allocated at the time the form was
          // created
          let modified = false;
          for (const familyId in metaDraftRoot.formStates) {
            const familyState = metaDraftRoot.formStates[familyId];
            if (!familyState.clauseChildId) {
              metaDraftRoot.formStates[familyId].clauseChildId = v4();
              modified = true;
            }
          }
          if (!modified) {
            return false;
          }
        })
      }]
    });
  }, [!!formStates, metaKey, ydoc]);

  const availableNewForms = useMemo(()=>{
    return Object.keys(FormTypes).filter((fCode) => FormUtil.canAddForm(formStates || {}, fCode));
  }, [formStates]);
  const rootLabel = 'Properties';
  const updatedBreadcrumb = useMemo(()=>[
    ...(breadcrumbs ?? [{ label: rootLabel, href: '/properties/' }]),
    { label: headlineVal || 'Property Overview', href: `/properties/${LinkBuilder.seoFriendlySlug(transId, headlineVal)}` }
  ], [breadcrumbs, headlineVal, rootLabel]);
  const commonProps: TransactionFormCommonProps = {
    breadcrumbs: updatedBreadcrumb,
    formId: formId || '',
    entityLogoLoadedUri: entityLogoUri,
    propertyUpdatesPending: hasUpdatesPending
  };

  if ((window as any).rawDogMode && (sessionInfo?.isGlobalAdmin || sessionInfo?.impersonator?.agentId)) {
    return <YDocEditorPage />;
  }

  if (explorerMode) return <ExploreYdoc {...commonProps} />;
  if (templateContractMode) return <TemplateContractForm {...commonProps} />;
  if (VendorToSignMode) return <VendorToSign {...commonProps} />;
  if (formStates==null && formId) return <FullPageLoading />;

  if (role === 'form' && !formId) {
    return <TransactionNewForm
      availForms={availableNewForms}
      onAddForm={(formCode)=>{
        handleNewForm(ydoc, formCode, fileSync)
          .then(formId => {
            navigate(formCode);
          })
          .catch(console.error);
      }} {...commonProps}
    />;
  }

  const formCode = node?.formCode || '';
  switch (formCode) {
    case FormCode.RSAA_SalesAgencyAgreement: return <ResidentialSalesAgreementForm {...commonProps} />;
    case FormCode.RSC_ContractOfSale: return <SalesContractForm {...commonProps} />;
    case ExtraFormCode.AAV_SalesAgencyAgreementVariation: return <AgencyAgreementVariation {...commonProps} />;
    case ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent: return <SubsequentAgencyForm {...commonProps} />;
    case ExtraFormCode.SCV_ContractOfSaleVariation: return <SalesContractVariation {...commonProps} />;
    case ExtraFormCode.SCT_ContractOfSaleTermination: return <SalesContractTerminationForm {...commonProps} />;
    case ExtraFormCode.SCTE_ContractOfSaleTerminationExternal: return <ContractExternalTermination {...commonProps} />;
    case FormCode.OfferToPurchase: return <LetterOfOfferReview {...commonProps} />;
    case FormCode.UploadedDocument: return <UploadedDocumentPage {...commonProps} />;
    case ExtraFormCode.Form1Upload: return <UploadedForm1Page {...commonProps} />;

    case '': return <TransactionEditor {...commonProps}/>;
    default: return <NotImplementedPlaceholder formCode={formCode} {...commonProps}/>;
  }

};
