import { FormContextSetup, getDefaultReportMissing } from '@property-folders/components/form-gen-util/yjsStore';
import { saleContractFormRules } from '@property-folders/contract/yjs-schema/model/form/sale-contract-residential';
import { saleContractVariationOverlay, scvFieldGroups } from '@property-folders/contract/yjs-schema/model/form/sale-contract-variation-overlay';
import { propertyFolder, propertyMeta } from '@property-folders/contract/yjs-schema/model/field/';
import '../FormInput.scss';

import { VariationWrapper } from '@property-folders/components/dragged-components/VariationWrapper';
import { SalesContractForm } from '../ResidentialSalesContract/ResidentialSalesContractForm';
import { ExtraFormCode } from '@property-folders/contract';

import { merge } from 'lodash';

import { FormContextType } from '@property-folders/common/types/FormContextType';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';

const FORM_CODE = ExtraFormCode.SCV_ContractOfSaleVariation;
const FORM_RULES = merge(structuredClone(saleContractFormRules),structuredClone(saleContractVariationOverlay));
const TRANSACTION_RULES = propertyFolder;
const META_RULES = propertyMeta;
const FILE_CONTEXT_VALUE: Omit<FormContextType, 'formId'> = {
  formName: FORM_CODE,
  printTitle: FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  wizardTitle: FormTypes[FORM_CODE].wizardTitle ?? FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  formRules: FORM_RULES,
  transactionRules: TRANSACTION_RULES,
  metaRules: META_RULES,
  fieldGroups: scvFieldGroups,
  reportMissing: getDefaultReportMissing(FORM_CODE, FORM_RULES)
};

export const SalesContractVariation = (props: TransactionFormProps) => {
  return <FormContextSetup formId={props.formId} base={FILE_CONTEXT_VALUE}>
    <VariationWrapper {...props} InnerForm={SalesContractForm} />
  </FormContextSetup>;
};

