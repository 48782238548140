import React, { useContext, useMemo } from 'react';
import { Wizard } from '@property-folders/components/dragged-components/Wizard/Wizard';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { FormContextSetup,
  getDefaultReportMissing
} from '@property-folders/components/form-gen-util/yjsStore';

import { fieldGroups as contractFieldGroups, saleContractTerminationFormRules } from '@property-folders/contract/yjs-schema/model/form/sale-contract-termination';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { ContractTermination, ContractTerminationDepositReturn, ExtraFormCode, FormInstance, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { useUpdateInstanceModifiedOnLoad } from '@property-folders/components/hooks/useUpdateInstanceModifiedOnLoad';
import '../FormInput.scss';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { useForm } from '@property-folders/components/hooks/useForm';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { VendorWizardStepPage } from '@property-folders/components/dragged-components/Wizard/VendorWizardStepPage';
import { PurchaserWizardStepPage } from '@property-folders/components/dragged-components/Wizard/PurchaserWizardStepPage';
import { PropertyWizardStepPage } from '@property-folders/components/dragged-components/Wizard/PropertyWizardStepPage';
import { AnnexuresSection } from '@property-folders/components/dragged-components/form/AnnexuresSection';
import { propertyFolder, propertyMeta } from '@property-folders/contract/yjs-schema/model/field';
import { requirePropertyDetailsMessage } from '@property-folders/common/util/formatting/constants';
import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { SigningConfigurationInterceptor } from '@property-folders/components/dragged-components/signing/SigningConfiguration';

import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { UpdatesPendingBadge } from '@property-folders/components/display/properties/UpdatesPendingBadge';
import { PropertyBackgroundJobsContext } from '@property-folders/components/context/PropertyBackgroundJobs';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { IPdfDefinitionProvider } from '@property-folders/common/types/PDFDefinition';
import { SaleContractTerminationPdfDefinitionProvider } from '@property-folders/common/util/pdfgen/definitions/helpers/sales-contract-termination';
import { LineageWrapper } from '@property-folders/components/dragged-components/VariationWrapper';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { contractTerminationDepositOpts } from '@property-folders/common/data-and-text/constants';
import { EditorMode } from '@property-folders/components/dragged-components/RichTextEditor';
import { LexicalRichTextEditor } from '@property-folders/components/dragged-components/LexicalRichTextEditor';
import { useMatches } from 'react-router-dom';

const FORM_CODE = ExtraFormCode.SCT_ContractOfSaleTermination;
const FORM_LABEL = FormTypes[FORM_CODE].label;
const FORM_RULES = saleContractTerminationFormRules;
const TRANSACTION_RULES = propertyFolder;
const FORM_CONTEXT: Omit<FormContextType, 'formId'> = {
  formName: FORM_CODE,
  printTitle: FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  wizardTitle: FormTypes[FORM_CODE].wizardTitle ?? FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  formRules: FORM_RULES,
  transactionRules: TRANSACTION_RULES,
  metaRules: propertyMeta,
  fieldGroups: contractFieldGroups,
  reportMissing: getDefaultReportMissing(FORM_CODE, saleContractTerminationFormRules)
};

export const SalesContractTerminationForm = (props: TransactionFormProps) => {
  // this code is similar to what's on the RSAA, so...
  // future: maybe lift signing stuff out into separate pages within TransactionHomePage
  // and also create a usePdfDefinitionProvider hook to automatically generate the right thing based on YjsDocContext and FormContext
  // and maybe a useInitiateSigningCallback hook to build the right kind of signing initiation flow.
  // anyway, no time to do that now!
  const formCode = FORM_CODE;
  const formId = props.formId;
  const { ydoc, docName: propertyId, transactionMetaRootKey, transactionRootKey } = useContext(YjsDocContext);
  const { bindState: metaBindState, binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { binder: dataBinder } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const updatedBreadcrumb = useMemo(()=>[...(props.breadcrumbs ?? []), { label: FORM_LABEL }], [props.breadcrumbs]);
  const pdfDefinition = useMemo<IPdfDefinitionProvider>(() => {
    return props.variationsMode
      ? undefined
      : new SaleContractTerminationPdfDefinitionProvider(dataBinder,
        metaBinder,
        formCode,
        formId,
        true);
  }, [dataBinder, metaBinder, formCode, formId, props.variationsMode]);

  return <FormContextSetup formId={formId} base={FORM_CONTEXT}>
    <LineageWrapper requireFullHistory={true}>
      <SigningConfigurationInterceptor
        {...props}
        breadcrumbs={updatedBreadcrumb}
        pdfDefinition={pdfDefinition}
        documentRenderer={() => (<SetupPdfLoadStateContext>
          <SalesContractTerminationFormInner
            {...props}
            breadcrumbs={updatedBreadcrumb}
            pdfDefinition={pdfDefinition}/>
        </SetupPdfLoadStateContext>)}
      />
    </LineageWrapper>
  </FormContextSetup>;
};

export const SalesContractTerminationFormInner = ({
  formId,
  parentPath = '',
  myPath = '',
  breadcrumbs,
  entityLogoLoadedUri,
  pdfDefinition
}: TransactionFormProps & {pdfDefinition: IPdfDefinitionProvider}): JSX.Element => {
  const { ydoc, docName, transactionRootKey, transactionMetaRootKey, formName: formCode, formFamilyCode, wizardTitle } = useForm();
  const { updateDraft } = useYdocBinder({ path: '' });
  const { bindState, binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { value: terminationOptions, fullPath } = useLightweightTransaction<ContractTermination>({ parentPath, myPath: 'contractTermination' });
  const { data: formInstances, update: updateMeta } = bindState<FormInstance[]>(state => state?.formStates?.[FORM_CODE]?.instances || []);
  const backgroundJobsContext = useContext(PropertyBackgroundJobsContext);
  const instance = useMemo(()=>{
    return formInstances && formInstances?.find(inst => inst.id === formId);
  }, [formInstances, formId]);

  // when the form instance is loaded, then update modified date, but only if it's not completed.
  // I suppose if we wanted to go more crazy we could do it if the user actually changes a field
  useUpdateInstanceModifiedOnLoad(instance, formId, FORM_CODE, updateMeta);

  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ parentPath, myPath });
  const headline = generateHeadlineFromMaterialisedData(transRoot);

  const currentPathing = useMatches();
  const contractManagementHref = currentPathing[currentPathing.length-2].pathname + '/contracts';
  const updatedBread: CrumbDefn[] = [...breadcrumbs.slice(0,-2), { label: 'Contract Management', href: contractManagementHref }, breadcrumbs[breadcrumbs.length-1]];
  return (
    <Wizard
      formId={formId}
      formName={formCode}
      transactionRootKey={transactionRootKey}
      transactionMetaRootKey={transactionMetaRootKey}
      docName={docName}
      title={wizardTitle}
      afterTitle={<UpdatesPendingBadge propertyId={backgroundJobsContext?.propertyId} headline={headline} show={backgroundJobsContext.envelopeChangesAvailable ?? true} className='ms-auto me-2 mt-1' />}
      printHeadline={headline}
      breadcrumbs={updatedBread}
      pdfDefinition={pdfDefinition}
      ydoc={ydoc}
      signing={true}
      entityLogoLoadedUri={entityLogoLoadedUri}
    >
      <VendorWizardStepPage
        name="vendor"
        label="Vendor"
        icon="person"
        variationWatchPaths={[['vendors'],['primaryVendor']]}
      />

      <PurchaserWizardStepPage
        name="purchaser"
        label="Purchaser"
        icon="shopping_cart"
        requiredTooltip='Specify Purchaser of Property'
        variationWatchPaths={[
          ['purchasers'],
          ['primaryPurchaser']
        ]}/>

      <PropertyWizardStepPage
        name="property"
        label="Property"
        icon="home"
        variationWatchPaths={[
          ['saleAddrs'],
          ['saleTitles'],
          ['titleDivision']
        ]}
        generalFocusClass={composeErrorPathClassName([],'property-section')}
        sectionErrorText={requirePropertyDetailsMessage}
        requiredTooltip='Specify Property for sale'
      />

      <WizardStepPage
        name='terminationDeposit'
        label='Terms of Termination'
        icon='home'
        variationWatchPaths={[['contractTermination']]}
      >
        <WrField.CheckRadio
          label='How will the deposit be handled?'
          options={contractTerminationDepositOpts}
          inline={false}
          radioType='checkbox'
          parentPath={fullPath}
          myPath='depositOption'
          name='depositOption'
          valueType='int'
        />
        {terminationOptions?.depositOption === ContractTerminationDepositReturn.Split &&
        <div className='d-flex flex-wrap w-100'>
          <div className='mt-2 flex-grow-1'>
            <WrField.Control
              label='Amount released to Vendor'
              parentPath={fullPath}
              myPath='splitVendorReleaseAmt'
              name='splitVendorReleaseAmt'
            />
          </div>
          <div className='mt-2 flex-grow-1'>
            <WrField.Control
              label='Amount returned to Purchaser'
              parentPath={fullPath}
              myPath='splitPurchaserReturnAmt'
              name='splitPurchaserReturnAmt'
            />
          </div>
        </div>
        }

        <div className='mt-2'>
          <LexicalRichTextEditor
            parentPath={fullPath}
            myPath='otherTerms'
            className='editor no-top-level-counter'
            inputMode={EditorMode.MARKDOWN}
            outputMode={EditorMode.MARKDOWN}
          />
        </div>

      </WizardStepPage>

      <WizardStepPage
        name="annexures"
        label="Annexures"
        icon='attach_file'
        variationDeterminationCallback={[()=>(instance?.annexures?.length??0) > 0]}
      >
        <AnnexuresSection formCode={formCode} formId={formId} ydoc={ydoc} transactionMetaRootKey={transactionMetaRootKey}/>
      </WizardStepPage>

    </Wizard>
  );
};
