import * as Y from 'yjs';
import { Awareness } from 'y-protocols/awareness';
import { IndexeddbPersistence } from 'y-indexeddb';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import React, { useEffect, useMemo, useState } from 'react';
import { PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import {
  AwarenessData,
  FormOrderState,
  FormOrderType,
  TransactionMetaData
} from '@property-folders/contract';
import { SetupNetStateContext } from '@property-folders/components/dragged-components/NetStateContext';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { SetupNetStateWritingYjsDocContext } from '@property-folders/components/form-gen-util/yjsStore';
import { SetupDebouncedAwarenessContext } from '@property-folders/components/context/DebouncedAwarenessContext';
import { initialPresence } from '~/pages/TransactionHomePage';
import { RoomProvider } from '@y-presence/react';
import { useLocation } from 'react-router-dom';
import { propertyFolder } from '@property-folders/contract/yjs-schema/model/field';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import './SubscriptionFormEditPage.scss';
import { EpfOrderForm1 } from '@property-folders/components/dragged-components/ordering/EpfOrderForm1';
import {
  ThirdPartyPreparingOrder
} from '@property-folders/components/dragged-components/ordering/ThirdPartyPreparingOrder';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { FormContext, FormContextDefaultValue } from '@property-folders/components/context/FormContext';
import { SubscriptionFormEditPageInner } from '~/components/SubscriptionFormEditPageInner';

interface RouterData {
  formId: string,
  transId: string,
  ydoc: Y.Doc,
  localProvider: IndexeddbPersistence,
  ydocStats: Y.Doc,
  localProviderStats: IndexeddbPersistence,
  awareness: Awareness,
}

/**
 * Establishes yjs context, etc.
 */
export function SubscriptionFormEditPage() {
  const { formId, transId, ydoc, awareness } = useReactRouterData<RouterData>();
  const location = useLocation();
  // Not supporting sublineages of subscription forms
  const { bindState: bindMetaState } = useImmerYjs<TransactionMetaData>(ydoc, PropertyRootKey.Meta);
  const { data: transMeta } = bindMetaState<TransactionMetaData>(meta => meta);
  const [formCode, setFormCode] = useState('');
  const formInstance = formCode
    ? PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, transMeta || {})
    : PropertyFormYjsDal.searchFormInstanceByIdFromState(formId, transMeta || {});

  useEffect(() => {
    if (!formInstance?.formCode) return;

    setFormCode(formInstance.formCode);
  }, [formInstance?.formCode]);

  const formContext = useMemo<FormContextType>(() => {
    return {
      ...FormContextDefaultValue,
      formName: formInstance?.formCode || '',
      transactionRules: propertyFolder,
      formId,
      authRepMode: formInstance?.order?.type === FormOrderType.Filler
    };
  }, [formInstance]);

  if (!formInstance) {
    return <></>;
  }

  return <SetupNetStateContext ydoc={ydoc} transactionRootKey={PropertyRootKey.Data}>
    <SetupNetStateWritingYjsDocContext
      ydoc={ydoc}
      awareness={awareness}
      docName={transId}
      transactionRootKey={PropertyRootKey.Data} // Subscription forms do not yet support sublineages
      transactionMetaRootKey={PropertyRootKey.Meta}
    >
      <RoomProvider<AwarenessData> awareness={awareness} initialPresence={initialPresence}>
        <SetupDebouncedAwarenessContext delay={0}>
          <FormContext.Provider value={formContext}>
            {(() => {
              if (formInstance.order?.type === FormOrderType.Filler) {
                return <SubscriptionFormEditPageInner transId={transId} formId={formId} formCode={formInstance.formCode}
                  yDoc={ydoc} />;
              }
              const params = new URLSearchParams(location.search || '');
              const viewOrderOverride = params.get('view_order') === '1';
              switch (formInstance.order?.state) {
                case FormOrderState.ClientOrdering:
                  return <EpfOrderForm1 yDoc={ydoc} transId={transId} formId={formId}
                    formCode={formInstance.formCode} />;
                case FormOrderState.ThirdPartyPreparing:
                case FormOrderState.ReturnedToThirdParty:
                case FormOrderState.Cancelled:
                  return <ThirdPartyPreparingOrder yDoc={ydoc} transId={transId} formId={formId}
                    formCode={formInstance.formCode} />;
                case FormOrderState.ReturnedToClient:
                default:
                  if (viewOrderOverride) {
                    return <ThirdPartyPreparingOrder yDoc={ydoc} transId={transId} formId={formId}
                      formCode={formInstance.formCode} />;
                  }
                  return <SubscriptionFormEditPageInner transId={transId} formId={formId}
                    formCode={formInstance.formCode} yDoc={ydoc} />;
              }
            })()}
          </FormContext.Provider>
        </SetupDebouncedAwarenessContext>
      </RoomProvider>
    </SetupNetStateWritingYjsDocContext>
  </SetupNetStateContext>;
}
