import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMatches } from 'react-router-dom';
import {
  EmailPropertyAuditDetail,
  GetPropertyAuditAuthorsResult,
  GetPropertyAuditEntriesResult,
  GetPropertyAuditFormFamiliesResult,
  PropertyAuditDetail,
  PropertyAuditEntryType,
  PropertyAuditMetadata, ServePropertyAuditDetail
} from '@property-folders/contract/rest/property';
import { AuditApi } from '@property-folders/common/client-api/audit';
import { Button, FloatingLabel, Form, Offcanvas, Spinner } from 'react-bootstrap';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { Maybe } from '@property-folders/common/types/Utility';
import { AuditChange } from '@property-folders/components/dragged-components/audit/AuditChange';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import { clsJn as classNames } from '@property-folders/common/util/classNameJoin';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { EmailEvent, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { Email } from '@property-folders/components/display/Email';
import * as Y from 'yjs';
import { Awareness } from 'y-protocols/awareness';

import './TransactionAudit.scss';
import { BreadCrumbs } from '@property-folders/components/dragged-components/BreadCrumbs';
import { useDebounce, useIntersection } from 'react-use';
import { Predicate } from '@property-folders/common/predicate';
import { rawTextToHtmlParagraphs } from '@property-folders/common/util/formatting';
import { useQueryParams } from '@property-folders/components/hooks/useQueryParams';
import { ShortId } from '@property-folders/common/util/url';

import { YjsDocContextType } from '@property-folders/common/types/YjsDocContextType';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { YFormUtil } from '@property-folders/common/util/yform';
import { FormUtil } from '@property-folders/common/util/form';
import { PDFPreviewer } from '@property-folders/components/dragged-components/PDFViewer/PDFPreviewer';

type AuditPageItem = GetPropertyAuditEntriesResult['page'][0];

function formatTimeSegment(segment: number) {
  return segment.toString().padStart(2, '0');
}

/**
 * Get the formatted time component of a date.
 * Format: HH:MM AM/PM
 */
function formatTime(date?: number | Date) {
  if (!date) return '';
  const asDate = new Date(date);
  const hours = asDate.getHours();
  const minutes = asDate.getMinutes();
  const amPm = hours >= 12 ? 'PM' : 'AM';
  const modHours = hours % 12;
  return `${formatTimeSegment(modHours ? modHours : 12)}:${formatTimeSegment(minutes)} ${amPm}`;
}

function formatDate(date: number | Date, fullMonth?: boolean) {
  const asDate = new Date(date);
  const languages = navigator.languages?.length
    ? [...navigator.languages]
    : [navigator.language];

  const parts = new Intl.DateTimeFormat(languages, { month: fullMonth ? 'long' : 'short', day: '2-digit', year: 'numeric' })
    .formatToParts(asDate)
    .reduce((accumulated,part) => ({ ...accumulated, [part.type]: part.value }), { month: '', day: '', year: '' });

  return `${parts.month} ${parts.day}, ${parts.year}`;
}

const actionTypes = [
  { value: 'all', label: 'All actions' },
  { value: 'content', label: 'Content changes' },
  { value: 'signing', label: 'Signing actions' },
  { value: 'notification', label: 'Notification actions' }
];

type ActionType = 'all' | 'content' | 'signing' | 'notification';

function parseActionType(value: string): ActionType {
  switch (value) {
    case 'content':
      return 'content';
    case 'signing':
      return 'signing';
    case 'notification':
      return 'notification';
    default:
      return 'all';
  }
}

function formatLocalDate(date: Date) {
  const year = date.getFullYear().toString();
  const month = (date.getMonth()+1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function reformatLocalDateForHeadlineRender(value: string | undefined) {
  if (!value) {
    return '';
  }

  return formatDate(new Date(value), true);
}

function getActionTypeLabelForHeadline(value: string) {
  return actionTypes.find(x => x.value === value)?.label || 'All actions';
}

function getDocumentLabelForHeadline(value?: string) {
  const label = FormTypes[value || '']?.label;
  return label || 'all documents';
}

function getUserNameForHeadline(value: Maybe<string>, availableUsers: FilterUser[]) {
  if (!value) {
    return 'all users';
  }
  return availableUsers.find(x => x.id === value)?.label || 'all users';
}

type FilterUser = GetPropertyAuditAuthorsResult['page'][0];
type FilterFormFamily = GetPropertyAuditFormFamiliesResult['page'][0];

function EmailAuditIcon({ subtype }: {subtype: EmailEvent}) {
  switch (subtype) {
    case EmailEvent.open:
      return <Icon name='mark_email_read' pack='material-symbols' />;
    case EmailEvent.bounce:
      return <Icon name='u_turn_right' pack='material-symbols' />;
    case EmailEvent.click:
      return <Icon name='link' pack='material-symbols' />;
    case EmailEvent.delivered:
      return <Icon name='mail' pack='material-symbols' />;
    case EmailEvent.dropped:
      return <Icon name='report' pack='material-symbols' />;
    case EmailEvent.spamreport:
      return <Icon name='send' pack='material-symbols' />;
    case EmailEvent.processed:
    case EmailEvent.deferred:
    default:
      return <Icon name='send' pack='material-symbols' />;

  }
  return <Icon name='mark_email_read' pack='material-symbols' />;
}

function AuditIcon({ type, subtype }: {type: PropertyAuditEntryType, subtype?: string}) {
  return <div className={'icon-container'}>
    {(() => {
      switch (type) {
        case 'created':
          return <Icon name='add' />;
        case 'deleted':
          return <Icon name='remove' />;
        case 'edited':
          return <Icon name='edit' />;
        case 'signed':
          return <Icon name='signature' pack='material-symbols' />;
        case 'voided':
          return <Icon name='inactive_order' pack='material-symbols' />;
        case 'started':
          return <Icon name='order_play' pack='material-symbols' />;
        case 'declined':
          return <Icon name='do_not_disturb_on' pack='material-icons' />;
        case 'executed':
          return <Icon name='order_approve' pack='material-symbols' />;
        case 'email':
          return <EmailAuditIcon subtype={subtype as EmailEvent} />;
        case 'verified':
          return <Icon name='verified_user' pack='material-symbols' />;
        case 'terminated':
          return <Icon name='tab_close' pack='material-symbols' />;
        case 'offer-declined':
        case 'offer-withdrawn':
          return <Icon name='contract_delete' pack='material-symbols' />;
        case 'offer-accepted':
          return <Icon name='recommend' pack='material-symbols' />;
        case 'offer-resubmitted':
        case 'offer-submitted':
          return <Icon name='contract' pack='material-symbols' />;
        case 'portal-invitation-withdrawn':
          return <Icon name='cancel_schedule_send' pack='material-symbols' />;
        case 'sms':
          return <Icon name='sms' pack='material-symbols' />;
        case 'served':
          return <Icon name='exposure_plus_1' pack='material-symbols' />;
        case 'distributed':
          return <Icon name='send' pack='material-symbols' />;
        default:
          return <Icon name='help' />;
      }
    })()}
  </div>;
}

function AuditHeadline({ detail, by, time, meta, setEmailPreview, setDocumentPreview }: {
  detail?: PropertyAuditDetail,
  by: string,
  time: string,
  meta?: PropertyAuditMetadata,
  setEmailPreview: (email: EmailPropertyAuditDetail) => void,
  setDocumentPreview: (serve: ServePropertyAuditDetail) => void
}) {
  if (!detail) {
    return <></>;
  }

  const metaItems = [
    by ? { icon: <Icon name={'person'} style={{ fontSize: '10px' }} />, text: `by ${by}` } : undefined,
    meta?.phone ? { icon: <Icon name={'phone'} style={{ fontSize: '10px' }} />, text: meta.phone } : undefined,
    meta?.email ? { icon: <Icon name={'mail'} style={{ fontSize: '10px' }} />, text: meta.email } : undefined,
    meta?.ip ? { icon: <Icon name={'computer'} style={{ fontSize: '10px' }} />, text: meta.ip } : undefined,
    meta?.proxyOnBehalfOf ? { icon: <Icon name={'person'} style={{ fontSize: '10px' }} />, text: `on behalf of ${meta.proxyOnBehalfOf}` } : undefined,
    meta?.hostedBy ? { icon: <Icon name={'person'} style={{ fontSize: '10px' }} />, text: `hosted by ${meta.hostedBy}` } : undefined,
    meta?.smsVerified ? { icon: <Icon name={'verified_user'} style={{ fontSize: '10px' }} />, text: 'SMS verified' } : undefined
  ].filter(Predicate.isNotNullish);

  let toText = '';
  if ('to' in detail) {
    toText = detail.toName
      ? `To ${detail.toName} <${detail.to}>`
      : `To ${detail.to}`;
  }

  return <div className={'w-100 d-flex flex-column'}>
    <div className={'d-flex flex-md-row gap-md-2'}>
      <h5 className={'text-break'}>{detail.headline}</h5>
    </div>
    {!!toText && <h5 className='text-nowrap'><small className='text-muted'>{toText}</small></h5>}
    {!!metaItems.length && <div className={'d-flex flex-column flex-md-row gap-md-1 align-items-md-center gap-md-2 flex-wrap mb-3'}>
      {metaItems.map((item, idx) => (
        <small key={idx} className={'text-muted text-nowrap'}>{item.icon} {item.text}</small>
      ))}
    </div>}
    {detail.type === 'sms' &&
      <div className={'d-flex flex-column flex-md-row gap-md-1 align-items-md-center gap-md-2 flex-wrap mb-3'}>
        <small className='text-muted text-nowrap'>{detail.body}</small>
      </div>}
    {detail.type === 'email' &&<>
      <div className='d-flex flex-column flex-md-row gap-md-1 align-items-md-center gap-md-2 flex-wrap'>
        <small className='text-muted text-nowrap'>{detail.subject}</small>
      </div>
      <div className='d-flex flex-column flex-md-row gap-md-1 align-items-md-center gap-md-2 flex-wrap mb-3'>
        <Button
          style={{ margin: 0, padding: 0 }}
          variant='link'
          onClick={() => setEmailPreview(detail)}
        >View</Button>
      </div></>}
    {detail.type === 'serve' && <>
      <div className='d-flex flex-column flex-md-row gap-md-1 align-items-md-center gap-md-2 flex-wrap' style={{ marginTop: '-1rem' }}>
        <Button
          style={{ margin: 0, padding: 0 }}
          variant='link'
          onClick={() => setDocumentPreview(detail)}
        >View</Button>
      </div></>}
  </div>;
}

function AuditDetail({
  detail
}: {
  detail ? : PropertyAuditDetail
}) {
  if (!detail) {
    return <></>;
  }

  switch (detail.type) {
    case 'decline':
      return <figure className={'w-100'}>
        {detail.reason && <blockquote className={'blockquote wrap-text'}>{rawTextToHtmlParagraphs(detail.reason)}</blockquote>}
      </figure>;
    case 'diff':
      return <>
        {detail.diff.map((change, idx) => (<AuditChange key={idx} change={change}/>))}
      </>;
    default:
      return <></>;
  }
}

export function TransactionAudit() {
  const matches = useMatches();
  const data = matches.map(m => m.data as Record<string, unknown>).reduce((prev, cur) => ({ ...prev, ...cur }), {});
  const transId = data.transId as string;
  const ydoc = data.ydoc as Y.Doc;
  const awareness = data.awareness as Awareness;

  const staticProviderValue: YjsDocContextType = useMemo(() => ({
    ydoc,
    awareness,
    docName: transId,
    transactionRootKey: PropertyRootKey.Data, // This page exists on the property document path, so we don't look for form IDs and sublineages
    transactionMetaRootKey: PropertyRootKey.Meta,
    clearDeclareDebounce: () => { /**/ },
    declareDebounce: () => { /**/ }
  }), [ydoc, transId, awareness]);

  return ydoc && transId
    ? <YjsDocContext.Provider value={staticProviderValue}>
      <TransactionAuditInner transId={transId} />
    </YjsDocContext.Provider>
    : <div>loading...</div>;
}

export function TransactionAuditInner({ transId }: { transId: string }) {
  const online = useOnline();
  const queryParams = useQueryParams();
  const formIdRaw = queryParams.get('formId');
  const { ydoc } = useContext(YjsDocContext);

  const formId = formIdRaw
    ? ShortId.toUuid(formIdRaw)
    : undefined;

  // The YjsDocContext does not come with the root key we are interested in, so we are going to need
  // to determine this ourselves
  const { metaRootKey } = YFormUtil.getFormLocationFromId(formId, ydoc)??{ metaRootKey: PropertyRootKey.Meta };

  const { value: metaRoot } = useLightweightTransaction<TransactionMetaData>({ parentPath: metaRootKey });

  const formInstance = formId && metaRoot
    ? FormUtil.getFormStateFromIdAlone(formId, metaRoot)
    : undefined;
  const signingSessionId = formInstance?.signing?.session?.id;
  const preSelectedFormType = formInstance?.formCode
    ? FormTypes[formInstance.formCode]
    : undefined;

  const defaultAvailableUser: FilterUser = { id: '', label: 'All users' };
  const [availableUsers, setAvailableUsers] = useState<FilterUser[]>([defaultAvailableUser]);
  const [filterUser, setFilterUser] = useState<Maybe<string>>(defaultAvailableUser.id);

  const defaultAvailableFormFamily: FilterFormFamily = preSelectedFormType
    ? { id: preSelectedFormType.formFamily, label: preSelectedFormType.label }
    : { id: '', label: 'All documents' };
  const [availableFormFamilies, setAvailableFormFamilies] = useState<FilterFormFamily[]>([defaultAvailableFormFamily]);
  const [filterFormFamily, setFilterFormFamily] = useState<Maybe<string>>(defaultAvailableFormFamily.id);

  const [filterAction, setFilterAction] = useState<ActionType>('all');

  const defaultDateFilter = formatLocalDate(new Date());
  const [filterDate, setFilterDate] = useState<Maybe<string>>(defaultDateFilter);

  const filtersApplied = filterDate !== defaultDateFilter || filterAction !== 'all' || filterUser || filterFormFamily !== '';

  const [showFilters, setShowFilters] = useState(false);
  const [searching, setSearching] = useState(false);
  const [nextKey, setNextKey] = useState<Maybe<string>>(undefined);
  const [entries, setEntries] = useState<AuditPageItem[]>([]);
  const [emailPreview, setEmailPreview] = useState<EmailPropertyAuditDetail>();
  const [documentPreview, setDocumentPreview] = useState<ServePropertyAuditDetail>();

  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({});
  const headlineVal = generateHeadlineFromMaterialisedData(transRoot);
  const rootLabel = 'Properties';
  const updatedBreadcrumb = useMemo(()=> [
    ...([{ label: rootLabel, href: '/properties/' }]),
    { label: headlineVal || 'Property Overview', href: `/properties/${LinkBuilder.seoFriendlySlug(transId, headlineVal)}` },
    { label: 'History' }
  ], [transId, headlineVal, rootLabel]);

  const searchFunc = () => {
    if (!transId) {
      return;
    }
    setSearching(true);
    AuditApi.getPropertyAuditEntries(transId, {
      before: filterDate
        ? new Date(new Date(filterDate).setHours(23,59,59,9999999)).getTime()
        : undefined,
      formFamily: filterFormFamily,
      authorIds: filterUser,
      type: filterAction,
      startKey: nextKey,
      signingSessionId
    })
      .then(r => {
        console.log('search response next key is', r?.nextKey);
        setNextKey(r?.nextKey);
        setEntries(existing => [...existing].concat(r?.page || []));
      })
      .catch(console.error)
      .finally(() => setSearching(false));
  };

  useEffect(() => {
    if (!(online && transId)) {
      return;
    }
    setNextKey(undefined);
    setEntries([]);
    searchFunc();
  }, [transId, online, filterUser || '', filterAction || '', filterFormFamily || '', filterDate || '']);

  useEffect(() => {
    if (!(online && transId)) {
      return;
    }

    AuditApi.getPropertyAuditAuthors(transId, { formFamily: filterFormFamily })
      .then(result => {
        const newAvailableUsers = [defaultAvailableUser].concat(result?.page || []);
        setAvailableUsers(newAvailableUsers);

        if (!newAvailableUsers.find(au => au.id === filterUser)) {
          setFilterUser(defaultAvailableUser.id);
        }
      })
      .catch(console.error);
  }, [transId, online, filterFormFamily || '']);

  useEffect(() => {
    if (!(online && transId)) {
      return;
    }

    AuditApi.getPropertyAuditFormFamilies(transId)
      .then(result => {
        setAvailableFormFamilies([defaultAvailableFormFamily].concat(result?.page || []));
      })
      .catch(console.error);
  }, [transId, online]);

  const groups = new Map<string, AuditPageItem[]>();
  for (const entry of entries) {
    const dateKey = formatDate(entry.tsStart);
    const group = groups.get(dateKey);
    if (!group) {
      groups.set(dateKey, [entry]);
      continue;
    }
    group.push(entry);
  }

  const parentRef = useRef<HTMLDivElement>(null);

  const intersectionRef = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '1px',
    threshold: 1
  });

  useDebounce(() => {
    if (!intersection) return;
    if (intersection.intersectionRatio < 1) return;
    if (searching) return;
    if (!nextKey) return;

    searchFunc();
  }, 250, [!!(intersection && intersection.intersectionRatio >= 1), searching, nextKey]);

  if (!online) {
    return <div>
      <h1>Unable to connect to the internet</h1>
      <p>History is not available when you are offline. Please try again when you have an Internet connection.</p>
    </div>;
  }

  const headline = filtersApplied
    ? `${getActionTypeLabelForHeadline(filterAction)} on ${getDocumentLabelForHeadline(filterFormFamily)} by ${getUserNameForHeadline(filterUser, availableUsers)} up to ${reformatLocalDateForHeadlineRender(filterDate)}`
    : 'Recent Activity';
  const icon = filtersApplied
    ? <Icon name={'filter_alt'}></Icon>
    : <Icon name={'filter_alt'} variant={'outlined'}></Icon>;

  return <div ref={parentRef} className={'w-100 h-100 px-3 overflow-auto transaction-audit'}>
    <div className={'w-100'}>
      <h1 className="display-6 py-1">History</h1>
      <div className='d-flex flex-row align-items-center justify-content-between w-100 mb-3'>
        <div><BreadCrumbs segments={updatedBreadcrumb}/></div>
      </div>
    </div>
    <hr className={'m-0'} />
    <div className={'container position-sticky'} style={{ top: '-1px' }}>
      <div className={'row bg-white mt-3 py-3 shadow'}>
        <div className={'col'}>
          <div className={'row cursor-pointer'} onClick={() => setShowFilters(!showFilters)}>
            <div className={'col d-flex flex-row justify-content-between align-items-center'}>
              <div className={'d-flex flex-row justify-content-between align-items-center w-100'}>
                <h4 className={'d-inline d-md-none'}>{filtersApplied ? 'Filters Applied' : 'Recent Activity'}</h4>
                <h4 className={'d-none d-md-inline'}>{headline}</h4>
                {icon}
              </div>
            </div>
          </div>
          {showFilters && <>
            <div className={'row bg-white p-3'}>
              <div className={'col-md-3'}>
                <FloatingLabel label={'User'}>
                  <Form.Select value={filterUser} onChange={e => setFilterUser(e.target.value ? e.target.value : undefined)}>
                    {availableUsers.map(u => (
                      <option key={u.id} value={u.id}>{u.label}</option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </div>
              <div className={'col-md-3'}>
                <FloatingLabel label={'Document'}>
                  <Form.Select disabled={!!formIdRaw} value={filterFormFamily} onChange={e => setFilterFormFamily(e.target.value ? e.target.value : undefined)}>
                    {availableFormFamilies.map(ff => (
                      <option key={ff.id} value={ff.id}>{ff.label}</option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </div>
              <div className={'col-md-3'}>
                <FloatingLabel label={'Action'}>
                  <Form.Select value={filterAction} onChange={e => setFilterAction(parseActionType(e.target.value))}>
                    {actionTypes.map(at => (
                      <option key={at.value} value={at.value}>{at.label}</option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </div>
              <div className={'col-md-3'}>
                <FloatingLabel label={'Up to'}>
                  <Form.Control type={'date'} disabled={!!formIdRaw} value={filterDate} onChange={e => setFilterDate(e.target.value)}></Form.Control>
                </FloatingLabel>
              </div>
            </div>
            {filtersApplied && <div className={'row'}>
              <div className={'col d-flex flex-row-reverse'}>
                <Button variant={'link'} className={'text-dark'} onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setFilterDate(defaultDateFilter);
                  setFilterAction('all');
                  setFilterUser(undefined);
                  setFilterFormFamily(undefined);
                }}>Clear filters</Button>
              </div>
            </div>}
          </>}
        </div>
      </div>
    </div>
    <div className={'mt-3 text-wrap'}>
      {[...groups].map(([key, value]) => {
        const wCol1 = '70px';
        const wCol2 = '100px';
        return <div key={key} className={'container mt-3'}>
          <div className={'row'}>
            <div key={`${key}-outer`} className={'d-table d-md-none'}>
              <div className={'d-table-row'}>
                <div className={'d-table-cell'}>
                  <h4>{key}</h4>
                </div>
              </div>
            </div>
            <div key={key} className={'d-table w-100 bg-white shadow pt-3'}>
              <div className={'d-none d-md-table-row'}>
                <div className={'d-table-cell'} style={{ width: wCol1 }}></div>
                <div className={'d-table-cell vertical-join-line'} style={{ width: wCol2 }}>
                  <div className={'d-flex flex-row justify-content-center'}>
                    <span className={'px-2 mb-2 date-box'}>
                      {key}
                    </span>
                  </div>
                </div>
                <div className={'d-table-cell'}></div>
              </div>
              {value.map((e, idx) => {
                const formattedTime = formatTime(e.tsStart);
                return <React.Fragment key={idx}>
                  <div key={`${idx}-heading`} className={'d-table-row'}>
                    <div className={'d-none d-md-table-cell'} style={{ width: wCol1 }}>
                      <div className={'d-flex flex-row justify-content-end align-items-center'}>
                        <h5><small className={'text-muted'}>{formattedTime}</small></h5>
                      </div>
                    </div>
                    <div className={classNames({
                      'd-none': true,
                      'd-md-table-cell': true,
                      'vertical-join-line': !!value[idx+1],
                      'vertical-top-line': !value[idx+1]
                    })} style={{ width: wCol2 }}>
                      <div className={'d-flex flex-row justify-content-center align-items-center'}>
                        <AuditIcon type={e.type} subtype={e.detail?.subtype} />
                      </div>
                    </div>
                    <div className={'d-table-cell'}>
                      <div className={'d-flex flex-row justify-content-start align-items-center'}>
                        <div className={classNames({
                          'w-100': true,
                          'mt-3': !!value[idx-1]
                        })}>
                          <AuditHeadline detail={e.detail} by={e.by} time={formattedTime} meta={e.meta} setEmailPreview={setEmailPreview} setDocumentPreview={setDocumentPreview} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div key={`${idx}-content`} className={'d-table-row'}>
                    <div className={'d-none d-md-table-cell'}></div>
                    <div className={classNames({
                      'd-none': true,
                      'd-md-table-cell': true,
                      'vertical-join-line': !!value[idx+1]
                    })}></div>
                    <div className={'d-table-cell px-3 pb-3'}>
                      <AuditDetail detail={e.detail} />
                    </div>
                  </div>
                </React.Fragment>;
              })}
            </div>
          </div>
        </div>;
      })}
    </div>
    <div className={'row'}>
      <div className={'col d-flex flex-row justify-content-between py-3'}>
        <div ref={intersectionRef}></div>
        {searching && <Spinner animation={'border'} />}
        {entries.length === 0 && !searching && !nextKey && <div>No records matched the filter criteria.</div>}
        {nextKey && <Button onClick={() => searchFunc()} disabled={searching}>NEXT</Button>}
        {!nextKey && <div></div>}
      </div>
    </div>
    <Offcanvas
      show={emailPreview}
      onHide={() => setEmailPreview(undefined)}
      placement='end'
      className='bg-light'
      closeButton
    >
      <Offcanvas.Header closeButton className='pb-0'>
        <Offcanvas.Title>Email Preview</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {emailPreview && <Email {...emailPreview} />}
      </Offcanvas.Body>
    </Offcanvas>

    {!!documentPreview?.fileId && <PDFPreviewer
      url={LinkBuilder.publishedDocument(documentPreview.fileId)}
      tryMakeScrollWork={true}
      onClose={() => setDocumentPreview(undefined)}
      useCoolSpinner={true}
      key='previewer'
    />}
  </div>;
}
