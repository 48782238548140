import { Form } from 'react-bootstrap';

const LoginForm = (
  { username,
    password,
    setPassword,
    setUsername
  }: { username: string,
    password: string,
    setUsername: (username: string) => void,
    setPassword: (password: string) => void
  }) => {
  return (
    <>
      <div className='form-group mt-3'>
        <Form.Control
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          className='form-control mt-1'
          placeholder='Your email address'
          autoComplete='username'
          required
        />
      </div>
      <div className='form-group mt-3'>
        <Form.Control
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          type='password'
          className='form-control mt-1'
          placeholder='Enter password'
          autoComplete='current-password'
          required
        />
      </div>
    </>
  );
};

export default LoginForm;