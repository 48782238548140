import { Button, FloatingLabel, FormControl, InputGroup } from 'react-bootstrap';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { CSSProperties, useState } from 'react';

function dateToYyyyMmDd(date: Date): string {
  return date.toISOString().split('T')[0];
}

export function PropertyFolderReport() {
  const oneMonthAgo = new Date((new Date()).setMonth((new Date()).getMonth() - 1));
  const [startDate, setStartDate] = useState<string>(dateToYyyyMmDd(oneMonthAgo));
  const [endDate, setEndDate] = useState<string>(dateToYyyyMmDd(new Date()));

  const inputStyle: CSSProperties = { maxWidth: '200px' };

  return <div className='p-3'>
    <h3>Property Folder Report</h3>

    <InputGroup className="mb-3 w-100" style={inputStyle}>
      <FloatingLabel label='Start date'>
        <FormControl type='date' value={startDate} onChange={ev => {
          setStartDate(ev.target.value);
        }} />
      </FloatingLabel>
    </InputGroup>

    <InputGroup className='mb-3 w-100' style={inputStyle}>
      <FloatingLabel label='End date'>
        <FormControl type='date' value={endDate} onChange={ev => setEndDate(ev.target.value)} />
      </FloatingLabel>
    </InputGroup>

    <Button onClick={() => {
      window.open(`${LinkBuilder.restApiBase}/reporting/property?start-date=${startDate}&end-date=${endDate}`, '_blank');
    }}>Generate</Button>
  </div>;
}
