import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { v4 } from 'uuid';
import { Hint, Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { LookupClassicTemplatesResult, LookupClassicTemplatesResultItem, LookupEntitiesResultItem } from '@property-folders/contract';
import { Lookups } from '@property-folders/common/client-api/lookups';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { generateDetailRow } from '@property-folders/components/dragged-components/form/NarrowAgentInput';
import { useEntities } from '@property-folders/components/hooks/useEntity';
import { companyTradingAs } from '@property-folders/common/util/formatting';
import { Predicate } from '@property-folders/common/predicate';
import type { FolderDetails } from '@property-folders/contract/rest/folders';

export type EntitySettingsEntityDisplay = EntitySettingsEntity & {
  label: string;
  compositeName: string;
};

export interface CreateSubscriptionFormPaneProps {
  formId: number;
  folderId?: FolderDetails['results']['folderId'];
  onClose: () => void;
}

export function CreateSubscriptionFormPane({ formId, folderId, onClose }: CreateSubscriptionFormPaneProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [urlSearchParams] = useSearchParams();
  const [idPrefix] = useState(v4());

  const [documentName, setDocumentName] = useState('');
  const allEntities = useEntities();

  const [selectedEntity, setSelectedEntity] = useState<EntitySettingsEntityDisplay[]>([]);
  const [entities, setEntities] = useState<EntitySettingsEntityDisplay[]>([]);

  useEffect(() => {
    if (!formId) return;

    const { ac, results } = Lookups.lookupEntities({
      formId
    });

    results
      .then(data => {
        if (!data) return;
        if (ac.signal.aborted) return;

        const entityOptions = data.items.map(i => {
          const entity = allEntities?.[i.entityId];

          if (!entity) {
            return;
          }

          const compositeName = entity.name && entity.tradeName
            ? companyTradingAs(entity.name, entity.tradeName)
            : entity.name || entity.tradeName;
          const label = entity.profileName || compositeName;

          return {
            ...entity,
            label,
            compositeName
          };
        }).filter(Predicate.isTruthy) as EntitySettingsEntityDisplay[];

        setEntities(entityOptions);
        if (data.items.length === 1) {
          setSelectedEntity([ entityOptions[0] ]);
        }
      })
      .catch(console.error);

    return () => {
      ac.abort();
    };
  }, [formId]);

  const [selectedTemplate, setSelectedTemplate] = useState<LookupClassicTemplatesResultItem[]>([]);
  const [templates, setTemplates] = useState<LookupClassicTemplatesResult>({ items: [] });

  useEffect(() => {
    if (!formId) return;

    const entity = selectedEntity.at(0) as LookupEntitiesResultItem | undefined;
    if (!entity) return;

    const { ac, results } = Lookups.lookupClassicTemplates({
      entityId: entity.entityId,
      formId
    });

    results
      .then(data => {
        if (!data) return;
        if (ac.signal.aborted) return;

        const templateIds = new Set(data.items.map(item => item.templateId));
        setTemplates(data);
        setSelectedTemplate(prev => {
          return prev.filter(prevItem => templateIds.has(prevItem.templateId));
        });
      })
      .catch(console.error);

    return () => {
      ac.abort();
    };
  }, [selectedEntity.at(0)?.entityId]);

  const [processing, setProcessing] = useState(false);
  const createHandler = async () => {
    const entity = selectedEntity.at(0);
    if (!entity) return;
    if (!documentName) return;

    setProcessing(true);
    try {
      const createResponse = await LegacyApi.createDocument({
        mode: 'legacy',
        templateId: selectedTemplate.at(0)?.templateId,
        entityId: entity.entityId,
        formId: formId,
        folderId: folderId,
        documentInstanceName: documentName
      });

      // Make sure user is redirected as they left off, when they close the form.
      const urlSearchParamsAsString = urlSearchParams.toString();
      const navigateParams = new URLSearchParams();
      navigateParams.set('DocumentID', createResponse.documentId.toString());
      navigateParams.set('ReturnPath', `${pathname}${urlSearchParamsAsString.length > 0 ? `?${urlSearchParamsAsString}` : ''}`);
      navigate(`/forms.php?${navigateParams.toString()}`);
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setProcessing(false);
    }
  };

  return <Container>
    <Row>
      <Col>
        <Form.Label htmlFor={`${idPrefix}-document-name`} className='fs-5'>
          What do you want to call this document?
        </Form.Label>
        <Form.Control
          id={`${idPrefix}-document-name`}
          type='text'
          size='lg'
          tabIndex={1}
          placeholder='Enter a document name'
          onChange={e => setDocumentName(e.target.value)}
          value={documentName}
        />
      </Col>
    </Row>
    {entities.length > 1 && <Row className='mt-3'>
      <Col>
        <Form.Label htmlFor={`${idPrefix}-select-entity`} className='fs-5'>
          Which agency should the document be created under?
        </Form.Label>
        <Typeahead
          id={`${idPrefix}-select-entity`}
          disabled={entities.length < 2}
          clearButton={true}
          labelKey='label'
          placeholder='Select an agency'
          options={entities}
          filterBy={() => true}
          renderMenu={(results, { renderMenuItemChildren, newSelectionPrefix, paginationText, ...menuProps }) => {
            return <Menu {...menuProps} style={{
              overflow: 'visible',
              padding: 0,
              right: 0,
              left: 0,
              top: 54,
              filter: 'drop-shadow(0px 0px 3px darkgrey)'
            }}>
              <div style={{ maxHeight: '280px', overflowY: 'auto', overflowX: 'hidden' }}>
                {(results as EntitySettingsEntityDisplay[]).map((result, index) => {
                  const entity = result;

                  return <MenuItem key={entity.label} option={entity} position={index}>
                    <div className='d-flex flex-column'>
                      <div className='fw-bold'>{entity.label}</div>
                      {entity.profileName && <div>{entity.compositeName}</div>}
                      {generateDetailRow(entity)}
                    </div>
                  </MenuItem>;
                })}
              </div>
            </Menu>;
          }}
          onChange={x => setSelectedEntity(castOptions<EntitySettingsEntityDisplay>(x))}
          selected={selectedEntity}
          emptyLabel='No matching agencies found.'
          renderInput={({ inputRef, referenceElementRef, value, ...inputProps }) => {
            return (
              <Hint>
                <div className='form-control p-0 ps-2'>
                  <Form.Control
                    value={value as string | string[] | number | undefined}
                    {...inputProps}
                    ref={(node: any) => {
                      inputRef(node);
                      referenceElementRef(node);
                    }}
                    tabIndex={2}
                    size='lg'
                    onChange={() => undefined}
                    className='border-0 p-0 m-0 fw-bold fake-input-select form-select'
                  />
                  {selectedEntity?.length === 1 && <div className='extra-row d-flex pe-5 pb-2'>{generateDetailRow(selectedEntity[0])}</div>}
                </div>
              </Hint>
            );
          }}
        />
      </Col>
    </Row>}
    {!!templates.items.length && <Row className='mt-3'>
      <Col>
        <Typeahead
          id={`${idPrefix}-select-template`}
          disabled={!selectedEntity.length}
          clearButton={true}
          labelKey='name'
          filterBy={['name']}
          placeholder={'None - create a blank document'}
          options={templates.items}
          onChange={x => setSelectedTemplate(castOptions<LookupClassicTemplatesResultItem>(x))}
          selected={selectedTemplate}
          emptyLabel='No matching templates found.'
          size='lg'
          renderInput={({ inputRef, referenceElementRef, value, ...inputProps }, { onClear }) => (
            <Hint className='d-flex flex-column'>
              <Form.Label htmlFor={`${idPrefix}-select-template`} className='fs-5'>
                Which template should be used?
              </Form.Label>
              <div>
                <Form.Control
                  value={value as string | string[] | number | undefined}
                  {...inputProps}
                  ref={(node: any) => {
                    inputRef(node);
                    referenceElementRef(node);
                  }}
                  tabIndex={3}
                  size='lg'
                />
                {!!selectedTemplate.length && <Button
                  variant='outline'
                  onClick={onClear}
                  className='fs-4'
                  style={{
                    float: 'right',
                    position: 'absolute',
                    top: '32px',
                    right: '0px'
                  }}>&times;</Button>}
              </div>
            </Hint>
          )}
        />
      </Col>
    </Row>}
    <Row className='mt-3'>
      <Col className='d-flex flex-row justify-content-end gap-2'>
        <Button
          tabIndex={5}
          disabled={processing}
          onClick={onClose}
          variant='outline-secondary'
        >Cancel</Button>
        <SpinnerButton
          tabIndex={4}
          disabled={!(documentName.trim()) || selectedEntity.length === 0}
          processing={processing}
          onClick={createHandler}
        >Create</SpinnerButton>
      </Col>
    </Row>
  </Container>;
}

interface EntityOption {
  entityId: number,
  displayName: string,
}

interface TemplateOption {
  templateId: number,
  name: string
}

export function castOptions<T>(options: Option[]): T[] {
  if (Array.isArray(options)) {
    return options as T[];
  } else {
    return [];
  }
}
