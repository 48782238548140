import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FormCodeUnion } from '@property-folders/contract';
import {
  generateHeadlineFromMaterialisedData,
  materialisePropertyData
} from '@property-folders/common/yjs-schema/property';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { RouterData } from '~/App';
import { handleNewFormOrder } from '~/pages/TransactionHomePage';

export interface Props {
  formCode: FormCodeUnion;
}

export function OrderPage({ formCode }: Props) {
  const navigate = useNavigate();
  const { ydoc, transId: propertyId } = useReactRouterData<RouterData>();
  const [ordering, setOrdering] = useState(false);

  const navigateToForm = (instanceId: string, formCode: string, headline: string) => {
    if (!propertyId) return;

    const formType = FormTypes[formCode];
    navigate(LinkBuilder.documentPath(
      {
        id: propertyId,
        nicetext: headline
      },
      {
        id: instanceId,
        nicetext: formType.label
      },
      !!formType.subscription));
  };

  useEffect(() => {
    if (!ydoc || ordering) {
      return;
    }

    setOrdering(true);

    const headline = generateHeadlineFromMaterialisedData(materialisePropertyData(ydoc));
    handleNewFormOrder(ydoc, formCode).then(newId => {
      if (!newId) {
        return;
      }

      navigateToForm(newId, formCode, headline);
    }).catch(err => {
      console.log(err);
    });
  }, [ydoc]);

  return <>Loading</>;
}
