import React, { useMemo, useState } from 'react';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useQuery } from '@tanstack/react-query';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { Container } from 'react-bootstrap';
import { SearchBar } from '@property-folders/components/dragged-components/SearchBar';
import { LazyInfiniteTableList } from '@property-folders/components/dragged-components/LazyInfiniteTableList';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';

export function TitleListPage() {
  const [filter, setFilter] = useState('');
  const { data: session } = AuthApi.useGetAgentSessionInfo();
  const { data: unfilteredItems } = useQuery({
    queryKey: ['settings-title-list', session?.agentId || -1],
    queryFn: async () => {
      const response = await AjaxPhp.listTitlesForAgent();

      return response.map(i => ({
        ...i,
        invoiceDate: new Date(i.invoiceDate) ?? new Date(0),
        id: i.titlePurchaseId
      }));
    }
  });

  const items = useMemo<typeof unfilteredItems>(() => {
    const substr = filter.toLowerCase();
    return (unfilteredItems ?? [])
      .filter(item => !substr || item.displayName.toLowerCase().includes(substr))
      .sort((a, b) => b.invoiceDate?.getTime() - a.invoiceDate?.getTime());
  }, [unfilteredItems, filter]);

  return <Container fluid={true} className='h-100 position-relative d-flex flex-column flex-start g-0'>
    <ContentTitler
      title='Title Purchases'
      afterTitle={<SearchBar
        placeholder='Search for a title'
        onSearch={term => setFilter(term)}
      />}
      flex={true}
      scroll={false}
    >
      <LazyInfiniteTableList
        storageKey={'TitleListPage'}
        hasNextPage={false}
        items={items ?? []}
        isFetching={!unfilteredItems}
        rowClick={row => {
          if (row.downloadUrl == null) {
            return;
          }

          window.open(row.downloadUrl, '_blank');
        }}
        rowClickEnabled={row => row.downloadUrl != null}
        columns={[
          {
            label: 'Name',
            rowMajor: row => String(row.displayName)
          },
          {
            label: 'Purchase Date',
            rowMajor: row => formatTimestamp(row.invoiceDate)
          }
        ]}
        rowActions={[
          {
            label: 'Download',
            action: row => {
              window.open(row.downloadUrl, '_blank');
            },
            if: row => row.downloadUrl != null
          },
          {
            label: 'Invoice',
            action: row => {
              window.open(row.invoiceUrl, '_blank');
            }
          }
        ]}
      />
    </ContentTitler>
  </Container>;
}
