import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Overlay, Popover, Spinner } from 'react-bootstrap';
import clsJn from '@property-folders/common/util/classNameJoin';
import { PopperRef } from 'react-bootstrap/types';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { useBreakpointValue } from '@property-folders/components/hooks/useBreakpointValue';
import {
  GetDocumentSigningDetailsItem,
  GetDocumentSigningDetailsResponse
} from '@property-folders/contract/rest/document';
import { getTimeString } from '@property-folders/common/util/formatting';
import { WrappedFetch } from '@property-folders/common/client-api/wrappedFetch';
import { upperFirst } from 'lodash';
import './DocumentPendingStatus.scss';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { DefaultTimeZone } from '@property-folders/contract';

export function DocumentPendingStatus({
  auto,
  signed,
  total,
  id,
  show,
  setShow,
  signingDetail
}: {
  auto: boolean,
  signed: number,
  total: number,
  id: number,
  show: boolean,
  setShow: (value: boolean) => void,
  signingDetail?: GetDocumentSigningDetailsItem[]
}) {
  const { data: session } = AuthApi.useGetAgentSessionInfo();
  const [data, setData] = useState<GetDocumentSigningDetailsResponse | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const statusColour = total === signed
    ? 'text-success'
    : 'text-danger';
  const mode = auto ? 'signed' : 'requested';

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShow(!show);
  };

  useEffect(() => {
    if (!signingDetail) return;
    setData({ items: signingDetail, timeZone: session?.timeZone || DefaultTimeZone });
  }, [signingDetail]);

  useEffect(() => {
    if (data || signingDetail) return;
    if (!show) return;

    const ac = new AbortController();

    WrappedFetch.json<GetDocumentSigningDetailsResponse>(`/api/rest/documents/${id}/signing-details`)
      .then(response => {
        if (ac.signal.aborted) return;
        setData(response);
      })
      .catch(err => {
        console.error(err);
        setErrorMessage('Failed to load signing details');
      });

    return () => ac.abort();
  }, [show, !!data]);

  return (
    <div ref={containerRef}>
      <Button
        ref={buttonRef}
        variant='link'
        className={clsJn('p-0 small', statusColour)}
        onClick={handleClick}
      >
        {signed} of {total} {mode}
      </Button>
      <Overlay
        show={show}
        target={buttonRef}
        placement="bottom"
        container={containerRef}
        rootClose
        onHide={() => setShow(false)}
      >
        <UpdatingPopover>
          <Popover.Header as="h3">Remote actions pending</Popover.Header>
          <Popover.Body>
            {!data && !errorMessage && <div className='d-flex flex-row justify-content-center'>
              <Spinner animation='border' />
            </div>}
            {errorMessage && <b className='small text-danger'>{errorMessage}</b>}
            {data?.items && <div className='w-100 small d-grid' style={{ gridTemplateColumns: '0.7fr 1fr' }}>
              {data.items.map((item, index) =>
                <StatusListItem key={index} timeZone={data.timeZone} {...item} />)}
            </div>}
          </Popover.Body>
        </UpdatingPopover>
      </Overlay>
    </div>
  );
}

const UpdatingPopover = React.forwardRef<HTMLDivElement, React.PropsWithChildren<{ show?: boolean, popper?: PopperRef }>>(
  ({ popper, children, show: _, ...props }, ref) => {
    const maxWidth = useBreakpointValue({ sm: '468px', md: '692px', base: '276px' }, '276px');
    useEffect(() => {
      popper?.scheduleUpdate?.();
    }, [children, popper, maxWidth]);

    return (
      <Popover ref={ref} popper={popper} {...props} style={{ ...(props as any).style, maxWidth }}>
        {children}
      </Popover>
    );
  },
);

// Remotely completed [by SENTTO] [on THEDATE]
// Remotely signed [POSITION] [by SENTTO] [on THEDATE]
// Email sent [for POSITION] [sent to SENTTO] [on THEDATE]
function StatusListItem({ type, status, timestampMs, method, name, recipient, timeZone }: GetDocumentSigningDetailsItem & { timeZone: string }) {
  const icon = useMemo(() => {
    switch (status) {
      case 'pending':
        return <Icon name='schedule' icoClass={'fs-6 me-1'} />;
      case 'done':
        return <Icon name='check_circle' icoClass={'fs-6 me-1 text-success'} />;
      case 'declined':
        return <Icon name='do_not_disturb_on' icoClass={'fs-6 me-1 text-danger'} />;
    }
  }, [status]);
  const { statusText, statusTime } = useMemo(() => {
    const tsFormatted = timestampMs && timestampMs > 0
      ? getTimeString(timestampMs, timeZone, status === 'pending' ? 'since' : 'at')
      : undefined;
    switch (status) {
      case 'pending':
        return {
          statusText: type === 'fill'
            ? 'Needs to complete and sign'
            : 'Needs to sign',
          statusTime: tsFormatted && `Pending ${tsFormatted}`
        };
      case 'done': {
        const statusText = type === 'fill' ? 'Completed' : 'Signed';
        return {
          statusText,
          statusTime: tsFormatted && `${statusText} ${tsFormatted}`
        };
      }
      case 'declined':
        return {
          statusText: 'Declined',
          statusTime: ''
        };
    }
  }, [timestampMs, status, type]);

  return <>
    <div className='d-flex flex-column' style={{ maxWidth: 110 }}>
      <b className='text-truncate' title={name}>{name}</b>
      {recipient && <span className='text-truncate' title={recipient}>{recipient}</span>}
    </div>
    <div className='d-flex flex-column'>
      <div className='d-inline-block'>
        {icon}
        <b>{statusText}</b>
      </div>
      <span>Method: {upperFirst(method)}</span>
      {statusTime && <div>{statusTime}</div>}
    </div>
  </>;
}
