import { useContext, useEffect, useMemo } from 'react';
import { FormContextSetup, getDefaultReportMissing } from '@property-folders/components/form-gen-util/yjsStore';
import {
  saafieldGroups as saaFieldGroups,
  salesAgencyAgreement
} from '@property-folders/contract/yjs-schema/model/form/sales-agency-agreement';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { ExtraFormCode, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import '../FormInput.scss';
import { ResidentialSalesAgencyAgreementPdfDefinitionProvider } from '@property-folders/common/util/pdfgen/definitions/helpers';
import { propertyFolder, propertyMeta } from '@property-folders/contract/yjs-schema/model/field';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import {
  SigningConfigurationInterceptor
} from '@property-folders/components/dragged-components/signing/SigningConfiguration';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { IPdfDefinitionProvider } from '@property-folders/common/types/PDFDefinition';

import { ResidentialSalesAgreementFormInner } from '../ResidentialSalesAgreement/ResidentialSalesAgreementForm';
import { LineageContext, LineageContextType, lineageContextDefaultValue, useVariation } from '@property-folders/components/hooks/useVariation';
import { buildSigningTimelines } from '@property-folders/common/util/dataExtract';
import { canonicalisers, isoLocalDateFormatter } from '@property-folders/common/util/formatting';
import { Predicate } from '@property-folders/common/predicate';
import { cloneDeep } from 'lodash';

const FORM_CODE = ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent;
const FORM_LABEL = FormTypes[FORM_CODE].label;
const FORM_RULES = salesAgencyAgreement;
const TRANSACTION_RULES = propertyFolder;
const FORM_CONTEXT: Omit<FormContextType, 'formId'> = {
  formName: FORM_CODE,
  printTitle: FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  wizardTitle: FormTypes[FORM_CODE].wizardTitle ?? FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  formRules: FORM_RULES,
  transactionRules: TRANSACTION_RULES,
  metaRules: propertyMeta,
  fieldGroups: saaFieldGroups,
  reportMissing: getDefaultReportMissing(FORM_CODE)
};

export const SubsequentAgencyForm = (props: TransactionFormProps) => {
  const formCode = FORM_CODE;
  const formId = props.formId;
  const { ydoc, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  const { binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { binder: dataBinder, status: dataStatus } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const updatedBreadcrumb = useMemo(()=>[...(props.breadcrumbs ?? []), { label: FORM_LABEL }], [props.breadcrumbs]);
  const pdfDefinition = useMemo<IPdfDefinitionProvider>(() => {
    return new ResidentialSalesAgencyAgreementPdfDefinitionProvider(dataBinder,
      metaBinder,
      formCode,
      formId,
      true);
  }, [dataBinder, metaBinder, formCode, formId]);

  const variationData = useVariation(true, { formCode: FORM_CONTEXT.formName, transactionRules: FORM_CONTEXT.transactionRules });

  useEffect(()=>{
    if (dataStatus !== 'success' || !variationData.snapshotHistory) return;
    const agencyState = dataBinder?.get()?.agency;
    if (!agencyState) return;
    const latestExpiry = buildSigningTimelines(variationData?.snapshotHistory).latestExpiry;

    if (!latestExpiry) return;
    // To the start of the first invalid day
    latestExpiry.setHours(0,0,0,0);
    const latestExpiryText = isoLocalDateFormatter(latestExpiry);
    const existingStartDateCanon = canonicalisers.date(agencyState.startOther);
    if (agencyState.start || Predicate.isNullish(agencyState.start)) {
      dataBinder?.update(draft => {
        if (!draft.agency) return;
        draft.agency.start = false;
        draft.agency.startOther = latestExpiryText;
      });
    } else if (!agencyState.startOther || new Date(existingStartDateCanon.canonical) < latestExpiry) {
      dataBinder?.update(draft => {
        if (!draft.agency) return;
        draft.agency.startOther = latestExpiryText;
      });
    }
  }, [dataStatus, dataBinder, variationData.snapshotHistory]);
  const lineageContext: LineageContextType | null = useMemo(()=>{
    if (!variationData) return cloneDeep(lineageContextDefaultValue);
    return { ...variationData, variationsMode: false };
  }, [variationData]);
  return <FormContextSetup formId={formId} base={FORM_CONTEXT}>
    <LineageContext.Provider value={lineageContext}>
      <SigningConfigurationInterceptor
        {...props}
        breadcrumbs={updatedBreadcrumb}
        pdfDefinition={pdfDefinition}
        documentRenderer={() => (<SetupPdfLoadStateContext>
          <ResidentialSalesAgreementFormInner
            {...props}
            variationsMode={props.variationsMode}
            breadcrumbs={updatedBreadcrumb}
            pdfDefinition={pdfDefinition} />
        </SetupPdfLoadStateContext>)}/>
    </LineageContext.Provider>
  </FormContextSetup>;
};
