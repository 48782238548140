import * as Y from 'yjs';
import { Button } from 'react-bootstrap';
import { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import { OfferContractSummary, OfferState } from '@property-folders/contract/property/OfferContractState';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { OfferContractCard } from '@property-folders/components/dragged-components/OfferContractCard';
import { SplitIfManyButton } from '@property-folders/components/dragged-components/SplitIfManyButton';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { useMediaQuery } from 'react-responsive';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import {
  ExtraFormCode,
  FormCode,
  FormCodeUnion,
  FormInstance,
  FormSigningState,
  FormStates,
  META_APPEND,
  PropertyRootKey,
  TransactionMetaData
} from '@property-folders/contract/yjs-schema/property';
import { buildContractDocumentAndForm } from '@property-folders/components/form-gen-util/buildSubDocument';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { useNavigate } from 'react-router-dom';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { LineageRelatedModalWrapper } from '@property-folders/components/display/LineageRelatedModal';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import {
  hideStatesFilter,
  useAlternateRootKeyContracts
} from '@property-folders/components/hooks/useAlternateRootKeyContracts';
import { FormInstanceCard, RequiresFormText } from '@property-folders/components/dragged-components/FormInstanceCard';
import { AssertTerminatedModal } from '@property-folders/components/dragged-components/signing/AssertTerminatedModal';
import { IndexeddbPersistence } from 'y-indexeddb';
import { Awareness } from 'y-protocols/awareness';
import { InstanceCardBase } from '@property-folders/components/dragged-components/InstanceCardBase';
import { offerStateMapAndNameSorter } from '@property-folders/components/dragged-components/ContractMgmtOverviewCard';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { cloneContractLikeFactory } from '@property-folders/components/form-gen-util/clone';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { GenericCardFallback } from '@property-folders/components/display/errors/card';
import { SearchType } from '@property-folders/components/display/SearchType';
import { RoomProvider } from '@y-presence/react';
import { initialPresence } from './TransactionHomePage';
import { AwarenessData } from '@property-folders/contract';
import { SetupNetStateWritingYjsDocContext } from '@property-folders/components/form-gen-util/yjsStore';

interface RouterData {
  transId: string,
  ydoc: Y.Doc,
  localProvider: IndexeddbPersistence,
  ydocStats: Y.Doc,
  localProviderStats: IndexeddbPersistence,
  awareness: Awareness,
}
type OfferManagementProps = {
  breadcrumbs?: CrumbDefn[]
};

// Discussions with Nick have revealed that the summary data we see on these cards, will be brought
// into the ydoc as metadata, next to the references. This will then probably be kept up to date by
// the backend, presumably on ydoc update of the child ydocs.
//
// https://lucid.app/lucidchart/e88071fb-8fbe-456e-93c4-f81b97f47044/edit?page=PLUn3zQfBrWK&invitationId=inv_e7b0eb9d-736b-42ce-8d97-2ee776c2b95f#
// In the Lucid chart, submitted contracts will be written materialised into the ydoc. However, it
// may be that because ydocs are essentially always writable, we may still do this as file snapshots
// even though this is more annoying to manage. If ydoc sections are protected from writing on the
// backend, it may still be OK to write them to the ydoc. Keeping in mind that the offer process
// is a very online process, so the backend matters.
//
// So all that out of the way, in terms of data sources for summary display, we're set. We still
// need to sort out the actual creation processes from the actions on this page, but they're not yet
// well defined in terms of what and how. So yeah, we'll just put together all the UI, and leave a
// bunch of placeholder handlers for them.
export function ContractManagement({ breadcrumbs }: OfferManagementProps): JSX.Element {
  // Data sources => Form Instance from...

  const { transId, ydoc, awareness  } = useReactRouterData<RouterData>();
  const { bindState: metaState } = useImmerYjs<TransactionMetaData>(ydoc, PropertyRootKey.Meta);

  // We only want the first instance, if the primary document isn't signed, nothing else will be
  const { data: rsaaInstance } = metaState<FormInstance>(meta=> meta?.formStates?.[FormCode.RSAA_SalesAgencyAgreement]?.instances?.[0]);
  const { data: templateRSCInstance } = metaState<FormInstance>(meta=> meta?.formStates?.[FormCode.RSC_ContractOfSale]?.instances?.[0]);
  const rsaaSigned = rsaaInstance?.signing?.state === FormSigningState.Signed;
  const templateContractExists = !!templateRSCInstance;

  const narrowMode = useMediaQuery({ maxWidth: BP_MINIMA.sm });
  const { instance: fileSync } = useContext(FileSyncContext);
  const navigate = useNavigate();
  const [relatedContext, setRelatedContext] = useState<{
    headline: string
    formStates: FormStates
    formFamilyContext: string,
    rootKey: string
  }|null>(null);
  const [showMarkTerminatedKey, setShowMarkTerminatedKey] = useState<string|null>(null);
  const [showArchived, setShowArchived] = useState(false);

  const headlineVal = generateHeadlineFromMaterialisedData(ydoc.getMap(PropertyRootKey.Data).toJSON(), narrowMode);
  const rootLabel = 'Properties';
  const updatedBreadcrumb = useMemo(()=>[...(breadcrumbs ?? [{ label: rootLabel, href: '/properties/' }]), { label: headlineVal || 'Property Overview', href: `/properties/${LinkBuilder.seoFriendlySlug(transId, headlineVal)}` }, { label: 'Contract Management' }], [breadcrumbs, headlineVal, rootLabel]);

  const contracts = useAlternateRootKeyContracts({ ydoc })?.filter(c => !!c.archived === showArchived);
  const terminatedStates = [OfferState.Terminated, OfferState.Unknown, OfferState.Declined, OfferState.Withdrawn];
  const signedContacts = contracts.filter(c=>!terminatedStates.includes(c.state)).sort(offerStateMapAndNameSorter).filter(hideStatesFilter);
  // These two contract groups are split in two, to allow the manually rendered Template Contract Card
  const terminatedContracts = contracts.filter(c=>terminatedStates.includes(c.state)).sort(offerStateMapAndNameSorter).filter(hideStatesFilter);

  const [forceFocusId, setForceFocusId] = useState<string|null>(null);
  const onForceFocus = useCallback((formId: string, forceMe: boolean) => {
    setForceFocusId(ffi => forceMe
      ? formId
      : ffi === formId
        ? null
        : ffi);
  }, []);

  // So this is basically the only place to generate speculative offer requests with contracts.
  // There are 2 types of data stored.
  // 1. A stand alone ydoc sent to the end user for signing and remote completion.
  // 2. Some kind of image stored elsewhere in the base ydoc. Maybe as another root key, which masks data in the main ydoc prior to snapshot
  //
  // The 'Contract' in the main form instance set (ie on the master root key) is now representative
  // of the Template Contract. It should be a sole form instance, and not allow any signing or
  // variations etc

  // For now, we'll just make some assumptions about the shape of documents, and create some data interfaces for each of the actions on this page.

  const handleContractCreate = useCallback(()=>{
    buildContractDocumentAndForm(ydoc, fileSync).then(res=>{
      const { formId, formCode } = res ?? {};
      if (!formId || !ydoc || !transId) return;
      navigate(LinkBuilder.documentPath({ id: transId, nicetext: headlineVal }, { id: formId, nicetext: FormTypes[formCode].label }, false));
    });
  }, []);

  function handleOpenRelated(lineageDataKey: string) {
    const lineageFamily = FormTypes[FormCode.RSC_ContractOfSale]?.formFamily;
    const meta = ydoc.getMap(lineageDataKey+META_APPEND).toJSON();

    setRelatedContext({
      headline: headlineVal,
      formStates: meta.formStates,
      formFamilyContext: lineageFamily,
      rootKey: lineageDataKey
    });
  }

  const generateContractCard = (
    value: OfferContractSummary,
    handleOpenRelated: (lineageKey: string)=>void,
    handleCreateForm: (lineageKey: string)=>(formCode: FormCodeUnion)=>void,
    handleShowAssertTerminatedModal: (lineageKey: string)=>void,
    onCloneSublineage: (lineageKey: string, formFamily: FormCode)=>void
  ): JSX.Element => {
    return <ErrorBoundary key={value.lineageRootKey} fallbackRender={fallback=><GenericCardFallback {...fallback} cardTitle='Contract' />}>
      <OfferContractCard
        instance={value}
        onRelatedClick={()=>handleOpenRelated(value.lineageRootKey)}
        onCreateForm={handleCreateForm(value.lineageRootKey)}
        onShowAssertTerminatedModal={()=>handleShowAssertTerminatedModal(value.lineageRootKey)}
        onCloneSublineageForm={()=>onCloneSublineage(value.lineageRootKey, FormCode.RSC_ContractOfSale)}
        forceFocus={forceFocusId ? forceFocusId === value.lineageRootKey : undefined}
        onForceFocus={onForceFocus}
      />
    </ErrorBoundary>;
  };

  const handleLineageFormCreate = async (formCode: FormCodeUnion, dataRootKey: string) => {
    const newForm = await handleNewForm(ydoc, formCode as FormCodeUnion, fileSync, {}, dataRootKey, dataRootKey+META_APPEND);
    if (!newForm) {
      return;
    }
    navigate(`/properties/${LinkBuilder.seoFriendlySlug(transId, headlineVal)}/document/${LinkBuilder.seoFriendlySlug(newForm.formId, FormTypes[formCode].label)}`);
  };

  const handleTemplateContractCreateOrNavigate = async () => {
    if (!templateContractExists) {
      const newForm = await handleNewForm(ydoc, FormCode.RSC_ContractOfSale, fileSync, {}, PropertyRootKey.Data, PropertyRootKey.Meta);
      if (!newForm) {
        return;
      }
    }
    navigate(`/properties/${LinkBuilder.seoFriendlySlug(transId, headlineVal)}/contracts/template`);
  };

  const handleShowAssertTerminatedModal = async (dataRootKey: string) => {
    setShowMarkTerminatedKey(dataRootKey);
  };

  const handleCloneSublineage = (dataRootKey: string, formFamily: FormCode) => {
    const cloneFunc = cloneContractLikeFactory({ fileSync, headline: headlineVal, propertyId: transId, ydoc, navigate });
    cloneFunc(dataRootKey);
  };

  const cardMapper = (v: OfferContractSummary)=>generateContractCard(
    v,
    handleOpenRelated,
    (lineageKey:string)=>(formCode: FormCodeUnion)=>handleLineageFormCreate(formCode, lineageKey),
    handleShowAssertTerminatedModal,
    handleCloneSublineage
  );

  return <LineageRelatedModalWrapper
    formFamilyContext={relatedContext?.formFamilyContext}
    formStates={relatedContext?.formStates}
    headline={relatedContext?.headline}
    ydocId={transId}
    onFormCreate={fCode=>relatedContext&&handleLineageFormCreate(fCode,relatedContext.rootKey)}
    onClose={()=>setRelatedContext(null)}
    onExternalTermination={()=>{
      if (!relatedContext?.rootKey) return;
      handleShowAssertTerminatedModal(relatedContext?.rootKey);
      setRelatedContext(null);
    }}
  >
    <SetupNetStateWritingYjsDocContext
      ydoc={ydoc}
      awareness={awareness}
      docName={transId}
      transactionRootKey={PropertyRootKey.Data}
      transactionMetaRootKey={PropertyRootKey.Meta}
    >
      <RoomProvider<AwarenessData> awareness={awareness} initialPresence={initialPresence}>

        <ContentTitler
          breadcrumbs={updatedBreadcrumb}
          title={showArchived ? 'Archived Contracts' : 'Contract Management'}
          className="overflow-auto"
          flex={true}
          scroll={true}
          narrowWholePageScroll={true}
          afterTitle={<>
            <SplitIfManyButton>
              <Button
                onClick={handleContractCreate}
                style={{ height: 42.25 }}
              ><Icon name='add' style={{ fontSize: 18, marginRight: '0.25rem' }} />Add Contract</Button>
              <Button>Add Contract from Offer</Button>
            </SplitIfManyButton>
            <SearchType
              setShowArchived={setShowArchived}
              favourites={false}
            />
          </>}
        >
          <div className='card-container'>
            {signedContacts.map(cardMapper)}
            {!showArchived && <ErrorBoundary fallbackRender={fallback=><GenericCardFallback {...fallback} cardTitle='Template Contract' />}>
              <InstanceCardBase
                title='Template Contract'
                cardClass={!rsaaSigned ? 'form-adder' : undefined}
                onOpen={handleTemplateContractCreateOrNavigate}
                noHover={!rsaaSigned}
                clickable={!!rsaaSigned}
                openText='View'
                footerButtons={
                  <SplitIfManyButton onForceFocus={(forceMe)=>onForceFocus('template-contract', forceMe)}>
                    <Button variant='outline-secondary' onClick={handleTemplateContractCreateOrNavigate} disabled={!rsaaSigned}>Edit</Button>
                  </SplitIfManyButton>
                }
                forceFocus={forceFocusId ? forceFocusId === 'template-contract' : undefined}
              >
                {!rsaaSigned ? <RequiresFormText formCode={FormCode.RSAA_SalesAgencyAgreement} /> : []}
              </InstanceCardBase>
            </ErrorBoundary>}
            {terminatedContracts.map(cardMapper)}
            {signedContacts.length === 0 && terminatedContracts.length === 0 && !showArchived &&
        <ErrorBoundary fallbackRender={fallback=><GenericCardFallback {...fallback} cardTitle='Create Contract' />}>
          <FormInstanceCard
            key={FormCode.RSC_ContractOfSale}
            onOpen={handleContractCreate}
            fCode={FormCode.RSC_ContractOfSale}
            cardClass="form-adder"
            renderOpts={{ ...FormTypes[FormCode.RSC_ContractOfSale].renderOpts, suggestionActions: ['create', 'view'] }}
            forceFocus={forceFocusId ? forceFocusId === 'new' : undefined}
          />
        </ErrorBoundary>
            }
          </div>
          <AssertTerminatedModal
            formFamilyCode={showMarkTerminatedKey ? FormCode.RSC_ContractOfSale : null}
            lineageKey={showMarkTerminatedKey}
            ydoc={ydoc}
            ydocId={transId}
            awareness={awareness}
            onClose={()=>setShowMarkTerminatedKey(null)}
            formNoun='Contract'
            wrongMethodText={<p>
          If you have not yet gotten a binding document confirming the termination of
          this Contract, please use <a href='#' onClick={()=>showMarkTerminatedKey && handleLineageFormCreate(ExtraFormCode.SCT_ContractOfSaleTermination, showMarkTerminatedKey)}>Mutual Termination of Contract of Sale</a> instead.
            </p>}
          />
        </ContentTitler>
      </RoomProvider>
    </SetupNetStateWritingYjsDocContext>

  </LineageRelatedModalWrapper>;
}
