import { EntityLargeScreenPreviewClickHandler, EntitySmallScreenPreviewButton } from '~/pages/settings/PreviewHandlers';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import {
  useAgentSessionPreviewEmailContact
} from '@property-folders/components/display/EmailPreview';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { EntityBrandFormConfig,
  EntityBrandPurchaserRegistrationConfig
} from '@property-folders/contract/yjs-schema/entity-settings';
import React, { useContext, useEffect, useState } from 'react';
import { Pdf } from '@property-folders/common/util/pdf-worker/Pdf';
import { transparentPixel } from '@property-folders/common/util/pdfgen/logos';
import {
  getPurchaserSelfRegistrationDefinition
} from '@property-folders/common/util/pdfgen/definitions/documents/purchaser-self-registration';
import QRCode from 'qrcode';
import { defaultPurchaserRegistrationBrandConfig } from '@property-folders/components/hooks/useEntity';
import { ContentType, MaterialisedPropertyData } from '@property-folders/contract';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { PDFViewer } from '@property-folders/components/dragged-components/PDFViewer/PDFViewer';
import { v4 } from 'uuid';
import { AjaxPhpSettingsImageUpload } from '~/pages/settings/AjaxPhpSettingsImageUpload';
import { Col, Row } from 'react-bootstrap';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { blobTob64 } from '@property-folders/common/util/dataExtract';

export enum PortalSelfRegistrationSettingsPreviewKey {
  SelfRegistrationPdf = 'selfRegistrationPdf'
}

export function PortalSelfRegistrationSettingsEdit({ onPreviewKey }: { onPreviewKey: (key: string) => void }) {
  return <div>
    <EntityLargeScreenPreviewClickHandler onPreviewKey={onPreviewKey} previewKey={PortalSelfRegistrationSettingsPreviewKey.SelfRegistrationPdf}>
      <WizardStepPage
        name='selfRegPdf'
        label='Purchaser Self-Registration PDF'
        icon='email'
        headerContent={<EntitySmallScreenPreviewButton onPreviewKey={onPreviewKey} previewKey={PortalSelfRegistrationSettingsPreviewKey.SelfRegistrationPdf}/>}
      >
        <Row>
          <Col size={12}>
            <h4>
              Header Image
            </h4>
          </Col>
        </Row>
        <Row>
          <Col size={12}>
            <AjaxPhpSettingsImageUpload hint='For best results, the image should use an aspect ratio of 7:2 (eg 2380x680px)' type='PurchaserRegistrationImage' />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col size={12}>
            <h4>
              Content
            </h4>
          </Col>
        </Row>
        <Row>
          <Col size={6}><WrField.ColourPicker name='Background' parentPath='brand.purchaserRegistration.page' myPath='backgroundColour' defaultValue={'#ffffff'} /></Col>
          <Col size={6}><WrField.ColourPicker name='Text' parentPath='brand.purchaserRegistration.page' myPath='foregroundColour' defaultValue={'#404040'} /></Col>
        </Row>
        <Row className='mt-3'>
          <Col size={12}>
            <h4>
              Stripe
            </h4>
          </Col>
        </Row>
        <Row>
          <Col size={6}><WrField.ColourPicker name='Background' parentPath='brand.purchaserRegistration.stripe' myPath='backgroundColour' defaultValue={'#000000'} /></Col>
          <Col size={6}><WrField.ColourPicker name='Text' parentPath='brand.purchaserRegistration.stripe' myPath='foregroundColour' defaultValue={'#dddddd'} /></Col>
        </Row>
      </WizardStepPage>
    </EntityLargeScreenPreviewClickHandler>
  </div>;
}

export function PortalSelfRegistrationSettingsPreview({ previewKey }: {previewKey: string}) {
  switch (previewKey) {
    case PortalSelfRegistrationSettingsPreviewKey.SelfRegistrationPdf:
    default:
      return <SelfRegistrationPdf />;
  }
}

function SelfRegistrationPdf() {
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const { value: brand } = useLightweightTransaction<EntityBrandPurchaserRegistrationConfig>({ parentPath: 'brand.purchaserRegistration' });
  const { value: formBrand } = useLightweightTransaction<EntityBrandFormConfig>({ parentPath: 'brand.form' });
  const [previewUrl, setPreviewUrl] = useState<string>();
  const contact = useAgentSessionPreviewEmailContact();

  useEffect(() => {
    if (!formBrand) return;

    (async () => {
      const qrCodeImageUrl = await QRCode.toDataURL('https://reaforms.com.au');
      const pdf = new Pdf();
      const brandReg = { ...defaultPurchaserRegistrationBrandConfig, ...brand };
      const headerImage = entityPhpInfo?.purchaserHeader
        ? await blobTob64(entityPhpInfo.purchaserHeader, true)
        : transparentPixel;
      pdf.prepare(formBrand, {}, true);
      const data = await pdf.generateBlobAsync(getPurchaserSelfRegistrationDefinition(
        makeProperty(contact),
        headerImage,
        brandReg,
        qrCodeImageUrl));
      setPreviewUrl(URL.createObjectURL(new Blob([data], { type: ContentType.Pdf })));
    })();

    return () => {};
  }, [
    brand?.headerImageS3Uri,
    brand?.page?.backgroundColour,
    brand?.page?.foregroundColour,
    brand?.stripe?.backgroundColour,
    brand?.stripe?.foregroundColour,
    !!formBrand,
    contact.name,
    contact.email
  ]);

  return <div className='w-100 p-3'>
    {previewUrl && <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}>
      <SetupPdfLoadStateContext>
        <PDFViewer
          bookmark=''
          pdfUrl={previewUrl}
          filename={'preview.pdf'}
          renderTextLayer={false}
          standalonePreview={true}
          useLoadSuccessForCompletion={true}
          activeViews={2}
        />
      </SetupPdfLoadStateContext>
    </div>}
  </div>;
}

function makeProperty(contact: { name: string, email?: string }): MaterialisedPropertyData {
  return {
    id: v4(),
    saleAddrs: [{
      id: v4(),
      streetAddr: '123 Evergreen Terrace',
      subStateAndPost: 'Adelaide, SA 5000',
      fromLssa: false,
      irrigationArea: '',
      lga: '',
      suburb: '',
      hundred: ''
    }],
    agent: [{
      id: v4(),
      phone: '',
      abn: '',
      email: '',
      company: '',
      address: '',
      rla: '',
      salesp: [{
        id: v4(),
        name: contact.name,
        email: contact.email || '',
        phone: ''
      }]
    }],
    compareAddrs: [],
    disableCompareAlreadyProvided: true
  };
}
