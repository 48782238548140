import { useContext, useMemo } from 'react';
import { BreadCrumbs } from '@property-folders/components/dragged-components/BreadCrumbs';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { TransactionMetaData } from '@property-folders/contract';
import { useUpdateInstanceModifiedOnLoad } from '@property-folders/components/hooks/useUpdateInstanceModifiedOnLoad';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';

type NotImplementedPlaceholderProps = TransactionFormProps & {
  formCode: string
};

export function NotImplementedPlaceholder({ breadcrumbs, formId, formCode }: NotImplementedPlaceholderProps) {
  const { ydoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { bindState } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey); // Used by non-existent forms, so should refer to sublineage if applicable?
  const { data: meta, update: updateMeta } = bindState<TransactionMetaData>(state => state);
  const formStates = meta?.formStates || {};
  const family = FormTypes[formCode]?.formFamily;
  const formInstances = formStates[family]?.instances || [];
  console.warn('not implemented page', formCode, formStates, formInstances);
  const instance = useMemo(()=>{
    return formInstances && formInstances?.find(inst => inst.id === formId);
  }, [formInstances, formId]);
  useUpdateInstanceModifiedOnLoad(instance, formId, family, updateMeta);
  const label = FormTypes[formCode]?.label || 'Not Implemented!';
  const updatedBreadcrumb = useMemo(()=>[...(breadcrumbs ?? []), { label }], [breadcrumbs]);

  return (
    <div>
      <h1>{label}</h1>
      <BreadCrumbs segments={updatedBreadcrumb}/>
      <div className="alert alert-warning" role="alert">
      Not implemented!
      </div>
    </div>
  );
}
