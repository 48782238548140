import { Link } from 'react-router-dom';

export function FourZeroFourPage() {
  return <div className='page-error'>
    <div>
      <h1>Something went wrong.</h1>
      <div className='d-flex mt-4'>
        <h1 className='text-secondary'>404</h1>
        <h4 className='text-secondary ms-4' style={{ maxWidth: 270 }}>We must have misplaced that page.</h4>
      </div>
      <div className='text-center'>
        <Link to='/index.php'>
          <button className='home btn text-white mt-4'>Take me home</button>
        </Link>
      </div>
    </div>
  </div>;
}
