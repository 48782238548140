import { Button, Card, CardGroup } from 'react-bootstrap';
import { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import {  FormTypes } from '@property-folders/common/yjs-schema/property/form';

type TransactionEditorProps = {
  breadcrumbs: CrumbDefn[]
  onAddForm: (formCode: string) => void;
  availForms: string[]
};

export const TransactionNewForm = ({ breadcrumbs, onAddForm, availForms }: TransactionEditorProps) => {

  return <div className="d-flex w-100 h-100">
    <ContentTitler breadcrumbs={[...breadcrumbs, { 'label': 'Add new Form' }]} title={'Add new Form'}>
      <CardGroup className='card-container'>
        {availForms.map(fCode=>[fCode, FormTypes[fCode]]).map(([formCode, descriptor]) => {
          return <Card>
            <Card.Header>{descriptor.label}</Card.Header>
            <Card.Body>{descriptor.description}</Card.Body>
            <Card.Footer className='d-flex justify-content-end'>
              <Button variant="outline-primary" onClick={()=>onAddForm(formCode)} title='Add this form to the property folder'>
                Add
              </Button>
            </Card.Footer>
          </Card>;
        })}
      </CardGroup>
    </ContentTitler>
  </div>;
};
