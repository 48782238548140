import { Button, Modal, Spinner } from 'react-bootstrap';
import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Maybe } from '@property-folders/contract';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import { useOnline } from '@property-folders/components/hooks/useOnline';

export function AjaxPhpSettingsImageUpload({
  label,
  hint,
  type = 'Logo'
}: {
  label?: string,
  hint?: string,
  type?: 'Logo' | 'PurchaserRegistrationImage',
}) {
  const online = useOnline();
  const { entityPhpInfo, entityPhpInfoStatus, entityUuid } = useContext(EntitySettingsContext);
  const url = (() => {
    switch (type) {
      case 'Logo':
        return entityPhpInfo?.urlLogo;
      case 'PurchaserRegistrationImage':
        return entityPhpInfo?.urlPurchaserHeader;
    }
  })();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [newImage, setNewImage] = useState<Maybe<{ filename: string, data: Blob, objectUrl: string }>>(undefined);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const handleDrop = async (files: File[]) => {
    if (files.length !== 1) return;
    const file = files[0];
    setNewImage({
      filename: file.name,
      data: file,
      objectUrl: URL.createObjectURL(file)
    });
  };
  const { getRootProps, getInputProps, inputRef, isDragAccept } = useDropzone({
    onDrop: (files) => handleDrop(files),
    noClick: true,
    multiple: false,
    accept: { 'image/jpeg': [], 'image/png': [] }
  });
  const openUploadModal = () => {
    setNewImage(undefined);
    setShowUploadModal(true);
    setProcessing(false);
  };
  const closeUploadModal = () => {
    setNewImage(undefined);
    setShowUploadModal(false);
    setProcessing(false);
  };
  const doUpload = async () => {
    if (!newImage) return;
    if (!entityUuid) return;
    setProcessing(true);
    try {
      const result = await AjaxPhp.uploadLogo({ entityUuid, type, data: newImage.data });

      if (result?.success) {
        entityPhpInfo?.reload();
        closeUploadModal();
        return;
      }
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setProcessing(false);
    }
  };
  const doRemove = async () => {
    if (!entityUuid) return;
    setProcessing(true);
    try {
      const result = await AjaxPhp.removeLogo({ entityUuid, type });
      if (result?.success) {
        entityPhpInfo?.reload();
        setShowRemoveModal(false);
      }
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setProcessing(false);
    }
  };

  if (!online) {
    return <></>;
  }

  return <>
    {label && <div className='lead'>
      <span>{label}</span>
    </div>}
    <div className='d-flex flex-column'>
      {hint && <small>{hint}</small>}
      <div className='mt-2 d-flex flex-row align-items-end gap-2'>
        <Button variant='outline-primary' onClick={openUploadModal}>Upload</Button>
        <Button variant='outline-danger' onClick={() => setShowRemoveModal(true)}>Remove</Button>
      </div>
      <div className='w-100 mt-2 d-flex justify-content-center align-items-center border border-1 p-2'>
        {entityPhpInfoStatus === 'loading'
          ? <Spinner animation='border' />
          : url
            ? <img src={url} style={{ height: '64px', objectFit: 'contain' }}></img>
            : <div className='fs-4 d-flex justify-content-center align-items-center' style={{ height: '64px' }}>None Set</div>}
      </div>
    </div>
    {showUploadModal && <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)} backdrop={processing ? 'static' : true}>
      <Modal.Header>
        <Modal.Title>Upload Logo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div {...getRootProps({
          className: clsJn(
            'd-flex flex-row align-items-center justify-content-center cursor-pointer drop-target',
            isDragAccept && 'drop-accept'
          ),
          style: {
            height: '100px'
          },
          onClick: () => {
            inputRef?.current?.click();
          }
        })}>
          <input {...getInputProps({ className: 'd-none', accept: 'image/jpeg,image/png' })}/>
          {newImage
            ? <div className='d-flex flex-row flex-nowrap gap-2 align-items-center justify-content-center h-100 w-100 p-2'>
              <img src={newImage.objectUrl} className='h-100' style={{ objectFit: 'contain' }} />
              <div className='d-flex flex-row align-items-center overflow-hidden wrap-text'><h5>{newImage.filename}</h5></div>
            </div>
            : <h5>Choose an image...</h5>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={closeUploadModal}>Cancel</Button>
        <SpinnerButton disabled={!newImage} processing={processing} onClick={doUpload}>Upload</SpinnerButton>
      </Modal.Footer>
    </Modal>}
    {showRemoveModal && <Modal show={showRemoveModal} onHide={() => setShowRemoveModal(false)} backdrop={processing ? 'static' : true}>
      <Modal.Header>
        <Modal.Title>Remove Logo</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure?</Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => setShowRemoveModal(false)}>Cancel</Button>
        <SpinnerButton variant='danger' processing={processing} onClick={doRemove}>Remove</SpinnerButton>
      </Modal.Footer>
    </Modal>}
  </>;
}
