import { Form } from 'react-bootstrap';

const PasswordResetForm = (
  { username,
    setUsername
  }: { username: string,
    setUsername: (username: string) => void
  }) => {
  return (
    <>
      <div className='p-3'>
        <div className='text-muted' style={{ width: '100%', height: '14px', borderBottom: '1px solid #6c757d', textAlign: 'center' }}>
          <span className='text-muted' style={{ fontSize: '1em', backgroundColor: '#FFFFFF', padding: '0 10px' }}>
            We will email you a link to reset your password
          </span>
        </div>
      </div>
      <div className='form-group mt-3'>
        <Form.Control
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          className='form-control mt-1'
          placeholder='Your email address'
          autoComplete='username'
          required
        />
      </div>
    </>
  );
};

export default PasswordResetForm;