import React, { useContext, useMemo } from 'react';
import { Wizard } from '@property-folders/components/dragged-components/Wizard/Wizard';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { FormContextSetup,
  getDefaultReportMissing
} from '@property-folders/components/form-gen-util/yjsStore';
import { fieldGroups as contractFieldGroups, saleContractFormRules } from '@property-folders/contract/yjs-schema/model/form/sale-contract-residential';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { FormCode, FormInstance, MaterialisedPropertyData, SaleMethod, TransactionMetaData } from '@property-folders/contract';
import { useUpdateInstanceModifiedOnLoad } from '@property-folders/components/hooks/useUpdateInstanceModifiedOnLoad';
import '../FormInput.scss';
import { SaleContractPdfDefinitionProvider } from '@property-folders/common/util/pdfgen/definitions/helpers';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { useForm } from '@property-folders/components/hooks/useForm';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { AnnexuresSection } from '@property-folders/components/dragged-components/form/AnnexuresSection';
import { propertyFolder, propertyMeta } from '@property-folders/contract/yjs-schema/model/field';
import { ClausesWizardStepPage } from '@property-folders/components/dragged-components/Wizard/ClausesWizardStepPage';

import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { UpdatesPendingBadge } from '@property-folders/components/display/properties/UpdatesPendingBadge';
import { PropertyBackgroundJobsContext } from '@property-folders/components/context/PropertyBackgroundJobs';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { IPdfDefinitionProvider } from '@property-folders/common/types/PDFDefinition';
import { useMatches } from 'react-router-dom';
import { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import { FullPageLoading } from '@property-folders/components/dragged-components/FullPageLoading';
import { ShowIfVariationRequired } from '@property-folders/components/display/form/ShowIfVariationRequired';
import { templateContractNonUniqueWatchPathsFlat } from '@property-folders/contract/yjs-schema/variations/template-contract';
import { Button } from 'react-bootstrap';
import { VendorWizardStepPage } from '@property-folders/components/dragged-components/Wizard/VendorWizardStepPage';
import { PropertyWizardStepPage } from '@property-folders/components/dragged-components/Wizard/PropertyWizardStepPage';
import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { requirePropertyDetailsMessage } from '@property-folders/common/util/formatting/constants';
import { ContractGst } from '@property-folders/components/dragged-components/form/SaleContract/contractGst';
import { ContractPrice } from '@property-folders/components/dragged-components/form/SaleContract/contractPrice';
import { ContractSettlementWizardStepPage } from '@property-folders/components/dragged-components/Wizard/ContractSettlementWizardStepPage';
import { ContractSchedule } from '@property-folders/components/dragged-components/form/SaleContract/ContractSchedule';
import { TemplateContractSpecialConditionsFinance } from '@property-folders/components/dragged-components/form/SaleContract/TemplateContractSpecialConditionsFinance';
import { TemplateContractSpecialConditionsPurchaserSale } from '@property-folders/components/dragged-components/form/SaleContract/TemplateContractSpecialConditionsPurchaserSale';
import { useSetAuctionSettlement } from '@property-folders/components/hooks/useSetAuctionSettlement';

const FORM_CODE = FormCode.RSC_ContractOfSale;
const FORM_LABEL = FormTypes[FORM_CODE].label;
const FORM_RULES = saleContractFormRules;
const TRANSACTION_RULES = propertyFolder;
const FORM_CONTEXT: Omit<FormContextType, 'formId'> = {
  formName: FORM_CODE,
  printTitle: FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  wizardTitle: FormTypes[FORM_CODE].wizardTitle ?? FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  formRules: FORM_RULES,
  transactionRules: TRANSACTION_RULES,
  metaRules: propertyMeta,
  fieldGroups: contractFieldGroups,
  reportMissing: getDefaultReportMissing(FORM_CODE, saleContractFormRules)
};

export const TemplateContractForm = (props: TransactionFormProps) => {
  // this code is similar to what's on the RSAA, so...
  // future: maybe lift signing stuff out into separate pages within TransactionHomePage
  // and also create a usePdfDefinitionProvider hook to automatically generate the right thing based on YjsDocContext and FormContext
  // and maybe a useInitiateSigningCallback hook to build the right kind of signing initiation flow.
  // anyway, no time to do that now!
  const formCode = FORM_CODE;
  const formId = props.formId;
  const { ydoc, transactionMetaRootKey, transactionRootKey } = useContext(YjsDocContext);
  const { binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { binder: dataBinder } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);

  const updatedBreadcrumb = useMemo(()=>[...(props.breadcrumbs ?? []), { label: FORM_LABEL }], [props.breadcrumbs]);
  const pdfDefinition = useMemo<IPdfDefinitionProvider|null>(() => {

    return new SaleContractPdfDefinitionProvider(
      dataBinder,
      metaBinder,
      formCode,
      formId,
      true,
      true
    );
  }, [dataBinder, metaBinder, formCode, formId]);

  return pdfDefinition
    ? <FormContextSetup formId={formId} base={FORM_CONTEXT}>
      <SetupPdfLoadStateContext>
        <TemplateContractFormInner
          {...props}
          breadcrumbs={updatedBreadcrumb}
          pdfDefinition={pdfDefinition}/>
      </SetupPdfLoadStateContext>
    </FormContextSetup>
    : <FullPageLoading />;
};

const TemplateContractFormInner = ({ formId, parentPath = '', myPath = '', breadcrumbs, entityLogoLoadedUri, pdfDefinition }: TransactionFormProps & {pdfDefinition: IPdfDefinitionProvider}): JSX.Element => {
  const { ydoc, docName, transactionRootKey, transactionMetaRootKey, formName: formCode, formFamilyCode, wizardTitle } = useForm();
  const { updateDraft } = useYdocBinder<MaterialisedPropertyData>({ path: '' });
  const { bindState, binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { data: formInstances, update: updateMeta } = bindState<FormInstance[]>(state => state?.formStates?.[FORM_CODE]?.instances || []);
  const backgroundJobsContext = useContext(PropertyBackgroundJobsContext);
  const instance = useMemo(()=>{
    return formInstances && formInstances?.find(inst => inst.id === formId);
  }, [formInstances, formId]);

  // when the form instance is loaded, then update modified date, but only if it's not completed.
  // I suppose if we wanted to go more crazy we could do it if the user actually changes a field
  useUpdateInstanceModifiedOnLoad(instance, formId, FORM_CODE, updateMeta);

  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ parentPath, myPath });
  const headline = generateHeadlineFromMaterialisedData(transRoot);

  const isAuction = transRoot?.sale?.saleMethod === SaleMethod.Auction;

  useSetAuctionSettlement(transRoot, updateDraft);

  const clauseUUID = metaBinder?.get()?.formStates?.[formFamilyCode]?.clauseChildId || '0';

  const currentPathing = useMatches();
  const contractManagementHref = currentPathing[currentPathing.length-2].pathname + '/contracts';
  const updatedBread: CrumbDefn[] = [...breadcrumbs.slice(0,-1), { label: 'Contract Management', href: contractManagementHref }, breadcrumbs[breadcrumbs.length-1]];
  return (
    <Wizard
      formId={formId}
      formName={formCode}
      transactionRootKey={transactionRootKey}
      transactionMetaRootKey={transactionMetaRootKey}
      docName={docName}
      title={'Template Contract'/* This doesn't have it's own form code definition */}
      afterTitle={
        <UpdatesPendingBadge propertyId={backgroundJobsContext?.propertyId} headline={headline} show={backgroundJobsContext.envelopeChangesAvailable} className='ms-auto me-2 mt-1' />
      }
      printHeadline={headline}
      breadcrumbs={updatedBread}
      pdfDefinition={pdfDefinition}
      ydoc={ydoc}
      signing={false}
      entityLogoLoadedUri={entityLogoLoadedUri}
    >
      <WizardStepPage
        label={'Note'}
        pageOnlyLabel='Note about Template Contracts'
        icon='info'
        name='tcNote'
        headerContent={<ShowIfVariationRequired formCode={FormCode.RSAA_SalesAgencyAgreement} pathWatchList={templateContractNonUniqueWatchPathsFlat}>
          {({ createAction, navigateAction, pickNavigate })=>{
            return pickNavigate
              ? <Button variant='outline-secondary' onClick={navigateAction}>Go to Agency Variation</Button>
              : <Button variant='outline-secondary' onClick={createAction}>Create Agency Variation</Button>;
          }}
        </ShowIfVariationRequired>}
      >
        <div>
          <p>The Template Contract sets the default values for all new Contracts in this Property Folder.</p>

          <p>By default, the Template Contract is populated using the terms agreed with the Vendor in the Sales Agency Agreement. We recommend that you create a Variation to Sales Agency Agreement to formalise these changes with the Vendors.</p>

          <p>If you'd prefer to edit the Template Contract directly, you can. In which case, reaforms will keep track of your changes and automatically include them in any future Variation to Sales Agency Agreement that you create.</p>
        </div>

      </WizardStepPage>

      <VendorWizardStepPage
        name="vendor"
        label="Vendor"
        icon="person"
        requiredTooltip='Specify Vendor of Property'
      />

      <PropertyWizardStepPage
        name="property"
        label="Property"
        icon="home"
        generalFocusClass={composeErrorPathClassName([],'property-section')}
        sectionErrorText={requirePropertyDetailsMessage}
        requiredTooltip='Specify Property for sale'
      />

      <WizardStepPage
        name='contractGst'
        label="GST Conditions"
        icon="percent"
        requiredTooltip='Specify whether GST is applicable to sale'
      >
        <ContractGst myPath='contractPrice' templateContractMode={true} />
      </WizardStepPage>

      <WizardStepPage
        name='contractPrice'
        label="Price"
        icon="attach_money"
      >
        <ContractPrice myPath='contractPrice' templateMode/>
      </WizardStepPage>

      <ContractSettlementWizardStepPage
        name='contractSettlement'
        label="Settlement"
        icon="schedule"
        requiredTooltip='Specify details of Settlement'
      />

      <WizardStepPage
        name='contractSchedule'
        label="Particulars"
        pageOnlyLabel='Property and sale particulars'
        icon="rule"
      >
        <ContractSchedule />
      </WizardStepPage>

      <WizardStepPage
        name='contractSpecialFinance'
        label='Purchaser finance'
        icon="account_balance"
        pdfFocus='subsection-purchaser-finance'
      >
        {isAuction
          ? <div>Not available for Auctions</div>
          : <TemplateContractSpecialConditionsFinance />
        }
      </WizardStepPage>

      <WizardStepPage
        name='contractSpecialPurchaserSale'
        label='Purchaser sale'
        icon="other_houses"
        pdfFocus='subsection-purchaser-sale'
      >
        {isAuction
          ? <div>Not available for Auctions</div>
          : <TemplateContractSpecialConditionsPurchaserSale />
        }
      </WizardStepPage>

      <ClausesWizardStepPage
        myPath={`clausesByFamily.[${clauseUUID}].clauses`}
        name='clauses'
        label='Additional Conditions'
        icon='playlist_add'
        variationWatchPaths={[['clausesByFamily', `[${clauseUUID}]`, 'clauses']]}
      />

      <WizardStepPage
        name="annexures"
        label="Annexures"
        icon='attach_file'
        variationDeterminationCallback={[()=>(instance?.annexures?.length??0) > 0]}
      >
        <AnnexuresSection formCode={formCode} formId={formId} ydoc={ydoc} transactionMetaRootKey={transactionMetaRootKey}/>
      </WizardStepPage>
    </Wizard>
  );
};
